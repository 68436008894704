import { CrawlData } from '../bookmark/model/Bookmark';

export interface PlanData {
  title: string;
  register: string;
  tag: string;
  day: string;
  content: string;
  social: boolean;
  bookmark: number;
  comment: number;
}

export interface SearchData {
  title: string;
  source?: string;
  date?: string;
  content: string;
  crawlData?: CrawlData;
  dataType?: string;
}

export interface SearchDatas {
  category: string;
  searchData: SearchData[];
}

export enum DetailOpenType {
  NEW_TAB = 'NEW_TAB',
  DETAIL_VIEW = 'DETAIL_VIEW',
}
