import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { SearchActionType } from '../../reducer/actions';
import { KeywordCategoryCode, RecommendedSearch } from '../../service/search/model/enum/Search.enum';
import './SearchRight.scss';

const SearchRight: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const renderKeywordCategory = (topCategoryCode: string) => {
    const keywordCategory =
      state.searchRight.keywordCategories &&
      state.searchRight.keywordCategories.find(
        category => category.topCategoryCode === topCategoryCode && category.categoryName
      );
    if (keywordCategory) {
      return <dd className={keywordCategory.esMatched ? 'matched' : ''}>{keywordCategory.categoryName}</dd>;
    }
    return <dd className={'empty'}>{t('search.message.noSearchHistory')}</dd>;
  };

  const toggleRecommendSearch = () => {
    let recommendedSearch = RecommendedSearch.TOGGLE_ON;
    if (state.cookieService.getRecommendedSearch() === RecommendedSearch.TOGGLE_ON) {
      recommendedSearch = RecommendedSearch.TOGGLE_OFF;
    }
    state.cookieService.setRecommendedSearch(recommendedSearch);

    dispatch({
      type: SearchActionType.TOGGLE_RECOMMENDED_SEARCH,
      searchRight: {
        recommendedSearch: recommendedSearch,
      },
    });
  };

  return (
    <div className={'SearchRight flex flex-one relative'}>
      <div className={'flex boarder-area left top vbox'}>
        <div className={'flex card'}>
          <div className={'flex vbox classification'}>
            <div className={'flex classification-title'}>{t('searchResult.classification.title')}</div>
            <div className={'flex vbox classification-result'}>
              <dl>
                <dt>{t('searchResult.classification.industry')}</dt>
                {renderKeywordCategory(KeywordCategoryCode.INDUSTRY)}
              </dl>
              <dl>
                <dt>{t('searchResult.classification.country')}</dt>
                {renderKeywordCategory(KeywordCategoryCode.COUNTRY)}
              </dl>
              <dl>
                <dt>{t('searchResult.classification.technology')}</dt>
                {renderKeywordCategory(KeywordCategoryCode.TECHNOLOGY)}
              </dl>
              <dl>
                <dt>{t('searchResult.classification.product')}</dt>
                {renderKeywordCategory(KeywordCategoryCode.PRODUCT)}
              </dl>
              <dl>
                <dt>{t('searchResult.classification.competitor')}</dt>
                {renderKeywordCategory(KeywordCategoryCode.COMPETITOR)}
              </dl>
              <dl>
                <dt>{t('searchResult.classification.keyword')}</dt>
                <dd className={'empty'}>{t('search.message.noSearchHistory')}</dd>
              </dl>
            </div>
          </div>
          <div className={'flex vbox recommend'}>
            <div className={'flex recommend-title'}>
              {t('searchResult.recommend.title')}
              <Form className="recommend-switch">
                <Form.Check
                  type="switch"
                  id="recommend-switch"
                  label=""
                  checked={state.searchRight.recommendedSearch === RecommendedSearch.TOGGLE_ON}
                  onChange={toggleRecommendSearch}
                />
              </Form>
            </div>
            <div className={'flex recommend-info'}>{t('searchResult.recommend.info')}</div>
          </div>
        </div>
        <div className={'flex analysis'}>
          <div className={'flex analysis-btn'}>{'Word Cloud 분석하기'}</div>
          <div className={'flex analysis-link'}></div>
        </div>
        <div className={'flex card'}>
          <div className={'flex vbox related'}>
            <div className={'flex related-title'}>
              {'#전기차'}
              <span>{t('searchResult.related.title')}</span>
            </div>
            <div className={'flex related-word-list wrap'}>
              <div className={'flex related-word'}>{'배터리'}</div>
              <div className={'flex related-word'}>{'하이브리드'}</div>
              <div className={'flex related-word'}>{'내연기관'}</div>
              <div className={'flex related-word'}>{'태양광'}</div>
              <div className={'flex related-word'}>{'무선충전'}</div>
              <div className={'flex related-word'}>{'친환경'}</div>
              <div className={'flex related-word'}>{'아이오닉'}</div>
              <div className={'flex related-word'}>{'볼보'}</div>
              <div className={'flex related-word'}>{'수소차'}</div>
              <div className={'flex related-word'}>{'테슬라'}</div>
            </div>
          </div>
          <div className={'flex vbox popular'}>
            <div className={'flex popular-title'}>
              {'#전기차'}
              <span>{t('searchResult.popular.title')}</span>
            </div>
            <div className={'flex popular-word-list wrap'}>
              <div className={'flex popular-word'}>
                <span className={'popular-word-rank'}>{'1'}</span>
                {'배터리'}
              </div>
              <div className={'flex popular-word'}>
                <span className={'popular-word-rank'}>{'2'}</span>
                {'하이브리드'}
              </div>
              <div className={'flex popular-word'}>
                <span className={'popular-word-rank'}>{'3'}</span>
                {'내연기관'}
              </div>
              <div className={'flex popular-word'}>{'태양광'}</div>
              <div className={'flex popular-word'}>{'무선충전'}</div>
              <div className={'flex popular-word'}>{'친환경'}</div>
              <div className={'flex popular-word'}>{'아이오닉'}</div>
              <div className={'flex popular-word'}>{'볼보'}</div>
              <div className={'flex popular-word'}>{'수소차'}</div>
              <div className={'flex popular-word'}>{'테슬라'}</div>
            </div>
          </div>
        </div>
        <div className={'flex card employee-list-area vbox'}>
          <div className={'flex title'}>
            {'#전기차'}
            <span>{t('searchResult.employee.title')}</span>
          </div>
          <div className={'flex employee-list vbox'}>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-1 bg-grey'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'Mark @LG디스플레이'}</div>
                <div className={'flex active-info'}>{'300+ 북마크 / 200+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-2 bg-red'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'Jason0803 @LG전자'}</div>
                <div className={'flex active-info'}>{'100+ 북마크 / 40+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-3 bg-yellow'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'kainmsh @LG CNS'}</div>
                <div className={'flex active-info'}>{'100+ 북마크 / 20+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-2 bg-green'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'Curt @LG전자'}</div>
                <div className={'flex active-info'}>{'100+ 북마크 / 10+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-4 bg-purple'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'Micheal @LG전자'}</div>
                <div className={'flex active-info'}>{'300+ 북마크 / 200+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-3 bg-blue'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'kainmsh @LG전자'}</div>
                <div className={'flex active-info'}>{'200+ 북마크 / 20+ 스크랩'}</div>
              </div>
            </div>
            <div className={'flex employee hbox'}>
              <div className={'flex avatar-1 bg-orange'}></div>
              <div className={'flex info vbox'}>
                <div className={'flex nickname'}>{'Micheal @LG전자'}</div>
                <div className={'flex active-info'}>{'30+ 북마크 / 2+ 스크랩'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRight;
