import React, { ReactElement, useContext } from 'react';
import { ButtonGroup, Button, ListGroup, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import { DocumentActionType } from '../../../reducer/actions';
import { DocumentFolderResponse, DocumentRequest } from '../../../service/document/model/Document';
import { DocumentFolderType } from '../../../service/document/model/enum/Document.enum';
import './DocumentFolderSection.scss';

interface Props {
  folders: DocumentFolderResponse[];
  setFooter: Function;
}

const DocumentFolderSection: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const initialDocumentEditor: DocumentRequest = { title: '', content: '' };

  const selectFolder = (folderId: string): void => {
    dispatch({
      type: DocumentActionType.SET_DOCUMENT_VIEW_LIST,
      selectedFolderId: folderId,
    });
    props.setFooter(false);
  };

  const openNewEditor = () => {
    dispatch({
      type: DocumentActionType.SET_DOCUMENT_VIEW_EDITOR,
      selectedFolderId: state.document.selectedFolderId,
      editorContent: '',
      documentEditor: {
        ...initialDocumentEditor,
        folderId: state.document.selectedFolderId,
      },
    });
    props.setFooter(false);
  };

  return (
    <div id="DocumentFolderSection" className="DocumentFolderSection">
      <header>
        <h3>{t('document.label.DocumentFolderSection.document')}</h3>
      </header>
      <ButtonGroup>
        <Button id="newDocumentButton" variant="primary" onClick={openNewEditor} className="newDocumentButton">
          {t('document.label.DocumentFolderSection.newDocument')}
        </Button>
        {/* TODO : 새폴더 버튼 가리기 (UI에 기능 추가시 주석 제거)
        <Button id="newFolderButton" variant="light">
          {t('document.label.DocumentFolderSection.newFolder')}
        </Button>
        */}
      </ButtonGroup>
      <ListGroup id="folders" variant="flush">
        <ListGroup.Item
          action
          active={
            state.document.selectedFolderId === '' || state.document.selectedFolderId === DocumentFolderType.RECENT
          }
          onClick={() => selectFolder(DocumentFolderType.RECENT)}
          className="recent"
        >
          <p>{t('document.label.DocumentFolderSection.recent')}</p>
        </ListGroup.Item>
        <ListGroup.Item
          action
          onClick={() => selectFolder(DocumentFolderType.SHARED)}
          className="shared"
          active={state.document.selectedFolderId === DocumentFolderType.SHARED}
        >
          <p>{t('document.label.DocumentFolderSection.shared')}</p>
          {/* TODO : ListGroup 게시판 항목 게시글 갯수 가리기 => 현재는 항목 갯수가 아니라 임의 값(5)로 되어 있음(UI에 기능 추가시 주석 제거)
          <Badge>5</Badge>
          */}
        </ListGroup.Item>
        {props.folders &&
          props.folders.map((item, index) => (
            <ListGroup.Item
              key={item.folderId}
              action
              variant="light"
              onClick={() => selectFolder(item.folderId)}
              active={state.document.selectedFolderId === item.folderId}
            >
              <p>{item.folderName}</p>
              {/* TODO : ListGroup 게시판 항목 게시글 갯수 가리기 => 현재는 항목 갯수가 아니라 임의 값(0)로 되어 있음(UI에 기능 추가시 주석 제거)
              <Badge>0</Badge>
               */}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
};

export default DocumentFolderSection;
