import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { ActionType, SearchActionType } from '../../reducer/actions';

import Title from './component/Title';
import LeftMenu from './component/LeftMenu';
import CardA from './component/CardA';

import ModuleDetail from './component/ModuleDetail';

import './Container.scss';
import { MegaTrendData } from '../../service/megaTrend/model/MegaTrend';
import { SearchData } from '../../service/model/Search';

/* TODO: Pros 추가 후 lint 제외 삭제 */
// eslint-disable-next-line
export interface ContainerAProps {}

const Container: React.FC<ContainerAProps> = (props: ContainerAProps) => {
  const [moduleDetail, setModuleDetail] = useState(false);
  const [source, setSource] = useState<string>('');
  const [domesticOverseas, setDomesticOverseas] = useState<string>('');
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [cardContents, setCardContents] = useState<MegaTrendData>();
  const { state, dispatch } = useContext(AuthContext);
  const [searchData, setSearchData] = useState<SearchData>();

  const showModuleDetail = () => {
    setModuleDetail(!moduleDetail);
  };

  const showModuleDetailForClick = () => {
    !moduleDetail && setModuleDetail(true);
  };

  return (
    <div id="moduleContainer" /*className={moduleDetail ? 'detail-show' : ''} */>
      <Title></Title>
      <section>
        <LeftMenu
          setSource={setSource}
          source={source}
          setDomesticOverseas={setDomesticOverseas}
          setIsMenuClicked={setIsMenuClicked}
          isMenuClicked={isMenuClicked}
        ></LeftMenu>
        <CardA
          showModuleDetail={showModuleDetailForClick}
          setCardContents={setCardContents}
          domesticOverseas={domesticOverseas}
          source={source}
          setIsMenuClicked={setIsMenuClicked}
          isMenuClicked={isMenuClicked}
        ></CardA>
      </section>
      {/* <ModuleDetail showModuleDetail={showModuleDetail} cardContents={cardContents} moduleDetail={moduleDetail}/> */}
    </div>
  );
};

export default Container;
