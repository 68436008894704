import React from 'react';
import TableauIframe from '../../../layouts/TableauIframe';

import './CompetitionContainer.scss';
export interface CompetitionContainerProps {
  currentTableau: string;
  menuName: string;
}

const CompetitionContainer: React.FC<CompetitionContainerProps> = (props: CompetitionContainerProps) => {
  const url = props.currentTableau;
  const menuName = props.menuName;

  const checkIsEmpty = (str: string): boolean => {
    return str !== undefined && str !== '';
  };

  return (
    <div id="competitionContainer" className={'competitionContainer'}>
      {checkIsEmpty(menuName) && (
        <div className={'title'}>
          <span>{menuName}</span>
        </div>
      )}
      {checkIsEmpty(url) && <TableauIframe url={url} width={'100%'} height={'calc(100vh - 200px)'} />}
    </div>
  );
};

export default CompetitionContainer;
