import React, { ReactElement, useEffect, useState } from 'react';
import { NavBar } from '../../layouts';
import MiContainer from './MiContainer';
import { MenuListI, MenuI } from '../../service/model/Menu';
import MenuService from '../../service/MenuService';
import './MiPortalPage.scss';

const MiPortalPage: React.FC = (): ReactElement => {
  const [menuList, setMenuList] = useState<MenuListI[]>();
  const [currentSideMenu, setCurrentSideMenu] = useState<MenuI>();

  const menuService: MenuService = new MenuService();
  /* eslint-disable */
  useEffect(() => {
    const _menuList = async () => {
      return await menuService.getMenuList();
    };
    _menuList().then(response => {
      setMenuList(response);
      if (!currentSideMenu) {
        setCurrentSideMenu(response[0].menuLv1);
      }
    });
  }, []);
  /* eslint-enable */

  const getmenuLv2List = (_menuList: MenuListI[], _currentSideMenu: MenuI): MenuI[] => {
    const _menuLv2List: MenuI[] | undefined = _menuList.filter((val: MenuListI) => {
      return val.menuLv1.menuCode === _currentSideMenu.menuCode;
    })[0].menuLv2;
    // TODO:: login
    return _menuLv2List ? _menuLv2List : [];
  };

  return (
    <div id="MiPocPage" className="MiPocPage">
      {menuList && menuList.length > 0 && currentSideMenu && (
        <>
          <header>
            <NavBar menuList={menuList} currentSideMenu={currentSideMenu} setCurrentSideMenu={setCurrentSideMenu} />
          </header>
          <MiContainer menuLv2List={getmenuLv2List(menuList, currentSideMenu)} menuLv1={currentSideMenu} />
        </>
      )}
    </div>
  );
};

export default MiPortalPage;
