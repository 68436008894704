import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { YoutubeApiCondition } from './model/Youtube';

export default class YoutubeService extends BaseService {
  async retrieveYoutube(condition: YoutubeApiCondition): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/youtube/dataapi/total/information';
    const serviceName = Service.MIBE;
    const params = condition;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
}
