import React, { ReactElement, CSSProperties, useContext, useEffect, useState } from 'react';
import { ButtonGroup, Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import Avatars from '../../layouts/Avatars';
import { Notice } from '../../service/notice/model/Notice';
import ProfileService from '../../service/ProfileService';
import { ShareType } from '../../service/share/model/enum/Share.enum';
import './NoticeModal.scss';

interface Props {
  showNoticesDrawer: Function;
}

export const NoticeModal: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const resultNoticeList: Notice[] = [];
  const [noticeList, setNoticeList] = useState<Notice[]>([]);
  const profileService = new ProfileService();
  const [profileRootClose, setProfileRootClose] = useState<boolean>(true);
  const avatarImage: string = profileService.getProfileImageFileName();
  const imageStyle: CSSProperties = profileService.getImageStyle();

  useEffect(() => {
    if (state.showNoticeDrawer) {
      searchNoticeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showNoticeDrawer]);

  const searchNoticeList = async () => {
    const response = await state.noticeService.retrieveSharedHistoryList();
    if (response) {
      response.forEach(item => {
        resultNoticeList.push(item);
      });
      setNoticeList(resultNoticeList);
    }
  };

  const getSharedTypeName = (sharedType: string) => {
    let result: string;

    switch (sharedType) {
      case ShareType.BOOKMARK:
        result = t('notice.label.NoticeModal.bookmark');
        break;
      case ShareType.MEGA_TREND:
        result = t('notice.label.NoticeModal.megaTrend');
        break;
      case ShareType.MI_NOTE:
        result = t('notice.label.NoticeModal.note');
        break;
      case ShareType.NEWS:
        result = t('notice.label.NoticeModal.news');
        break;
      default:
        result = '';
    }
    return result;
  };

  return (
    <div id="Notice">
      <div className="header">
        <h3>{t('notice.label.NoticeModal.notice')}</h3>
        <ButtonGroup>
          <Button
            className="btn-icon"
            onClick={() => {
              props.showNoticesDrawer(false);
            }}
          >
            <i className="ic-close"></i>
          </Button>
        </ButtonGroup>
      </div>
      <div className="body">
        {noticeList.map((item, index) => {
          return (
            <div className="notice-detail" key={index}>
              <Button className="btn-icon">
                <i className="ic-close"></i>
              </Button>
              <aside>
                <div className="avatarImg on">
                  <Image src={Avatars[item.avatarType]} style={{ backgroundColor: item.avatarBackgroundColor }} />
                </div>
              </aside>
              <section>
                <div className="notice-item">
                  <div className="createdMember">
                    <b>{item.createdMemberName}</b>
                    {t('notice.label.NoticeModal.sir')} {getSharedTypeName(item.sharedType)}
                    {t('notice.label.NoticeModal.isShared')}
                    <span>10분전</span>
                  </div>
                  <div className="sharedComment">{item.sharedComment}</div>
                  <div className="sharedList">
                    {item.sharedHistoryDetail.map((detail, idx) => {
                      return (
                        <div className="sharedDetail" key={detail.title + idx}>
                          <a className="sharedUrl" href="#">
                            {detail.title}
                          </a>{' '}
                          <span>{detail.source}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default NoticeModal;
