import React, { useContext, useState } from 'react';
import { AutoComplete } from 'rsuite';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import { Button } from 'react-bootstrap';
import { CategoryDelimeter } from '../../../../service/interests/model/enum/Interest.enum';
import { InterestResponse } from '../../../../service/interests/model/Interest';
import { Toast } from '../../../../reducer/states';
import './Interests.scss';

export interface InputAutoCompleteProps {
  interestData: InterestResponse[];
  onSelectItem: (selectedCategory: string) => void;
  personalData: InterestResponse[];
}

export const InputAutoComplete: React.FC<InputAutoCompleteProps> = (props: InputAutoCompleteProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState<string>('');

  let isDuplicated = false;

  const handleOnChange = (val, e) => {
    if (isDuplicated) {
      setInputValue(inputValue);
    } else {
      setInputValue(getCategoryName(val));
    }
  };

  const handleOnSelect = (item, e) => {
    const toast: Toast = {
      toastMessage: '',
      showToast: true,
    };
    if (isExistPersonalData(getCategoryCode(item.label))) {
      isDuplicated = true;
      toast.toastMessage = t('interests.message.duplicateInterest');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
    } else {
      props.onSelectItem(getCategoryCode(item.label));
    }
  };

  const clearInput = () => {
    setInputValue('');
  };

  const isExistPersonalData = category => {
    return props.personalData.filter(elem => {
      return elem.category === category;
    }).length > 0
      ? true
      : false;
  };

  const getCategoryCode = data => {
    return data.split(CategoryDelimeter.CODE_NAME)[1];
  };

  const getCategoryName = data => {
    return data.split(CategoryDelimeter.CODE_NAME)[0];
  };

  const getCategoryNameExceptTopCategory = data => {
    const categoryName = getCategoryName(data);
    return categoryName.substr(categoryName.indexOf(CategoryDelimeter.ITEM));
  };

  return (
    <>
      <AutoComplete
        placeholder={t('interests.inputPlaceholder')}
        data={props.interestData.map(elem => elem.categoryName + CategoryDelimeter.CODE_NAME + elem.category)}
        onChange={handleOnChange}
        onSelect={handleOnSelect}
        value={inputValue}
        filterBy={(value, item) =>
          inputValue !== '' &&
          getCategoryNameExceptTopCategory(item.label)
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1
        }
        renderItem={item => {
          return (
            <div className={isExistPersonalData(getCategoryCode(item.label)) ? 'disabled' : ''}>
              <span>{item.label.split(CategoryDelimeter.ITEM)[0]}</span>
              {reactStringReplace(getCategoryNameExceptTopCategory(item.label), inputValue, (match, i) => (
                <span key={i} className="keyword">
                  {match}
                </span>
              ))}
            </div>
          );
        }}
      />
      <Button className="remove" onClick={clearInput}></Button>
    </>
  );
};
export default InputAutoComplete;
