import { version } from 'cypress/types/bluebird';
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { Action, ActionType } from '../../reducer/actions';
import {
  TermsResponse,
  TermsAgreementCondition,
  TermsShowType,
  AgreeTerms,
  TermsTypeCode,
} from '../../service/terms/model/Terms';
import './TermsPage.scss';

export interface TermsPageProps {
  memberId: number;
  termsCode: string;
  showType: string;
  termsVersion?: string;
  onHide: () => void;
}

const TermsPage: React.FC<TermsPageProps> = (props: TermsPageProps) => {
  const { dispatch, state } = useContext(AuthContext);
  const [terms, setTerms] = useState<TermsResponse>();
  const [termsId, setTermsId] = useState<number>(0);
  const [versions, setVersions] = useState<string[]>([]);
  const { t } = useTranslation();

  const reqTerms = {
    termsTypeCode: props.termsCode,
    termsVersion: props.termsVersion || '',
  };

  /* eslint-disable */
  useEffect(() => {
    switch (reqTerms.termsTypeCode) {
      case TermsTypeCode.SERVICE:
        retrieveTerms();
        break;

      case TermsTypeCode.PRIVACY:
        (async () => {
          const response = await state.termsService.getVersions(reqTerms);
          if (response && response.length > 0) {
            reqTerms.termsVersion = response[0].termsVersion; //최신 버전
            let resultVersions: string[] = [];

            response.forEach(elem => {
              resultVersions.push(elem.termsVersion);
            });

            setVersions(resultVersions);
            retrieveTerms();
          } else {
            dispatch({
              type: ActionType.SHOW_TOAST,
              toast: {
                showToast: true,
                toastMessage: t('terms.message.get.fail'),
              },
            });
          }
        })();
        break;

      default:
        break;
    }
  }, []);
  /* eslint-enable */

  const handleAgreeTerms = (): void => {
    const reqParam: TermsAgreementCondition = {
      termsId: termsId,
      memberId: props.memberId,
      termsAgreeYn: AgreeTerms.AGREE,
    };

    const agree = async () => {
      return await state.termsService.agreeTerms(reqParam);
    };
    agree().then(response => {
      if (!response) {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('terms.message.agree.fail'),
          },
        });
      } else {
        props.onHide();
      }
    });
  };

  const handleChangeVersion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    reqTerms.termsVersion = e.target.value;
    retrieveTerms();
  };

  const retrieveTerms = () => {
    const terms = async () => {
      return await state.termsService.getTerms(reqTerms);
    };
    terms().then(response => {
      if (response) {
        setTerms(response);
        setTermsId(response.termsId);
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('terms.message.get.fail'),
          },
        });
      }
    });
  };

  return (
    <>
      {terms && (
        <Modal
          show={true}
          onHide={props.onHide}
          backdrop={props.showType == TermsShowType.AGREE ? 'static' : true}
          id="TermsPage"
          className="terms-modal"
          keyboard={props.showType == TermsShowType.AGREE ? false : true}
          scrollable={true}
          size="lg"
          centered
        >
          <Modal.Header
            closeButton={props.showType == TermsShowType.AGREE ? false : true}
            className="terms-modal-header"
          >
            <Modal.Title className="terms-modal-title">{terms && terms.termsTitle}</Modal.Title>
            {props.termsCode == TermsTypeCode.PRIVACY && (
              <div className="revisionDate">
                <span>{t('terms.text.revisionDate')}</span>
                <select className="terms-modal-header-combobox" onChange={handleChangeVersion}>
                  {versions.map((elem, i) => {
                    return (
                      <option key={i} value={elem}>
                        {elem}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </Modal.Header>
          <Modal.Body className="terms-modal-body">
            <div
              className="terms-content"
              dangerouslySetInnerHTML={{
                __html: terms ? (terms.termsDetailContents ? terms.termsDetailContents : '') : '',
              }}
            />
          </Modal.Body>
          {props.showType == TermsShowType.AGREE && (
            <Modal.Footer className="terms-modal-footer">
              <Button id="agreeTermsBtn" className="terms-modal-footer-btn" onClick={handleAgreeTerms}>
                {t('terms.button.agree')}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
};

export default TermsPage;
