export enum DocumentViewType {
  LIST = 'LIST',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
}

export enum DocumentFolderType {
  RECENT = 'RECENT',
  SHARED = 'SHARED',
}
