import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/ko';
import React, { createContext, useReducer } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import './App.scss';
import { ErrorPage, LoginPage, MiPortalPage, UnauthorizedErrorPage } from './pages';
import CommonToast from './pages/common/CommonToast';
import { initialState, reducer } from './reducer';
import { State } from './reducer/states';
import CookieService from './service/CookieService';

interface AuthContextProps {
  state: State;
  dispatch: React.Dispatch<any>;
}

export const AuthContext = createContext({} as AuthContextProps);

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const lang = localStorage.getItem('lang') || 'ko';
  localStorage.setItem('lang', lang.toLocaleLowerCase());

  const cookieService = new CookieService();

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <CommonToast
        toast={{
          toastMessage: state.toast?.toastMessage || '',
          showToast: state.toast?.showToast || false,
          toastLink: state.toast?.toastLink || undefined,
        }}
      />
      <Router>
        <Switch>
          <Route exact path="/">
            {cookieService.getSessionIdInCookie() ? <Redirect to="/mi" /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/mi" render={props => <MiPortalPage />} />
          <Route exact path="/login" render={LoginPage} />
          <Route exact path="/unauthorized" component={UnauthorizedErrorPage} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
