import React, { useState, useContext, useEffect } from 'react';
import { Button, ButtonGroup, ListGroup, Figure, Modal } from 'react-bootstrap';
import { BookmarkAddContentsCondtion } from '../../service/bookmark/model/Bookmark';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'rsuite';
import './YoutubeContents.scss';
import { YoutubeInfo } from '../../service/youtube/model/Youtube';
import YoutubeDrawerPage from '../youtube/YoutubeDrawerPage';
import { AuthContext } from '../../App';
import { ActionType } from '../../reducer/actions';
export interface BookmarkContentsProps {
  contentsList: BookmarkAddContentsCondtion[];
}

export interface YoutubeContentsProps {
  contentsList: YoutubeInfo[];
}

export const YoutubeContents: React.FC<YoutubeContentsProps> = (props: YoutubeContentsProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const showYoutubeDrawer = (isShow: boolean): void => {
    dispatch({
      type: ActionType.SHOW_YOUTUBE_DRAWER,
      showYoutubeDrawer: isShow,
    });
  };
  return (
    <div id="YoutubeContents">
      <div>
        <ListGroup>
          {props.contentsList.map((content, inx) => {
            return (
              <ListGroup.Item key={inx}>
                <Checkbox></Checkbox>
                <Figure>
                  <Figure.Image alt="youtube sample image" src={content.thumbnailsUrl} />
                  <Figure.Caption>youtube sample image</Figure.Caption>
                </Figure>
                <div className="content">
                  <h4>{content.title}</h4>
                  <p>
                    {t('youtube.label.youtubeContents.retrieve')} {content.viewCount}
                    {t('youtube.label.youtubeContents.count')} | {t('youtube.label.youtubeContents.comments')}{' '}
                    {content.commentCount}
                    {t('youtube.label.youtubeContents.commentsCount')}
                  </p>
                  <address>{content.publishedAt ? content.publishedAt.substring(0, 10) : ''}</address>
                </div>
                <Button
                  id={'request' + inx.toString()}
                  variant="outline-info"
                  size="lg"
                  active
                  onClick={() => showYoutubeDrawer(true)}
                  style={{ width: '150px', height: '50px', margin: '15px' }}
                >
                  {t('youtube.label.youtubeContents.analysisRequest')}
                </Button>{' '}
                <YoutubeDrawerPage showYoutubeDrawer={showYoutubeDrawer} />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </div>
  );
};
export default YoutubeContents;
