import React, { ReactElement } from 'react';
import { CardDeck, Row } from 'react-bootstrap';

import { ContentsResponse } from '../../../../service/model/Contents';
import './ContentsCardDeck.scss';

import ContentsCard from './ContentsCard';

export interface ContentsCardProps {
  contentsResponseList: ContentsResponse[];
}

const ContentsCardDeck = (props: ContentsCardProps): ReactElement => {
  return (
    <CardDeck id="ContentsCardDeck">
      {props.contentsResponseList.map((item, index) => {
        //<div>{item.categoryName}</div>
        return <ContentsCard key={index} contentsList={item.contentsList}></ContentsCard>;
      })}
    </CardDeck>
  );
};

export default ContentsCardDeck;
