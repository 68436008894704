import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { SearchMemberRequest } from './model/Member';

export default class MemberService extends BaseService {
  async searchMemberList(request: SearchMemberRequest): Promise<CommonResponse> {
    const url = '/v1/member';
    const serviceName = Service.MIBE;
    const method = Method.GET;
    const params = { keyword: request.keyword };
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
}
