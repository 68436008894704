import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { ActionType, MiMaterialActionType, SearchActionType } from '../../reducer/actions';
import { PaginationOption } from '../../service/model/enum/Pagination.enum';
import { DetailOpenType } from '../../service/model/Search';
import { SearchCondition } from '../../service/search/model/enum/Search.enum';
import { MiMaterialSearchCondition } from '../../service/search/model/Search';
import Pagination from '../common/Pagination';
import SearchItem from './component/SearchItem';
import './MiMaterialPage.scss';
import SearchEmpty from './SearchEmpty';

export interface MiMaterialPageProps {
  keyword: string;
  setLeftMenu: React.Dispatch<number>;
  isChecked: boolean;
  isCheckHover: boolean;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const MiMaterialPage: React.FC<MiMaterialPageProps> = (props: MiMaterialPageProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (state.isInitialPage) {
      searchMiMaterials(PaginationOption.START_PAGE, SearchCondition.DEFAULT_PAGE_COUNT);
    }

    dispatch({
      type: SearchActionType.HIDE_SEARCH_RIGHT,
      searchRight: { isShowRight: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialPage]);

  const searchMiMaterials = async (pageNum: number, countPerPage: number) => {
    const condition: MiMaterialSearchCondition = {
      size: countPerPage,
      page: pageNum,
      keyword: props.keyword,
    };
    const response = await state.searchService.searchMiMaterials(condition);
    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: MiMaterialActionType.SET_MI_MATERIAL_RESULT,
        miMaterial: response.data,
      });
    } else {
      dispatch({
        type: MiMaterialActionType.SET_MI_MATERIAL_RESULT,
        miMaterial: { total: { value: 0 }, items: [] },
      });
      dispatch({
        type: ActionType.SHOW_TOAST,
        toast: {
          showToast: true,
          toastMessage: t('search.message.UE'),
        },
      });
    }
    setCurrentPage(pageNum);
  };

  return (
    <Fragment>
      {state.miMaterial.total.value === 0 ? (
        <SearchEmpty />
      ) : (
        <div className={'MiMaterialPage flex vbox'}>
          <div className={'title flex hbox between'}>
            <div className={'flex'}>{t('search.label.category.miMaterial')}</div>
            <div className={'flex center paging_numbers'}>
              {state.searchService.getDefaultItemNumberStateDisplayStr(currentPage, state.miMaterial.total.value, '') +
                t('search.label.category.count')}
            </div>
          </div>
          <div className={'flex vbox'}>
            {state.miMaterial.items.map((item, index) => {
              return (
                <SearchItem
                  isCheckHover={props.isCheckHover}
                  isChecked={props.isChecked}
                  setCheckHover={props.setCheckHover}
                  setChecked={props.setChecked}
                  key={index}
                  searchData={state.searchService.getSearchDataByMiMaterial(item)}
                  detailOpenType={DetailOpenType.DETAIL_VIEW}
                />
              );
            })}
            <Pagination
              totalCount={state.miMaterial.total.value}
              countPerPage={SearchCondition.DEFAULT_PAGE_COUNT}
              onPageSelect={searchMiMaterials}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MiMaterialPage;
