import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import Pagination from '../../pages/common/Pagination';
import { GoogleSearchActionType, SearchActionType } from '../../reducer/actions';
import { PaginationOption } from '../../service/model/enum/Pagination.enum';
import { StatusCode } from '../../service/model/enum/Status.enum';
import { DetailOpenType } from '../../service/model/Search';
import { SearchType } from '../../service/search/model/enum/Search.enum';
import { GoogleSearchCondition } from '../../service/search/model/GoogleSearch';
import SearchItem from './component/SearchItem';
import GoogleSearchExceed from './GoogleSearchExceed';
import './GoogleSearchPage.scss';
import SearchEmpty from './SearchEmpty';

export interface GoogleResultPageProps {
  keyword: string;
  isChecked: boolean;
  isCheckHover: boolean;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const GoogleSearchPage: React.FC<GoogleResultPageProps> = (props: GoogleResultPageProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const [isExceed, setIsExceed] = useState<boolean>(false);

  useEffect(() => {
    if (state.isInitialPage) {
      searchGoogle(PaginationOption.START_PAGE);
    }

    dispatch({
      type: SearchActionType.HIDE_SEARCH_RIGHT,
      searchRight: { isShowRight: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialPage]);

  const searchGoogle = async (pageNum: number) => {
    const condition: GoogleSearchCondition = {
      keyword: props.keyword,
      startIndex: (pageNum - 1) * 10 + 1,
    };
    const response = await state.googleSearchService.getResults(condition);
    if (response) {
      if (response.successOrNot === 'Y') {
        const searchResult = response.data.searchResults;
        dispatch({
          type: GoogleSearchActionType.SET_GOOGLE_RESULT_LIST,
          searchResults: searchResult,
          pagingInfo: response.data.pagingInfo,
        });
      } else {
        dispatch({
          type: GoogleSearchActionType.SET_GOOGLE_RESULT_LIST,
          searchResults: [],
          pagingInfo: {
            totalCount: 0,
            countPerPage: 0,
            startIndex: 0,
            endIndex: 0,
          },
        });

        if (response.statusCode === StatusCode.NO_DATA) {
          setIsNoData(true);
          setIsExceed(false);
        }
        if (response.statusCode === StatusCode.QUOTA_EXCEEDED) {
          setIsNoData(false);
          setIsExceed(true);
        }
      }
    }
  };

  return (
    <Fragment>
      {state.google.pagingInfo.totalCount > 0 ? (
        <div className={'GoogleSearchPage flex vbox'}>
          <div className={'title flex hbox between'}>
            <div className={'flex googleSearch'}>{t('search.label.category.search')}</div>
            <div className={'flex center paging_numbers'}>
              {state.google.pagingInfo.startIndex +
                '-' +
                state.google.pagingInfo.endIndex +
                ' / ' +
                state.google.pagingInfo.totalCount +
                t('search.label.category.count')}
            </div>
          </div>
          <div id="searchResult" className={'flex vbox'}>
            {state.google.searchResults.map((item, index) => {
              return (
                <SearchItem
                  key={index}
                  searchData={{
                    title: item.title,
                    content: item.snippet,
                    source: item.displayLink,
                    date: item.snippet,
                    crawlData: {
                      crawlDataId: item.link,
                      crawlDate: '',
                      addDate: '',
                      htmlContent: '',
                      morphemeWords: '',
                    },
                    dataType: SearchType.GOOGLE,
                  }}
                  detailOpenType={DetailOpenType.NEW_TAB}
                  detailOpenUrl={item.link}
                  isCheckHover={props.isCheckHover}
                  isChecked={props.isChecked}
                  setCheckHover={props.setCheckHover}
                  setChecked={props.setChecked}
                />
              );
            })}
            {state.google.searchResults && (
              <Pagination
                totalCount={state.google.pagingInfo.totalCount}
                countPerPage={state.google.pagingInfo.countPerPage}
                onPageSelect={searchGoogle}
              />
            )}
          </div>
        </div>
      ) : (
        <div id="googleNoData" className="GoogleSearchPage flex vbox">
          {isNoData && <SearchEmpty />}
          {isExceed && (
            <div>
              <div className={'title flex hbox between googleSearch'}>{t('search.label.category.search')}</div>
              <div id="googleExceed" className={'search-left-text'}>
                <GoogleSearchExceed keyword={props.keyword} />
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default GoogleSearchPage;
