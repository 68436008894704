import React, { ReactElement, useState } from 'react';
import './Footer.scss';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TermsPage from '../pages/terms/TermsPage';
import { TermsShowType, TermsTypeCode } from '../service/terms/model/Terms';

const Footer: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
  const [termsCode, setTermsCode] = useState<string>(TermsTypeCode.SERVICE);

  const showTermModal = (termsType: string) => {
    setTermsCode(termsType);
    setIsShowTermModal(true);
  };

  const hideTermModal = () => {
    setIsShowTermModal(false);
  };

  return (
    <div id="mainFooter">
      {isShowTermModal && (
        <TermsPage
          onHide={hideTermModal}
          memberId={1}
          showType={TermsShowType.DISPLAY}
          termsCode={termsCode}
        ></TermsPage>
      )}
      <ul>
        <li>
          <Button id="btnShowServiceTerm" onClick={() => showTermModal(TermsTypeCode.SERVICE)}>
            {t('common.label.Footer.serviceTerm')}
          </Button>
        </li>
        <li>
          <Button id="btnShowPersonalInfoTerm" onClick={() => showTermModal(TermsTypeCode.PRIVACY)}>
            <b>{t('common.label.Footer.personalInfoTerm')}</b>
          </Button>
        </li>
        <li>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="helpDesk-tooltip">
                <ul>
                  <li className="call">{t('common.label.Footer.call')}</li>
                  <li className="mail">{t('common.label.Footer.email')}</li>
                </ul>
              </Tooltip>
            }
          >
            <Button id="btnHelpDesk" className="dark">
              {t('common.label.Footer.helpDesk')}
            </Button>
          </OverlayTrigger>
        </li>
        <li>
          <span className="light">{t('common.label.Footer.copyRight')}</span>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
