import React, { ReactElement, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BookmarkContainer.scss';
import BookmarkModal from './BookmarkModal';
import { AuthContext } from '../../App';
import { ActionType, BookmarkActionType } from '../../reducer/actions';
import { useTranslation } from 'react-i18next';
import { FolderResponse, BookmarkAddContentsCondtion, CrawlData } from '../../service/bookmark/model/Bookmark';
import { Menu } from '../../service/model/Menu';
import { SearchData } from '../../service/model/Search';
import AggregateService from '../../service/aggregate/AggregateService';
import { ActionFlag } from '../../service/search/model/enum/Search.enum';

export interface BookmarkContainerProps {
  searchData: SearchData;
  crawlData: CrawlData;
  onOverlayRootClose: (show: boolean) => void;
}

const BookmarkContainer: React.FC<BookmarkContainerProps> = (props: BookmarkContainerProps): ReactElement => {
  const { dispatch, state } = useContext(AuthContext);
  const [folders, setFolders] = useState<FolderResponse[]>([]);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const aggregateService = new AggregateService();

  /* eslint-disable */
  useEffect(() => {
    (async () => {
      const response = await state.bookmarkService.retrieveFolderList();
      if (response) {
        let resultFolderList: FolderResponse[] = [];
        response.forEach(elem => {
          resultFolderList.push(elem);
        });
        setFolders(resultFolderList);
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    })();
  }, [state.bookmark.isAddFolder]);
  /* eslint-enable */

  const handleOnShowModal = (): void => {
    props.onOverlayRootClose(false);
    setIsShowModal(true);
  };

  const handleOnHideModal = (): void => {
    props.onOverlayRootClose(true);
    setIsShowModal(false);
  };

  const handleAddFolder = (): void => {
    dispatch({
      type: BookmarkActionType.ADD_FOLDER,
      isAddFolder: true,
    });
  };

  const handleOnClickFolder = (e: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    if (props.searchData) {
      aggregateService.addAggregateData(props.searchData, ActionFlag.BOOKMARK).then(response => {}); // eslint-disable-line
    }

    const folderId = parseInt(e.currentTarget.dataset.id as string);
    const request: BookmarkAddContentsCondtion = state.bookmarkService.getBookmarkContentsByCrawlData(
      props.crawlData,
      folderId
    );

    (async (): Promise<void> => {
      const response = await state.bookmarkService.addBookmarkContents(request);
      if (response) {
        dispatch({
          type: BookmarkActionType.ADD_CONTENTS,
          isAddContents: true,
        });
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.addBookmarkContents.reponse.SUCCESS'),
            toastLink: {
              linkMessage: t('bookmark.ui.linkMessage'),
              onLinkClick: () => {
                dispatch({
                  type: BookmarkActionType.SHOW_BOOKMARK,
                  showBookmark: false,
                });
                dispatch({
                  type: BookmarkActionType.EXPAND_BOOKMARK,
                  isExpand: true,
                  folderId: folderId,
                });
                dispatch({
                  type: BookmarkActionType.SET_IS_INITIAL,
                  isInitial: false,
                });
                dispatch({
                  type: ActionType.CHANGE_MENU,
                  menuLv2: Menu.BOOKMARK,
                });
                dispatch({
                  type: ActionType.SET_SEARCH_RESULT,
                  searchResult: false,
                });
              },
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    })();
  };

  return (
    <div id="BookmarkContainer">
      <BookmarkModal
        folders={folders}
        onAdd={handleAddFolder}
        onHide={handleOnHideModal}
        show={isShowModal}
      ></BookmarkModal>
      <div className="Bookmark-header">{t('bookmark.ui.bookmarkHeader')}</div>
      <div className="Bookmark-body">
        <ul>
          {folders &&
            folders.map((elem, i) => {
              return (
                <li
                  key={elem.bookmarkFolderId}
                  data-id={elem.bookmarkFolderId}
                  className="Bookmark-body-folder-title"
                  onClick={handleOnClickFolder}
                >
                  <i className="ic-folder"></i>
                  {elem.bookmarkFolderName}
                </li>
              );
            })}
        </ul>
      </div>
      <div id="Bookmark-footer" className="Bookmark-footer" onClick={handleOnShowModal}>
        {t('bookmark.ui.bookmarkFooter')}
      </div>
    </div>
  );
};

export default BookmarkContainer;
