import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import { ShareActionType } from '../../../reducer/actions';
import { SelectMember } from '../../../service/share/model/Share';
import { SearchMemberRequest, MemberResponse } from '../../../service/member/model/Member';
import { TagGroup, Tag } from 'rsuite';
import { ShareMember } from './ShareMember';
import AsyncSelect from 'react-select/async';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MemberDisplayType, ShareConstant } from '../../../service/share/model/enum/Share.enum';
import './ShareMemberSection.scss';

export const ShareMemberSection: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const [memberListDisplay, setMemberListDisplay] = useState<string>(ShareConstant.VISIBLE);

  const handleClose = (memberId: number): void => {
    dispatch({
      type: ShareActionType.ADD_MEMBERS,
      sharedMembers: state.share.sharedMembers?.filter(elem => elem.memberId !== memberId),
    });
  };
  const memberLoadOptions = (keyword: string, callback: any): void => {
    const selectMemberList: SelectMember[] = [];
    const requestKeyword = keyword.trim();
    if (requestKeyword.length > 1) {
      const request: SearchMemberRequest = {
        keyword: requestKeyword,
      };
      state.memberService.searchMemberList(request).then(response => {
        if (response && response.successOrNot === 'Y') {
          const memberList: MemberResponse[] = response.data;
          if (memberList.length > 0) {
            memberList.forEach(elem => {
              selectMemberList.push({
                value: elem,
                label: (
                  <ShareMember
                    keyword={requestKeyword}
                    memberId={elem.memberId}
                    avatarType={state.shareService.getAvatarFileName(elem.avatarType)}
                    memberKorName={elem.memberKorName}
                    memberEngName={elem.memberEngName}
                    jobTitle={elem.jobTitle}
                    memberCompany={elem.memberCompany}
                    deptKorName={elem.deptKorName}
                    avatarBackgroundColor={elem.avatarBackgroundColor}
                    memberDisplayType={MemberDisplayType.SEARCHED}
                  />
                ),
              });
            });
          }
        }
        if (memberListDisplay === ShareConstant.INVISIBLE) setMemberListDisplay(ShareConstant.VISIBLE);
        callback(selectMemberList);
      });
    } else {
      if (memberListDisplay === ShareConstant.VISIBLE) setMemberListDisplay(ShareConstant.INVISIBLE);
    }
  };
  const handleChange = (elem): void => {
    const member: MemberResponse = {
      memberId: elem.value.memberId,
      avatarType: state.shareService.getAvatarFileName(elem.value.avatarType),
      memberKorName: elem.value.memberKorName,
      memberEngName: elem.value.memberEngName,
      jobTitle: elem.value.jobTitle,
      memberCompany: elem.value.memberCompany,
      deptKorName: elem.value.deptKorName,
      avatarBackgroundColor: elem.value.avatarBackgroundColor,
    };
    if (
      state.share.sharedMembers &&
      state.share.sharedMembers.filter(elem => elem.memberId === member.memberId).length === 0
    ) {
      dispatch({
        type: ShareActionType.ADD_MEMBERS,
        sharedMembers: [...state.share.sharedMembers, member],
      });
    }
    setMemberListDisplay(ShareConstant.INVISIBLE);
  };

  return (
    <div id="ShareMemberSection" className={'selWrap ' + memberListDisplay}>
      <AsyncSelect
        className="search-member-select"
        placeholder={t('share.label.ShareModal.nameOrTeam')}
        loadOptions={memberLoadOptions}
        value={null}
        onChange={handleChange}
        noOptionsMessage={() => null}
      />
      <TagGroup className="selected-member-group">
        {state.share.sharedMembers &&
          state.share.sharedMembers.map((elem, idx) => (
            <OverlayTrigger
              key={idx}
              placement="top"
              trigger="hover"
              overlay={
                <Tooltip id={'member-tooltip'} className="member-tooltip">
                  <div>
                    <span>{elem.memberCompany}</span>
                    <span>{elem.deptKorName}</span>
                  </div>
                </Tooltip>
              }
            >
              <Tag className="selected-member" key={idx} onClose={(e): void => handleClose(elem.memberId)} closable>
                <ShareMember
                  memberId={elem.memberId}
                  avatarType={elem.avatarType}
                  memberKorName={elem.memberKorName}
                  memberEngName={elem.memberEngName}
                  jobTitle={elem.jobTitle}
                  avatarBackgroundColor={elem.avatarBackgroundColor}
                  memberDisplayType={MemberDisplayType.SELECTED}
                />
              </Tag>
            </OverlayTrigger>
          ))}
      </TagGroup>
    </div>
  );
};
export default ShareMemberSection;
