import cookie from 'react-cookies';
import { Session, SessionInfo } from './model/SessionInfo';
import { RecommendedSearch } from './search/model/enum/Search.enum';
export default class CookieService {
  public setCookie(name: string, value: string, maxAge?: number): void {
    const defaultMaxAge = 24 * 60 * 60 * 365 * 10; // Cookie cannot have permanent life. Set lifetime 10 years.
    cookie.save(name, value, { path: '/', maxAge: maxAge ? maxAge : defaultMaxAge });
  }

  public getCookie(name: string): string {
    const cookieValue = cookie.load(name);
    return cookieValue ? cookieValue : '';
  }

  public deleteCookie(name: string): void {
    cookie.remove(name, { path: '/' });
  }

  public getSessionInfoInCookie = (): SessionInfo => {
    const sessionInfo: SessionInfo = {} as SessionInfo;

    if (this.getSessionIdInCookie() !== '') {
      const session = this.getCookie(Session.COOKIE_NAME);
      Object.entries(session).forEach(([key, value]) => {
        sessionInfo[key] = value;
      });
    }
    return sessionInfo;
  };

  public setSessionToCookie = (sessionInfo: SessionInfo, maxAge?: number): void => {
    this.setCookie(Session.COOKIE_NAME, JSON.stringify(sessionInfo), maxAge);
  };

  public getSessionInCookie = (name: string): string => {
    const sessionValue = this.getCookie(Session.COOKIE_NAME)[name];
    return sessionValue ? sessionValue : '';
  };

  public getSessionIdInCookie = (): string => {
    return this.getSessionInCookie(Session.SESSION_ID);
  };

  public getRecommendedSearch = (): string => {
    if (!this.getCookie(RecommendedSearch.COOKIE_NAME)) {
      this.setRecommendedSearch(RecommendedSearch.TOGGLE_ON);
    }
    return this.getCookie(RecommendedSearch.COOKIE_NAME);
  };

  public setRecommendedSearch = (toggleRecommendedSearch: string): void => {
    this.setCookie(RecommendedSearch.COOKIE_NAME, toggleRecommendedSearch);
  };
}
