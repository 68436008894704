import { SearchDetailCopyInfo } from './model/SearchDetail';
import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { SearchData } from '../model/Search';
import { Service } from '../model/Service';
import { RelationCode, SearchCondition, SearchType } from './model/enum/Search.enum';
import { MiMaterial, MiMaterialSearchCondition, NewsData, NewsListSearchCondtion } from './model/Search';
import { CrawlData } from '../bookmark/model/Bookmark';

export default class SearchService extends BaseService {
  async searchNewsReportList(condition: NewsListSearchCondtion): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/news';
    const params = condition;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;
    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
  getSearchDataByNewsData(item: NewsData): SearchData {
    return {
      title: item.title,
      date: item.publishDate,
      source: item.author, //TODO: 추후 출처 정보 있을 시 출처로 바꿔야함
      content: item.content,
      crawlData: {
        crawlDataId: item.crawlDataId,
        crawlDate: item.crawlDate,
        url: item.url,
        publishDate: item.publishDate,
        title: item.title,
        mediaType: item.mediaType,
        content: item.content,
        mediaDetailId: item.mediaDetailId,
        imageUrl: item.imageUrl,
        fileUrl: item.fileUrl,
        author: item.author,
        addDate: item.addDate,
        htmlContent: item.htmlContent,
        categorizationKeyword: item.categorizationKeyword,
        morphemeWords: item.morphemeWords,
      },
      dataType: SearchType.NEWS,
    };
  }
  getDefaultItemNumberStateDisplayStr(currentPage: number, totalCount: number, relation: string): string {
    return (
      (currentPage - 1) * SearchCondition.DEFAULT_PAGE_COUNT +
      1 +
      '-' +
      (totalCount >= currentPage * SearchCondition.DEFAULT_PAGE_COUNT
        ? currentPage * SearchCondition.DEFAULT_PAGE_COUNT
        : totalCount) +
      ' / ' +
      totalCount +
      (relation === RelationCode.GTE ? '+' : '')
    );
  }

  /** TODO: BE 서비스 개발 이후 수정필요 */
  /* eslint-disable */
  async searchMiMaterials(condition: MiMaterialSearchCondition): Promise<CommonResponse> {
    const dummy: MiMaterial[] = [];
    for (let i = (condition.page - 1) * condition.size; i < condition.page * condition.size; i++) {
      dummy.push({
        documentId: i + 1,
        title: i + 1 + ': 전기차 관련 거시환경 분석',
        content:
          '그렇다 보니 전기차를 생산하는 기업들은 배터리에 대한 투자를 아끼지 않으며, 회사 자체적으로 개발하거나 배터리 전문 기업으로부터 납품받아 차에 장착한다.',
        author: 'PETER@LG전자',
        creationDate: '2020-10-28 07:20:46',
        lastUpdateDate: '2020-10-28 07:20:46',
      });
    }

    const response: CommonResponse = {
      successOrNot: 'Y',
      statusCode: 'SUCCESS',
      desc: 'Success Response',
      data: {
        total: {
          value: 30,
        },
        items: dummy,
      },
    };

    return response;
  }
  /* eslint-enable */

  getSearchDataByMiMaterial(item: MiMaterial): SearchData {
    return {
      title: item.title,
      date: item.lastUpdateDate,
      source: item.author,
      content: item.content,
      dataType: SearchType.MY_WORK,
    };
  }

  // 향후 상세 데이터 유형 추가시 SearchDetailCopyInfo 형식에 맞게 추가 필요.
  getDetailContentCopyInfo = (crawlData: CrawlData | undefined): SearchDetailCopyInfo | undefined => {
    let result: SearchDetailCopyInfo | undefined = undefined;
    if (crawlData) {
      result = {
        title: crawlData.title ? crawlData.title : '',
        url: crawlData.url ? crawlData.url : '',
        /* TODO: 향후 Tag 정보 수신시 Tag 정보 추가 필요 하기는 예시
        tags: crawlData.tagInfo
        */
      };
    }
    return result;
  };
}
