import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Divider } from 'rsuite';
import { AuthContext } from '../../App';
import { ReactComponent as IconCheck } from '../../icons/ic-check.svg';
import { ReactComponent as IconTrash } from '../../icons/ic-trash-1214.svg';
import { ActionType, ShareActionType } from '../../reducer/actions';
import { Toast } from '../../reducer/states';
import { ShareConstant } from '../../service/share/model/enum/Share.enum';
import { ShareRequest } from '../../service/share/model/Share';
import { MemberResponse } from '../../service/member/model/Member';
import { ShareMemberSection } from './component/ShareMemberSection';
import './ShareModal.scss';

export const ShareModal: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const [sharedComment, setSharedComment] = useState<string>('');
  const sharedMembers: MemberResponse[] = state.share.sharedMembers || [];

  useEffect(() => {
    if (state.share.show) {
      setSharedComment('');
      dispatch({
        type: ShareActionType.ADD_MEMBERS,
        sharedMembers: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.share.show]);

  const handleClose = (): void => {
    dispatch({
      type: ShareActionType.SHOW_SHARE_MODAL,
      show: false,
    });
  };

  const deleteItem = (idx: number): void => {
    const sharedDetails = state.share.sharedDetails;
    if (sharedDetails.length === 1) return;
    sharedDetails.splice(idx, 1);
    dispatch({
      type: ShareActionType.SET_SHARED_DETAILS,
      sharedDetails: sharedDetails,
    });
  };

  const saveShare = () => {
    const toast: Toast = {
      toastMessage: '',
      showToast: true,
    };

    const shareRequest: ShareRequest = {
      sharedHistory: {
        sharedType: state.share.sharedType,
        sharedComment: sharedComment,
      },
      sharedDetails: state.share.sharedDetails,
      sharedMembers: sharedMembers.map(member => member.memberId),
    };
    state.shareService.saveShare(shareRequest).then(response => {
      if (response && response.successOrNot === 'Y') {
        if (response.data.row > 0) {
          toast.toastMessage = t('share.message.shareAlarm', [response.data.row]);
          dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
          handleClose();
        }
      } else {
        toast.toastMessage = t('share.message.shareFail');
        dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
      }
    });
  };

  return (
    <Modal
      id="ShareModal"
      className="ShareModal"
      centered
      backdrop="static"
      show={state.share.show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('share.label.ShareModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="share-detail">
          {state.share.sharedDetails &&
            state.share.sharedDetails.map((item, idx, arr) => {
              return (
                <div className="detail-item" key={item.title + idx}>
                  <span>{item.title}</span>
                  {/* TODO: 추후 URL 복사 기능 추가 시 사용
                  <a className="detail-url" href="#">
                    {t('share.label.ShareModal.urlCopy')}
                  </a>
                  */}
                  {arr.length > 1 && (
                    <i className="ic-trash" onClick={() => deleteItem(idx)}>
                      <IconTrash />
                    </i>
                  )}
                </div>
              );
            })}
        </div>
        <Divider />
        <div className="share-member">
          <p className="subtitle">{t('share.label.ShareModal.member')}</p>
          <ShareMemberSection />
        </div>
        <Divider />
        <div className="share-comment">
          <p className="subtitle">{t('share.label.ShareModal.comment')}</p>
          <Form.Control
            as="textarea"
            maxLength={ShareConstant.SHARE_CONTENT_MAX_LENGTH}
            rows={ShareConstant.SHARE_CONTENT_ROW}
            value={sharedComment}
            onChange={e => setSharedComment(e.currentTarget.value)}
          />
        </div>
        <Divider />
      </Modal.Body>
      <Modal.Footer>
        <p className="share-info">
          <span className="count">{state.share.sharedDetails ? state.share.sharedDetails.length : 0}</span>
          {t('share.label.ShareModal.info')}
        </p>
        <Button variant="primary" id="saveShareButton" onClick={saveShare} disabled={sharedMembers.length === 0}>
          <IconCheck />
          {t('share.label.ShareModal.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShareModal;
