import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './LocalNav.scss';
import { Search } from '../component/Search';
import { MenuI } from '../../../service/model/Menu';

export interface LocalNavProps {
  menuLv2List: MenuI[];
  menuLv2: string;
  setmenuLv2: Function;
  menuClick: Function;
  onSearchChange: Function;
  keyword: string;
}

export const LocalNav: React.FC<LocalNavProps> = (props: LocalNavProps) => {
  const { t } = useTranslation();
  const defaultClassName = '';

  return (
    <div className="LocalNav">
      <Search id="local" keyword={props.keyword} onSearchChange={props.onSearchChange}></Search>
      <ul className="nav-list">
        {props.menuLv2List.map((val: MenuI, index) => {
          return (
            <li
              id={val.menuCode}
              key={index}
              className={[
                defaultClassName,
                val.menuCode === props.menuLv2 ? 'select' : '',
                val.menuCode === 'MENU001001' ? 'home' : '',
              ].join(' ')}
              onClick={() => {
                props.setmenuLv2(val.menuCode);
                val.menuCode === 'MENU001001' ? props.menuClick(true) : props.menuClick(false);
              }}
            >
              {val.menuTitle}
            </li>
          );
        })}
      </ul>
      {/* <ul className="nav-sublist">
        <li>{t('localNav.sublist.process')}</li>
        <li>{t('localNav.sublist.customerResearch')}</li>
      </ul>
      <Button id="widgetSetting" title={t('localNav.widgetSetting')}>{t('localNav.widgetSetting')}</Button> */}
    </div>
  );
};
export default LocalNav;
