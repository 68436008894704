import React, { ReactElement, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginContainer.scss';
import { Container, Row } from 'react-bootstrap';
import LoginSection from './component/LoginSection';
import { AuthContext } from '../../../App';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';
import CommonToast from '../../common/CommonToast';

const LoginContainer: React.FC = (): ReactElement => {
  const { state } = useContext(AuthContext);
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;

  const getLoginContainerBody = () => {
    return (
      <div id="LoginContainer">
        <Container fluid={true}>
          <Row>
            <LoginSection />
          </Row>
        </Container>
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getLoginContainerBody}</CommonLoadingOverlay>;
};

export default LoginContainer;
