import React, { useState, ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginForm.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import LoginResponse from '../../../../service/member/model/LoginResponse';
import LoginRequest from '../../../../service/member/model/LoginRequest';

import ArrowIcon from '../../../../icons/ArrowRight';

import InterestService from '../../../../service/interests/InterestService';
import LoginService from '../../../../service/member/LoginService';
import CookieService from '../../../../service//CookieService';
import TermsService from '../../../../service/terms/TermsService';
import TermsPage from '../../../terms/TermsPage';
import { TermsShowType, TermsTypeCode, TermsAgreeYnCondition } from '../../../../service/terms/model/Terms';
import Interests from '../../../main/component/modal/Interests';

import LoginFixedEmail from './LoginFixedEmail';

const LoginForm: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const loginService = new LoginService();
  const schema = yup.object({
    emailId: yup
      .string()
      .email(t('member.message.login.validation.emailId.common'))
      .required(t('member.message.login.validation.emailId.required')),
  });
  const [messageSubmit, setMessageSubmit] = useState('');

  const clearMessageSubmit = (): void => {
    if (messageSubmit !== '') {
      setMessageSubmit('');
    }
  };

  const cookieService = new CookieService();

  const [showTermModal, setShowTermModal] = useState<boolean>(false);
  const [showInterestModal, setShowInterestModal] = useState<boolean>(false);
  const [interestSetupYn, setInterestSetupYn] = useState<string>('N');
  const [memberId, setMemberId] = useState<number>(0);

  const hideTermsModal = () => {
    setShowTermModal(false);

    if (interestSetupYn === 'Y') {
      goToMiPage(); //TODO: 로그인 성공시 이동하는 page 추후 반영
    } else {
      setShowInterestModal(true);
    }
  };

  const hideInterestModal = () => {
    setShowInterestModal(false);
    goToMiPage();
  };

  const goToMiPage = () => {
    window.location.assign('/mi'); //TODO: 로그인 성공시 이동하는 page 추후 반영
  };

  return (
    <>
      <div id="LoginForm" className="login-form">
        <Formik
          initialValues={{
            emailId: cookieService.getCookie('emailAddress'),
            checkSaveId: cookieService.getCookie('emailAddress').length > 0,
          }}
          validationSchema={schema}
          onSubmit={async (values: FormikValues): Promise<void> => {
            const loginRequest: LoginRequest = {
              email: values.emailId,
            };

            const loginResponse: LoginResponse = await loginService.requestLogin(loginRequest);
            // TODO: 기존 login에 추가로 리턴되는지 확인 후 수정 필요
            if (loginResponse) {
              if (loginResponse.memberId) {
                setMemberId(loginResponse.memberId);
                loginService.setSession(loginResponse);

                values.checkSaveId
                  ? cookieService.setCookie('email', loginRequest.email)
                  : cookieService.deleteCookie('email');

                const termService = new TermsService();
                const interestService = new InterestService();
                const reqParam: TermsAgreeYnCondition = {
                  termsTypeCode: TermsTypeCode.SERVICE,
                  memberId: loginResponse.memberId,
                };
                const checkAgree = await termService.getTermsAgreeYn(reqParam);
                const interestResponse = await interestService.getInitialInterestSetupYn();
                setInterestSetupYn(interestResponse.data.interestSetupYn);
                if (checkAgree) {
                  if (checkAgree.termsAgreeYn == 'Y') {
                    if (interestResponse.data.interestSetupYn !== 'N') {
                      goToMiPage(); //TODO: 로그인 성공시 이동하는 page 추후 반영
                    } else {
                      setShowInterestModal(true);
                    }
                  } else {
                    setShowTermModal(true);
                  }
                }
              } else {
                setMessageSubmit(t('member.message.login.response.submit.INVALID_ACCOUNT'));
              }
            }
          }}
        >
          {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group className="form-email">
                  <Form.Control
                    id="emailId"
                    name="emailId"
                    data-testid="emailId"
                    className="formik-field"
                    required
                    type="text"
                    placeholder={t('member.label.LoginForm.emailIdPlaceholder')}
                    onChange={
                      messageSubmit === ''
                        ? handleChange
                        : () => {
                            clearMessageSubmit();
                          }
                    }
                    onBlur={handleBlur}
                    onFocus={clearMessageSubmit}
                    isInvalid={!!errors.emailId}
                    defaultValue={values.emailId}
                  />
                  <Form.Control.Feedback id="emailIdFeedback" data-testid="emailIdFeedback" type="invalid">
                    {touched.emailId && !!errors.emailId ? errors.emailId : ''}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <div id="submitArea" className="submit-area">
                <div id="submitFeedback" data-testid="submitFeedback" className="submit-feedback">
                  <span>{messageSubmit}</span>
                </div>
                <Form.Row className="submit-row">
                  <Button
                    id="loginSubmitButton"
                    name="loginSubmitButton"
                    data-testid="loginSubmitButton"
                    className="submit-button"
                    type="submit"
                    disabled={isSubmitting || !(!errors.emailId && !!values.emailId)}
                  >
                    <span id="loginSubmitButtonText" data-testid="loginSubmitButtonText" className="submit-button-text">
                      {t('member.label.LoginForm.login')}
                    </span>
                    <ArrowIcon />
                  </Button>
                </Form.Row>
                <LoginFixedEmail handleSubmit={handleSubmit} setFieldValue={setFieldValue} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showTermModal && (
        <TermsPage
          onHide={hideTermsModal}
          memberId={memberId}
          showType={TermsShowType.AGREE}
          termsCode={TermsTypeCode.SERVICE}
        ></TermsPage>
      )}
      {showInterestModal && <Interests isFirst onHide={hideInterestModal}></Interests>}
    </>
  );
};
export default LoginForm;
