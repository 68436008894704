import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { SearchData } from '../model/Search';
import { Service } from '../model/Service';

export default class AggregateService extends BaseService {
  async addAggregateData(aggregateData: SearchData, actionFlag: string): Promise<CommonResponse> {
    const method = Method.POST;
    const url = '/v1/aggregate';
    const params = { actionFlag: actionFlag };
    const body = aggregateData;
    const serviceName = Service.MIBE;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }
}
