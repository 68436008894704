export interface YoutubeApiCondition {
  url: string;
}

export interface YoutubeInfo {
  commentThreads: CommentThreads[];
  publishedAt: string;
  title: string;
  thumbnailsUrl: string;
  thumbnailsUrlWidth: number;
  thumbnailsUrlHeight: number;
  viewCount: string;
  likeCount: string;
  dislikeCount: string;
  favoriteCount: string;
  commentCount: string;
  captionsInfoList: CaptionsInfo[];
}

export interface CommentThreads {
  commentThreads: string;
}

export interface CaptionsInfo {
  caption: string;
  lang: string;
}

export class YoutubeContentsList {
  public static readonly LIST_MAX_LENGTH: number = 10;
}
