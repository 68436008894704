import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import TableauIframe from '../../../../layouts/TableauIframe';

export interface TableauSamplePageProps {} // eslint-disable-line

const TableauSamplePageOneItem: React.FC<TableauSamplePageProps> = (props: TableauSamplePageProps) => {
  const viewItems = [
    'B_01_1_SPEC/SPEC',
    'S_03_ValueMap_HE/ValueMap',
    'S_03_ValueMap_HA/ValueMap',
    'S_03_ValueMap_LGD/ValueMap',
    'C_04_/sheet0',
    'Z_10_Buzz/Buzz',
    'C_02_/_01',
  ];
  const [url, setUrl] = useState<string>();

  let templateText: string | null = '';
  const handleChange = e => {
    templateText = e.target.value;
  };

  const handleButton = e => {
    templateText && setUrl(templateText);
  };

  return (
    <>
      {viewItems.map((item, index) => {
        return (
          <Button key={index} variant="secondary" onClick={() => setUrl(item)} style={{ margin: '10px' }}>
            {item}
          </Button>
        );
      })}
      <Form className={'templateForm'} style={{ display: 'inline' }}>
        <Form.Control
          style={{ display: 'inline', width: '250px' }}
          id="templateInput"
          name="template"
          type="text"
          onChange={handleChange}
        />
        <Button variant="secondary" onClick={handleButton}>
          Send
        </Button>
      </Form>
      <div>{url && <TableauIframe url={url} width={'100%'} height={'100vh'} />}</div>
    </>
  );
};

export default TableauSamplePageOneItem;
