import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './KeywordTree.scss';

export interface KeywordTreeProps {
  Dummy?: any;
}

export const KeywordTree: React.FC<KeywordTreeProps> = (props: KeywordTreeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('keywordTree.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>tree component</Modal.Body>
      <Modal.Footer>
        <Button variant="primary">{t('keywordTree.apply')}</Button>
      </Modal.Footer>
    </>
  );
};
export default KeywordTree;
