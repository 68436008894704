import {
  TermsResponse,
  VersionResponse,
  TermsRetrieveCondition,
  TermsAgreementCondition,
  TermsAgreeYnCondition,
  TermsVersionRetrieveCondition,
} from './model/Terms';
import { Service } from '../model/Service';
import { Method } from '../model/Method';
import { CommonResponse } from '../model/CommonResponse';
import BaseService from '../BaseService';

export default class TermsService extends BaseService {
  async getTerms(condition: TermsRetrieveCondition): Promise<TermsResponse> {
    const method = Method.GET;
    const url = '/v1/terms/agreement';
    const params = condition;
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async agreeTerms(condition: TermsAgreementCondition): Promise<CommonResponse> {
    const method = Method.POST;
    const url = '/v1/terms/agreement';
    const params = null;
    const body = condition;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getVersions(condition: TermsVersionRetrieveCondition): Promise<VersionResponse[]> {
    const method = Method.GET;
    const url = '/v1/terms/versions';
    const params = condition;
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getTermsAgreeYn(condition: TermsAgreeYnCondition): Promise<TermsResponse> {
    const method = Method.POST;
    const url = '/v1/terms/termsAgreeYn';
    const params = null;
    const body = condition;

    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }
}
