import React, { useContext, useState, useRef } from 'react';
import { Modal, Button, Form, Image, Container } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../App';
import { ActionType, BookmarkActionType } from '../../../../reducer/actions';
import {
  BookmarkAddContentsCondtion,
  BookmarkContentsTypeCode,
  CrawlData,
  FolderResponse,
} from '../../../../service/bookmark/model/Bookmark';

import './AddUrl.scss';

import { ReactComponent as IconCheck } from '../../../../icons/ic-check.svg';

export interface AddUrlProps {
  handleCloseAddUrl: Function;
  folderId: number;
  onSuccess: Function;
}

interface AddUrlValuesI {
  url: string;
  title: string;
  description: string;
  image: string;
  isLoaded: boolean;
  folder: number;
}

export const AddUrl: React.FC<AddUrlProps> = (props: AddUrlProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [folders, setFolders] = useState<FolderResponse[]>([]);
  const titleRef = useRef<any>(null);

  const schema = yup.object().shape({
    url: yup.string().required(t('bookmark.message.required.url')),
  });
  const isFormik = true;

  const handleGetUrlInfo = (values: AddUrlValuesI, setFieldValue: Function) => {
    state.bookmarkService.retrieveUrlInfo(values.url).then(response => {
      if (response) {
        setFieldValue('title', response.title, false);
        setFieldValue('image', response.image, false);
        setFieldValue('description', response.description, false);
        setFieldValue('isLoaded', true);
        retrieveBookmarkFolder(setFieldValue);
        titleRef.current?.focus();
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    });
  };

  const retrieveBookmarkFolder = (setFieldValue: Function) => {
    state.bookmarkService.retrieveFolderList().then(response => {
      if (response && response.length > 0) {
        const items: FolderResponse[] = [];
        response.forEach(elem => {
          items.push(elem);
        });
        setFolders(items);
        setFieldValue('folder', items.length > 0 ? items[0].bookmarkFolderId : 0);
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    });
  };

  const createSelectItems = (setFieldValue: Function) => {
    const options: any = [];
    folders.forEach(folder => {
      options.push(
        <option key={folder.bookmarkFolderId} value={folder.bookmarkFolderId}>
          {folder.bookmarkFolderName}
        </option>
      );
    });
    return options;
  };

  const handleUrlChange = (values: AddUrlValuesI, setFieldValue: Function, e: React.FormEvent<HTMLInputElement>) => {
    setFieldValue('url', e.currentTarget.value);
    if (values.isLoaded) {
      setFieldValue('title', '');
      setFieldValue('image', '', false);
      setFieldValue('isLoaded', false, false);
      setFieldValue('description', '', false);
    }
  };

  const addUrlsValues: AddUrlValuesI = {
    url: '',
    title: '',
    image: '',
    description: '',
    isLoaded: false,
    folder: -1,
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('modal.addUrl.title')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={isFormik}
        initialValues={addUrlsValues}
        onSubmit={(inputData, { setSubmitting }): void => {
          // TODO: 임시 바인딩처리, 추후 url 처리 스토리 진행 시 수정 필요
          const request: BookmarkAddContentsCondtion = {
            bookmarkFolderId: inputData.folder,
            contentsId: '1',
            title: inputData.title.trim(),
            detailContents: inputData.url.trim(),
            bookmarkContentsTypeCode: BookmarkContentsTypeCode.CT_URL,
            sharedYn: 'N',
            thumbnail: inputData.image ? inputData.image : undefined,
            crawlData: {} as CrawlData,
          };

          (async (): Promise<void> => {
            const response = await state.bookmarkService.addBookmarkContents(request);
            if (response) {
              dispatch({
                type: BookmarkActionType.ADD_CONTENTS,
                isAddContents: true,
              });
              dispatch({
                type: ActionType.SHOW_TOAST,
                toast: {
                  showToast: true,
                  toastMessage: t('bookmark.message.addBookmarkContents.reponse.SUCCESS'),
                  toastLink: {
                    linkMessage: t('bookmark.ui.linkMessage'),
                    onLinkClick: () => {
                      props.onSuccess(request.bookmarkFolderId);
                    },
                  },
                },
              });
              props.handleCloseAddUrl();
            } else {
              dispatch({
                type: ActionType.SHOW_TOAST,
                toast: {
                  showToast: true,
                  toastMessage: t('bookmark.message.UE'),
                },
              });
              props.handleCloseAddUrl();
            }
          })();
        }}
        validationSchema={schema}
      >
        {({ values, handleSubmit, handleChange, handleBlur, isSubmitting, errors, touched, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Modal.Body>
                <Form.Group id="url-group">
                  <Form.Label>{t('modal.addUrl.url')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="url"
                    name="url"
                    defaultValue={values.url}
                    placeholder={t('modal.addUrl.placeholder')}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleUrlChange(values, setFieldValue, e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.url}
                  />
                  <Button
                    id="getUrlInfo"
                    variant="secondary"
                    disabled={!values.url}
                    onClick={e => {
                      handleGetUrlInfo(values, setFieldValue);
                    }}
                  >
                    {t('modal.addUrl.retrieve')}
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.Label></Form.Label>
                  <Form.Control.Feedback id="urlFeedback" data-testid="urlFeedback" type="invalid">
                    {!!errors.url && touched.url ? errors.url : ''}
                  </Form.Control.Feedback>
                </Form.Group>

                {values.isLoaded && (
                  <Container>
                    {values.image && (
                      <Form.Group>
                        <Form.Label>{t('modal.addUrl.image')}</Form.Label>
                        <Image id="addUrlImage" src={values.image} />
                      </Form.Group>
                    )}
                    <Form.Group>
                      <Form.Label>{t('modal.addUrl.urlTitle')}</Form.Label>
                      <Form.Control
                        type="text"
                        id="title"
                        name="title"
                        defaultValue={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ref={titleRef}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('modal.addUrl.folder')}</Form.Label>
                      <Field as="select" id="folder" name="folder" onChange={handleChange} onBlur={handleBlur}>
                        {createSelectItems(setFieldValue)}
                      </Field>
                    </Form.Group>
                  </Container>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button id="saveUrl" variant="primary" type="submit" disabled={isSubmitting || !values.isLoaded}>
                  <IconCheck />
                  {t('modal.addUrl.save')}
                </Button>
              </Modal.Footer>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddUrl;
