import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import { InterestResponse } from '../../../../service/interests/model/Interest';
import { InputAutoComplete } from './InputAutoComplete';
import { CategoryCode } from '../../../../service/interests/model/enum/Interest.enum';

import './Interests.scss';
import KeywordTree from './KeywordTree';

import { ReactComponent as IconCheck } from '../../../../icons/ic-check.svg';

import { Toast } from '../../../../reducer/states';

export interface InterestsProps {
  isFirst: boolean;
  onHide: () => void;
}

export const Interests: React.FC<InterestsProps> = (props: InterestsProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [searchResult, setSearchResult] = useState<InterestResponse[]>([]);
  const [personalInterests, setPersonalInterests] = useState<InterestResponse[]>([]);
  const [interestsCount, setInterestsCount] = useState<number>(0);
  const [showKeywordTree, setShowKeywordTree] = useState(false);

  const handleShowKeywordTree = (): void => {
    setShowKeywordTree(true);
  };
  const handleCloseKeywordTree = (): void => {
    setShowKeywordTree(false);
  };

  const getInterestSearchResult = async () => {
    const response = await state.interestService.getInterestSearchResult();

    if (response && response.successOrNot === 'Y') {
      setSearchResult(response.data);
    }
  };

  useEffect(() => {
    getPersonalInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestsCount]);

  useEffect(() => {
    getInterestSearchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPersonalInterests = async () => {
    const response = await state.interestService.retrievePersonalInterests();

    if (response && response.successOrNot === 'Y') {
      setPersonalInterests(response.data);
      setInterestsCount(response.data.length);
    }
  };

  const handleMyItemClick = async category => {
    const toast: Toast = {
      toastMessage: '',
      showToast: true,
    };
    const response = await state.interestService.deletePersonalInterest(category);
    if (response && response.successOrNot === 'Y') {
      setInterestsCount(interestsCount - 1);
      toast.toastMessage = t('interests.message.deleteSuccess');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
    } else {
      toast.toastMessage = t('interests.message.saveFail');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
    }
  };

  const handleSelectSearchItem = async category => {
    const interests = {
      category: category,
    };
    const toast: Toast = {
      toastMessage: '',
      showToast: true,
    };
    const response = await state.interestService.savePersonalInterest(interests);

    if (response && response.successOrNot === 'Y') {
      toast.toastMessage = t('interests.message.saveSuccess');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
      setInterestsCount(interestsCount + 1);
    } else {
      toast.toastMessage = t('interests.message.saveFail');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
    }
  };

  const handleOnClickStart = async () => {
    const response = await state.interestService.updateInterestInitialSetup();
    if (response && response.successOrNot === 'Y') {
      props.onHide();
    }
  };

  const renderPersonalInterest = (topCategory: string) => {
    const interests = personalInterests.filter((elem, inx) => {
      return elem.topCategory === topCategory;
    });

    if (interests.length > 0) {
      return interests.map(elem => {
        return (
          <span key={elem.category}>
            {elem.categoryName}
            <span onClick={() => handleMyItemClick(elem.category)}>
              <i></i>
            </span>
          </span>
        );
      });
    } else {
      return <>{t('interests.blank')}</>;
    }
  };

  return (
    <>
      <Modal id="Interest" show={true} centered dialogClassName="interests">
        <Modal.Header>
          {props.isFirst ? (
            ''
          ) : (
            <Button
              className="btn-icon"
              onClick={() => {
                props.onHide();
              }}
            >
              <i className="ic-close"></i>
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <h5>{t('interests.title')}</h5>
          <div className="input-wrapper">
            {personalInterests.length > 0 && (
              <InputAutoComplete
                interestData={searchResult}
                onSelectItem={handleSelectSearchItem}
                personalData={personalInterests}
              ></InputAutoComplete>
            )}
            <Button className="seeAll" onClick={() => handleShowKeywordTree()}>
              {t('interests.inputButton')}
            </Button>
          </div>
          <p className="info">{t('interests.info')}</p>
          <div className="keyword-list">
            <dl>
              <dt>{t('interests.industry')}</dt>
              <dd>{renderPersonalInterest(CategoryCode.INDUSTRY)}</dd>
            </dl>
            <dl>
              <dt>{t('interests.country')}</dt>
              <dd>{renderPersonalInterest(CategoryCode.COUNTRY)}</dd>
            </dl>
            <dl>
              <dt>{t('interests.technology')}</dt>
              <dd>{renderPersonalInterest(CategoryCode.TECHNOLOGY)}</dd>
            </dl>
            <dl>
              <dt>{t('interests.product')}</dt>
              <dd>{renderPersonalInterest(CategoryCode.PRODUCT)}</dd>
            </dl>
            <dl>
              <dt>{t('interests.competitor')}</dt>
              <dd>{renderPersonalInterest(CategoryCode.COMPETITOR)}</dd>
            </dl>
          </div>
          <label className="customCheckbox">
            <input type="checkbox" />
            <span>{t('interests.check')}</span>
          </label>
        </Modal.Body>
        <Modal.Footer>
          {props.isFirst && (
            <Button variant="primary" onClick={handleOnClickStart}>
              <IconCheck />
              {t('interests.start')}
            </Button>
          )}
        </Modal.Footer>
        <Modal show={showKeywordTree} onHide={handleCloseKeywordTree} centered dialogClassName="keywordTree">
          <KeywordTree></KeywordTree>
        </Modal>
      </Modal>
    </>
  );
};
export default Interests;
