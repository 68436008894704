export enum CategoryCode {
  INDUSTRY = '1001',
  COUNTRY = '1002',
  TECHNOLOGY = '1003',
  PRODUCT = '1004',
  COMPETITOR = '1005',
}

export enum CategoryDelimeter {
  CODE_NAME = ':',
  ITEM = '>',
}
