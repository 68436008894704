import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AuthContext } from '../../App';
import { Affix } from 'rsuite';
import Bookmark from './Bookmark';
import { Unlike } from './modal/Unlike';
import SearchBottom from './SearchBottom';
import SearchContents from './SearchContents';
import SearchLeft from './SearchLeft';
import SearchOption from './SearchOption';
import './SearchPage.scss';
import SearchRight from './SearchRight';

export interface SearchPageProps {
  keyword: string;
}

const SearchPage: React.FC<SearchPageProps> = (props: SearchPageProps) => {
  const { state } = useContext(AuthContext);
  const [isChecked, setChecked] = useState<boolean>(false);
  const [isCheckHover, setCheckHover] = useState<boolean>(false);

  const [leftMenu, setLeftMenu] = useState<number>(0);
  const [showUnlike, setShowUnlike] = useState(false);
  const onShowUnlikeModal = () => setShowUnlike(true);
  const onHideUnlikeModal = () => setShowUnlike(false);

  return (
    <div className={'SearchPage flex flex-one vbox'}>
      <SearchOption />
      <div className={'contents-wrap flex hbox'}>
        <Affix top={158}>
          <SearchLeft leftMenu={leftMenu} setLeftMenu={setLeftMenu} keyword={props.keyword} />
        </Affix>
        <SearchContents
          isChecked={isChecked}
          setChecked={setChecked}
          isCheckHover={isCheckHover}
          setCheckHover={setCheckHover}
          leftMenu={leftMenu}
          setLeftMenu={setLeftMenu}
          keyword={props.keyword}
        />
        {state.searchRight.isShowRight && <SearchRight />}
      </div>
      <SearchBottom showUnlikeModal={onShowUnlikeModal} />
      {isChecked && <Bookmark />}
      <Modal show={showUnlike} onHide={onHideUnlikeModal} backdrop="static" centered dialogClassName="Unlike">
        <Unlike onHideUnlikeModal={onHideUnlikeModal} />
      </Modal>
    </div>
  );
};

export default SearchPage;
