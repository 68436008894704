import i18next from 'i18next';
import moment from 'moment';

import { DateFormat } from '../../../service/model/enum/Common.enum';

export const isJSON = value => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const t = (code: string, params?: object): string => {
  if (params) {
    return i18next.t(code, params);
  } else {
    return i18next.t(code);
  }
};

const calcDiff = (format: DateFormat, targetDate: string | undefined, unitOfTime: any) => {
  return moment(moment().format(format)).diff(moment(targetDate).format(format), unitOfTime);
};

export const getCustomDate = (format: DateFormat, targetDate: string | undefined) => {
  //오늘, 어제, 2일 전, 3일 전, 4일 전, 5일 전, 6일 전, 1주 전, 2주 전, 3주 전, 4주 전
  //1개월 전, 2개월 전, 3개월 전,..., 11개월 전, 이후는 실제 날짜
  let res = '';
  const cntYear = calcDiff(format, targetDate, 'years');
  if (cntYear < 1) {
    //1년 미만
    const cntmonth = calcDiff(format, targetDate, 'months');
    if (cntmonth < 1) {
      //1달 미만
      const cntweek = calcDiff(format, targetDate, 'weeks');
      if (cntweek < 1) {
        //1주 미만
        const cntday = calcDiff(format, targetDate, 'days');
        if (cntday < 1) {
          res = t('common.message.date.today');
        } else if (cntday < 2) {
          res = t('common.message.date.yesterday');
        } else {
          res = t('common.message.date.dayAgo', [cntday]);
        }
      } else {
        //1주 이상
        res = t('common.message.date.weekAgo', [cntweek]);
      }
    } else {
      //1달 이상
      res = t('common.message.date.monthAgo', [cntmonth]);
    }
  } else {
    //1년 이상
    res = moment(targetDate).format('YYYY.MM.DD');
  }
  return res;
};
