export interface BookmarkAddFolderCondtion {
  bookmarkFolderName: string;
}

export interface BookmarkAddContentsCondtion {
  bookmarkFolderId: number;
  contentsId: string;
  publishedDate?: string;
  title?: string;
  detailContents?: string;
  bookmarkContentsTypeCode: BookmarkContentsTypeCode;
  sharedYn: string;
  tags?: string;
  source?: string;
  thumbnail?: string;
  crawlData: CrawlData;
}

export interface CrawlData {
  crawlDataId: string;
  crawlDate: string;
  url?: string;
  publishDate?: string;
  title?: string;
  mediaType?: string;
  content?: string;
  mediaDetailId?: string;
  imageUrl?: string;
  fileUrl?: string;
  author?: string;
  addDate: string;
  htmlContent: string;
  categorizationKeyword?: object;
  morphemeWords: string;
}
export interface FolderResponse {
  bookmarkFolderId: number;
  memberId: number;
  bookmarkFolderName: string;
}

export interface UrlInformation {
  url: string;
  title: string;
  type: string;
  image: string;
  description: string;
  siteName: string;
}

export enum BookmarkContentsTypeCode {
  CT_ARTICLE = 'CT_ARTICLE',
  CT_URL = 'CT_URL',
}

export enum BookmarkStatusCode {
  SUCCESS = 'SUCCESS',
  DUPLICATE_FOLDER_NAME = 'DUPLICATE.FOLDER.NAME',
}

export enum BookmarkCode {
  ALL_CONTENTS_FOLDER_ID = 0,
}
