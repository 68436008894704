import React, { useState, useContext, useEffect } from 'react';
import { Button, ButtonGroup, Modal, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';
import { ReactComponent as ImgNodata } from '../../../icons/ic-empty.svg';
import YoutubeContents from '../../youtube/YoutubeContents';
import { YoutubeApiCondition, YoutubeInfo, YoutubeContentsList } from '../../../service/youtube/model/Youtube';
import './Youtube.scss';
export interface YoutubeProps {
  handleCloseYoutube: Function;
  menuClick: Function;
}

export const Youtube: React.FC<YoutubeProps> = (props: YoutubeProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [contentsList, setContentsList] = useState<YoutubeInfo[]>([]);
  const [contentsUrlList, setContentsUrlList] = useState<string[]>([]);
  const [apiCallCount, setApiCallCount] = useState<number>(0);
  const [inputText, setInputText] = useState('');

  const onChangeInput = e => {
    setInputText(e.target.value);
  };

  const inputKeyDown = e => {
    if (e.key === 'Enter') {
      getYoutubeList(inputText);
    }
  };

  const submitHandler = e => {
    e.preventDefault();
  };

  const getYoutubeList = async (url: string) => {
    const condition: YoutubeApiCondition = {
      url: '',
    };
    const urlList: string[] = url.split(';');
    for (const element of urlList) {
      if (contentsList.length == YoutubeContentsList.LIST_MAX_LENGTH) {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('youtube.message.apiLimits'),
          },
        });
        continue;
      }
      /**
       * 이미 조회된 영상URL의 경우, 재조회하지 않는다.
       */
      if (contentsUrlList.includes(element)) {
        continue;
      } else {
        contentsUrlList.push(element);
      }
      condition.url = element;
      await state.youtubeService.retrieveYoutube(condition).then(response => {
        if (response) {
          if (response.successOrNot === 'Y') {
            contentsList.push(response.data);
          } else if (response.successOrNot === 'N') {
            if (response.statusCode === 'NO.CAPTION.INFO') {
              dispatch({
                type: ActionType.SHOW_TOAST,
                toast: {
                  showToast: true,
                  toastMessage: t('youtube.message.noCaptionInfo'),
                },
              });
            } else if (response.statusCode === 'NO.VIDEO.INFO') {
              dispatch({
                type: ActionType.SHOW_TOAST,
                toast: {
                  showToast: true,
                  toastMessage: t('youtube.message.noVideoInfo'),
                },
              });
            } else if (response.statusCode === 'INVALID.URL') {
              dispatch({
                type: ActionType.SHOW_TOAST,
                toast: {
                  showToast: true,
                  toastMessage: t('youtube.message.invalidUrl'),
                },
              });
            }
          }
        }
      });
    }
    setApiCallCount(apiCallCount + urlList.length);
  };

  return (
    <div id="youtube">
      <div id="moduleContainer">
        <div className="module-title">
          <a href="#"></a>
          <h3>{t('youtube.label.analysis')}</h3>
        </div>
        <h2>{t('youtube.label.loading')}</h2>
        <form onSubmit={submitHandler}>
          <input
            type="text"
            placeholder={t('youtube.label.insertUrl')}
            value={inputText}
            onChange={onChangeInput}
            onKeyDown={inputKeyDown}
            id="youtubeUrl"
          />
        </form>
        <p>{t('youtube.label.analysisDesc')}</p>

        {apiCallCount ? (
          contentsList.length > 0 ? (
            <section>
              <YoutubeContents contentsList={contentsList} />
            </section>
          ) : (
            <section>
              <div className="noDataContent">
                <ImgNodata />
                {t('youtube.message.noData')}
              </div>
            </section>
          )
        ) : (
          ''
        )}
      </div>
      <footer>
        {contentsList.length > 0 ? (
          <div className="document-type-list document-button-group">
            <Button variant="outline-secondary" size="lg">
              {t('youtube.label.checkAll')}
            </Button>{' '}
            <Button variant="outline-secondary" size="lg">
              {t('youtube.label.uncheck')}
            </Button>{' '}
            <Button variant="secondary" size="lg">
              {t('youtube.label.delete')}
            </Button>
            <Button variant="secondary" size="lg">
              {t('youtube.label.analysisRequest')}
            </Button>
          </div>
        ) : (
          ''
        )}
      </footer>
    </div>
  );
};

export default Youtube;
