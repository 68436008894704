import React, { ReactElement, useContext } from 'react';
import { Toast, Button } from 'react-bootstrap';
import './CommonToast.scss';
import { ActionType } from '../../reducer/actions';
import { AuthContext } from '../../App';
import { Toast as ToastState } from '../../reducer/states';

export interface ToastProps {
  toast: ToastState;
  onClose?: Function;
}

export const CommonToast: React.FC<ToastProps> = (props: ToastProps): ReactElement => {
  const { dispatch } = useContext(AuthContext);
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      dispatch({
        type: ActionType.SHOW_TOAST,
        toast: { showToast: false, toastMessage: '', toastLink: { linkUrl: '', linkMessage: '' } },
      });
    }
  };

  return (
    <Toast
      id="CommonToast"
      data-testid="commonToast"
      className="commonToast"
      onClose={onClose}
      show={props.toast.showToast}
      delay={3000}
      autohide
    >
      <Toast.Body>
        <div>
          <span id="toastMessage" className="toastMessage">
            {props.toast.toastMessage}
            {props.toast.toastLink && (
              <Button id="toastLink" onClick={props.toast.toastLink.onLinkClick}>
                {props.toast.toastLink.linkMessage}
              </Button>
            )}
          </span>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default CommonToast;
