import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Unlike.scss';

export interface UnlikeProps {
  onHideUnlikeModal: Function;
}

export const Unlike: React.FC<UnlikeProps> = (props: UnlikeProps) => {
  const [unlikeContent, setUnlikeContent] = useState<string>('');
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const onUnlikeContentChange = e => {
    if (e.target.value.length > 0) setIsEmpty(false);
    else setIsEmpty(true);
    setUnlikeContent(e.target.value);
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{'검색결과에 대한 개선의견을 입력해주세요'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'flex unlike-content'}>
          <textarea
            className={'unlike-textarea'}
            placeholder={
              '검색에 추가되었으면 하는 웹사이트나 출처를 함께 입력해주시면\n검색 결과 개선활동에 더 큰 도움이 됩니다.'
            }
            onChange={onUnlikeContentChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={'send-btn'} variant={'dark'} disabled={isEmpty}>
          {'보내기'}
        </Button>
      </Modal.Footer>
    </>
  );
};
export default Unlike;
