import { Service } from '../model/Service';
import { Method } from '../model/Method';
import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { DocumentFolderResponse, DocumentRequest, EditorTrimAndTagRemoveConst } from './model/Document';

export default class DocumentService extends BaseService {
  /** TODO: BE 서비스 개발 이후 수정필요 */
  /* eslint-disable */
  async getFolders(): Promise<DocumentFolderResponse[]> {
    let response: CommonResponse;
    response = {
      successOrNot: 'Y',
      statusCode: 'SUCCESS',
      desc: 'SUCCESS',
      data: [
        { folderId: 'F000001', folderName: '환경분석' },
        { folderId: 'F000002', folderName: '경쟁사분석' },
      ],
    };
    return (response && response.successOrNot === 'Y' ? response.data : []) as DocumentFolderResponse[];
  }
  /* eslint-enable */

  async getDocuments(folderId: string): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/documents';
    const serviceName = Service.MIBE;
    const params = { folderId: folderId };
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getDocument(documentId: number, caller: string): Promise<CommonResponse> {
    const method = Method.GET;
    const url = `/v1/documents/${documentId}`;
    const serviceName = Service.MIBE;
    const params = { caller: caller };
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getDocumentForViewer(documentId: number): Promise<CommonResponse> {
    const method = Method.GET;
    const url = `/v1/documents/viewer/${documentId}`;
    const serviceName = Service.MIBE;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async saveDocument(document: DocumentRequest): Promise<CommonResponse> {
    let method = Method.POST;
    if (document.documentId) {
      method = Method.PATCH;
    }
    const url = '/v1/documents';
    const serviceName = Service.MIBE;
    const params = null;
    const body = document;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  checkDocumentTitleAndContentsToSave(document: DocumentRequest | undefined, contents: string | undefined): boolean {
    return (
      !document?.title?.toString().replace(EditorTrimAndTagRemoveConst.REGEXP_TRIM, '') ||
      !contents
        ?.replace(EditorTrimAndTagRemoveConst.REGEXP_CONTENTS_TAG_REMOVE, '')
        .replace(EditorTrimAndTagRemoveConst.REGEXP_TRIM, '')
    );
  }
}
