import { MenuListI } from '../model/Menu';
import { Service } from '../model/Service';
import BaseService from '../BaseService';
import { TableauTicket } from './model/TableauTicket';
import { TableauInfo } from './model/enum/Tableau.enum';

export default class TableauService extends BaseService {
  async getTableauTicket(): Promise<TableauTicket> {
    const method = 'GET';
    const url = '/v1/tableau/ticket';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : {};
  }

  getFullUrl(ticket: string, url: string): string | undefined {
    let fullUrl: string | undefined = undefined;
    if (ticket) {
      fullUrl =
        this.getAPILocation(TableauInfo.HOST_KEY) +
        TableauInfo.TRUSTED_URL +
        '/' +
        ticket +
        TableauInfo.VIEW_URL +
        '/' +
        url +
        TableauInfo.IFRAME_PARAMS;
    }

    return fullUrl;
  }
}
