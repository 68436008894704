import React, { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import {
  ActionType,
  GoogleSearchActionType,
  MiMaterialActionType,
  NewsActionType,
  SearchActionType,
} from '../../reducer/actions';
import { StatusCode } from '../../service/model/enum/Status.enum';
import { DetailOpenType } from '../../service/model/Search';
import GoogleSearchService from '../../service/search/GoogleSearchService';
import {
  RecommendedSearch,
  SearchCondition,
  SearchMenuType,
  SearchType,
} from '../../service/search/model/enum/Search.enum';
import { GoogleSearchCondition, GoogleSearchResult } from '../../service/search/model/GoogleSearch';
import {
  MiMaterial,
  MiMaterialSearchCondition,
  NewsData,
  NewsListSearchCondtion,
} from '../../service/search/model/Search';
import SearchItem from './component/SearchItem';
import GoogleSearchExceed from './GoogleSearchExceed';
import SearchEmpty from './SearchEmpty';
import './UnifiedSearchPage.scss';

export interface UnifiedSearchPageProps {
  keyword: string;
  setLeftMenu: React.Dispatch<number>;
  isChecked: boolean;
  isCheckHover: boolean;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const UnifiedSearchPage: React.FC<UnifiedSearchPageProps> = (props: UnifiedSearchPageProps): ReactElement => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);

  const [isExceed, setIsExceed] = useState<boolean>(false);

  const googleService = new GoogleSearchService();
  const newsDataList: NewsData[] = state.news.items;
  const miMaterials: MiMaterial[] = state.miMaterial.items;
  const googleDatas: GoogleSearchResult[] = state.google.searchResults;
  const moveCategory = menu => () => {
    props.setLeftMenu(menu);
  };

  useEffect(() => {
    if (state.search.isEnter) {
      searchAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyword, state.search.isEnter]);

  useEffect(() => {
    if (state.isInitialPage) {
      searchAll();
      dispatch({
        type: SearchActionType.SET_IS_INITIAL_PAGE,
        isInitialPage: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialPage]);

  useEffect(() => {
    if (state.searchRight.recommendedSearch) {
      searchAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchRight.recommendedSearch]);

  const searchAll = () => {
    dispatch({
      type: ActionType.SEARCH,
      search: {
        isEnter: false,
      },
    });

    searchNewsList();
    searchMiMaterials();
    searchGoogle();
  };

  const searchNewsList = async () => {
    const condition: NewsListSearchCondtion = {
      size: 10,
      page: 1,
      keyword: props.keyword,
      recommendedSearch: state.cookieService.getRecommendedSearch() === RecommendedSearch.TOGGLE_ON,
    };

    const response = await state.searchService.searchNewsReportList(condition);
    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: NewsActionType.SET_NEWS_VIEW_LIST,
        newsDataList: response.data,
      });

      if (response.data) {
        dispatch({
          type: SearchActionType.SHOW_SEARCH_RIGHT,
          searchRight: {
            isShowRight: true,
            keywordCategories: response.data.keywordCategories,
            recommendedSearch: state.cookieService.getRecommendedSearch(),
          },
        });
      } else {
        dispatch({
          type: SearchActionType.HIDE_SEARCH_RIGHT,
          searchRight: { isShowRight: false },
        });
      }
    } else {
      dispatch({
        type: NewsActionType.SET_NEWS_VIEW_LIST,
        /* eslint-disable */
        newsDataList: {
          timed_out: false,
          total: {
            value: 0,
            relation: '',
          },
          max_score: 0,
          items: [],
        },
        /* eslint-enable */
      });
    }
  };

  const searchMiMaterials = async () => {
    const condition: MiMaterialSearchCondition = {
      size: 10,
      page: 1,
      keyword: props.keyword,
    };

    const response = await state.searchService.searchMiMaterials(condition);
    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: MiMaterialActionType.SET_MI_MATERIAL_RESULT,
        miMaterial: response.data,
      });
    } else {
      dispatch({
        type: MiMaterialActionType.SET_MI_MATERIAL_RESULT,
        miMaterial: { total: { value: 0 }, items: [] },
      });
    }
  };

  const searchGoogle = async () => {
    const condition: GoogleSearchCondition = {
      keyword: props.keyword,
      startIndex: 1,
    };

    const response = await googleService.getResults(condition);
    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: GoogleSearchActionType.SET_GOOGLE_RESULT_LIST,
        searchResults: response.data.searchResults,
        pagingInfo: response.data.pagingInfo,
      });
    } else {
      if (response.statusCode === StatusCode.QUOTA_EXCEEDED) {
        setIsExceed(true);
      }
      dispatch({
        type: GoogleSearchActionType.SET_GOOGLE_RESULT_LIST,
        searchResults: [],
        pagingInfo: {
          totalCount: 0,
          countPerPage: 0,
          startIndex: 0,
          endIndex: 0,
        },
      });
    }
  };

  return (
    <Fragment>
      {newsDataList.length === 0 && googleDatas.length === 0 && miMaterials.length === 0 ? (
        <div className={'UnifiedSearchPage flex vbox'}>
          <SearchEmpty />
          {isExceed && (
            <div id="unifiedGoogleSearchResult">
              <div className={'title flex hbox between googleSearch'}>{t('search.label.category.search')}</div>
              <div id="googleExceed" className={'search-left-text'}>
                <GoogleSearchExceed keyword={props.keyword} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={'UnifiedSearchPage flex vbox'}>
          {miMaterials.length > 0 && (
            <div>
              <div className={'title flex hbox between'}>
                <div className={'flex'}>{t('search.label.category.miMaterial')}</div>
              </div>
              <div className={'flex vbox'}>
                {miMaterials.map((item, index) => {
                  if (index >= SearchCondition.UNIFIED_SEARCH_COUNT) return;
                  return (
                    <SearchItem
                      isCheckHover={props.isCheckHover}
                      isChecked={props.isChecked}
                      setCheckHover={props.setCheckHover}
                      setChecked={props.setChecked}
                      key={index}
                      searchData={state.searchService.getSearchDataByMiMaterial(item)}
                      detailOpenType={DetailOpenType.DETAIL_VIEW}
                    />
                  );
                })}
                {miMaterials.length > SearchCondition.UNIFIED_SEARCH_COUNT && (
                  <div
                    id="moreMiMaterialBtn"
                    className={'flex more add'}
                    onClick={moveCategory(SearchMenuType.MI_MATERIAL)}
                  >
                    {t('search.label.category.more', { category: t('search.label.category.miMaterial') })}
                  </div>
                )}
              </div>
            </div>
          )}

          {newsDataList.length > 0 && (
            <div id="unifiedNewsSearchResult">
              <div className={'title flex hbox between'}>
                <div className={'flex'}>{t('search.label.category.newsAndReport')}</div>
              </div>
              <div className={'flex vbox'}>
                {newsDataList.map((item, index) => {
                  if (index >= SearchCondition.UNIFIED_SEARCH_COUNT) return;
                  return (
                    <SearchItem
                      isCheckHover={props.isCheckHover}
                      isChecked={props.isChecked}
                      setCheckHover={props.setCheckHover}
                      setChecked={props.setChecked}
                      key={index}
                      searchData={state.searchService.getSearchDataByNewsData(item)}
                      detailOpenType={DetailOpenType.DETAIL_VIEW}
                    />
                  );
                })}
                {newsDataList.length > SearchCondition.UNIFIED_SEARCH_COUNT && (
                  <div
                    id="moreNewsReportBtn"
                    className={'flex more add'}
                    onClick={moveCategory(SearchMenuType.NEWS_REPORT)}
                  >
                    {t('search.label.category.more', { category: t('search.label.category.newsAndReport') })}
                  </div>
                )}
              </div>
            </div>
          )}

          {googleDatas.length > 0 ? (
            <div id="unifiedGoogleSearchResult">
              <div className={'title flex hbox between googleSearch'}>{t('search.label.category.search')}</div>
              <div className={'flex vbox'}>
                {googleDatas.map((item, index) => {
                  if (index > SearchCondition.UNIFIED_SEARCH_COUNT) return;
                  return (
                    <SearchItem
                      isCheckHover={props.isCheckHover}
                      isChecked={props.isChecked}
                      setCheckHover={props.setCheckHover}
                      setChecked={props.setChecked}
                      key={index}
                      searchData={{
                        title: item.title,
                        content: item.snippet,
                        source: item.displayLink,
                        date: item.snippet,
                        crawlData: {
                          crawlDataId: item.link,
                          crawlDate: '',
                          addDate: '',
                          htmlContent: '',
                          morphemeWords: '',
                        },
                        dataType: SearchType.GOOGLE,
                      }}
                      detailOpenType={DetailOpenType.NEW_TAB}
                      detailOpenUrl={item.link}
                    />
                  );
                })}
                {googleDatas.length > SearchCondition.UNIFIED_SEARCH_COUNT && (
                  <div id="moreGoogleBtn" className={'flex more add'} onClick={moveCategory(SearchMenuType.GOOGLE)}>
                    {t('search.label.category.more', { category: t('search.label.category.googleSearch') })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            isExceed && (
              <div id="unifiedGoogleSearchResult">
                <div className={'title flex hbox between googleSearch'}>{t('search.label.category.search')}</div>
                <div id="googleExceed" className={'search-left-text'}>
                  <GoogleSearchExceed keyword={props.keyword} />
                </div>
              </div>
            )
          )}
        </div>
      )}
    </Fragment>
  );
};
export default UnifiedSearchPage;
