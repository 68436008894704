function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "D086DEB7-02C8-4BA1-AD92-CA1F88494000");

var _ref3 = React.createElement("defs", null, React.createElement("path", {
  d: "M2,4 L2,13 L10,13 L10,4 L11,4 L11,13 C11,13.5522847 10.5522847,14 10,14 L2,14 C1.44771525,14 1,13.5522847 1,13 L1,4 L2,4 Z M5,5 L5,11 L4,11 L4,5 L5,5 Z M8,5 L8,11 L7,11 L7,5 L8,5 Z M12,2 L12,3 L0,3 L0,2 L12,2 Z M8,0 L8,1 L4,1 L4,0 L8,0 Z",
  id: "path-1"
}));

var _ref4 = React.createElement("g", {
  id: "A-\\uBA54\\uC778-\\uBC0F-\\uBD81\\uB9C8\\uD06C,-\\uC5D0\\uB514\\uD130",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Layer----Bookmark",
  transform: "translate(-621.000000, -1015.000000)"
}, React.createElement("g", {
  id: "Component/Button/Contained-+-Icon/2.-Gray/1.-Normal-Copy-2",
  transform: "translate(609.000000, 1004.000000)"
}, React.createElement("g", {
  id: "Group",
  transform: "translate(12.000000, 11.000000)"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-1"
})), React.createElement("use", {
  id: "Combined-Shape",
  fill: "#6B7682",
  xlinkHref: "#path-1"
})))));

var SvgIcTrash1214 = function SvgIcTrash1214(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "12px",
    height: "14px",
    viewBox: "0 0 12 14",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3, _ref4);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIcTrash1214, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/ic-trash-1214.ee237213.svg";
export { ForwardRef as ReactComponent };