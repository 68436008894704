import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginSection.scss';

import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from 'react-bootstrap';
import lgLogo from '../../../../icons/lg-logo.svg';
import LoginForm from './LoginForm';

const LoginSection: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div id="LoginSection">
      <Row className="loginContainer-top-row">
        <Col>
          <div id="LoginSectionTitle" className="LoginSection-title">
            <Image src={lgLogo} className="lgLogo" />

            <h2>
              <span id="LoginSectionTitleTarget" className="LoginSection-title-target">
                {t('member.label.LoginSection.main.title')}
              </span>
              {t('member.label.LoginSection.main.titleDescription')}
            </h2>
          </div>
          <div id="LoginSectionForm" className="LoginSection-form">
            <LoginForm />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LoginSection;
