import React, { ReactElement, useContext, useState } from 'react';
import { Drawer } from 'rsuite';
import { AuthContext } from '../../App';
import Notice from './NoticeModal';

interface Props {
  showNoticesDrawer: Function;
}

const NoticeDrawer: React.FC<Props> = (props: Props): ReactElement => {
  const { state } = useContext(AuthContext);

  return (
    <Drawer
      backdrop={false}
      size="md"
      show={state.showNoticeDrawer || false}
      onHide={() => props.showNoticesDrawer(false)}
      className="noticeDrawer"
    >
      <Drawer.Body>
        <Notice showNoticesDrawer={props.showNoticesDrawer} />
      </Drawer.Body>
    </Drawer>
  );
};

export default NoticeDrawer;
