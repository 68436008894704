export enum TableauList {
  TABLEAU001 = 'B_01_1_SPEC/SPEC',
  TABLEAU002 = 'S_03_ValueMap_HE/ValueMap',
  TABLEAU003 = 'S_03_ValueMap_HA/ValueMap',
  TABLEAU004 = 'S_03_ValueMap_LGD/ValueMap',
  TABLEAU005 = 'C_04_/sheet0',
  TABLEAU006 = 'Z_10_Buzz/Buzz',
  TABLEAU007 = 'C_02_/_01',
  TABLEAU008 = 'B_01_5_PRM/PRM',
}
