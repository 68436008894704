import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxGroup, List, Avatar } from 'rsuite';
import { AuthContext } from '../../../App';
import { ReactComponent as ImgNodata } from '../../../icons/ic-empty.svg';
import Avatars from '../../../layouts/Avatars';
import { DocumentActionType, ShareActionType } from '../../../reducer/actions';
import { DocumentResponse } from '../../../service/document/model/Document';
import { DocumentFolderType, DocumentViewType } from '../../../service/document/model/enum/Document.enum';
import { DateFormat } from '../../../service/model/enum/Common.enum';
import { ShareType } from '../../../service/share/model/enum/Share.enum';
import './DocumentListSection.scss';

interface Props {
  documents?: DocumentResponse[];
  setFooter: Function;
  handleShowMove: Function;
  handleShowCopy: Function;
  handleShowDelete: Function;
}
interface CheckStatus {
  checkAll: boolean;
  value: number[];
}

const DocumentListSection: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const initialCheckState = {
    checkAll: false,
    value: [],
  };
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [checkState, setCheckState] = useState<CheckStatus>(initialCheckState);

  useEffect(() => {
    const documentIds: number[] = [];
    if (props.documents) {
      props.documents.forEach(item => {
        documentIds.push(item.documentId);
      });
      setDocumentIds(documentIds);
    }
    setCheckState(initialCheckState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documents]);

  const handleCheckAll = (value, checked) => {
    const nextValue = checked ? documentIds : [];
    setCheckState({
      value: nextValue,
      checkAll: checked,
    });
    if (!checked) {
      props.setFooter(false);
    } else {
      props.setFooter(true);
    }
  };
  const handleCheck = value => {
    setCheckState({
      value,
      checkAll: value.length === documentIds.length,
    });
    if (value.length == 0) {
      props.setFooter(false);
    } else {
      props.setFooter(true);
    }
  };

  const showDocumentEditor = (documentId: number) => {
    state.documentService.getDocument(documentId, DocumentViewType.EDITOR).then(response => {
      if (response && response.successOrNot === 'Y') {
        const document = response.data;
        dispatch({
          type: DocumentActionType.SET_DOCUMENT_VIEW_EDITOR,
          selectedFolderId: state.document.selectedFolderId,
          editorContent: document.content,
          documentEditor: document,
        });
      }
    });
  };

  const showDocumentViewer = (documentId: number) => {
    state.documentService.getDocument(documentId, DocumentViewType.VIEWER).then(response => {
      if (response && response.successOrNot === 'Y') {
        const document = response.data;
        dispatch({
          type: DocumentActionType.SET_DOCUMENT_VIEW_CONTENT,
          selectedFolderId: state.document.selectedFolderId,
          editorContent: document.content,
          documentEditor: document,
        });
      }
    });
  };

  const setDateFormat = (date: string): string => {
    return moment(date).format(DateFormat.DATE_DISP_FORMAT);
  };

  const handleShowShareModal = (
    documentId: string | undefined,
    title: string | undefined,
    content: string | undefined
  ) => {
    dispatch({
      type: ShareActionType.SHOW_SHARE_MODAL,
      show: true,
      sharedType: ShareType.MI_NOTE,
      sharedDetails: [
        {
          contentId: documentId,
          title: title,
          source: ShareType.MI_NOTE,
          content: content,
        },
      ],
    });
  };

  return (
    <div id="DocumentListSection" className="DocumentListSection">
      {props.documents ? (
        props.documents.length > 0 ? (
          <List hover id="documents">
            <List.Item>
              {/* TODO : 체크박스 가리기(UI에 기능 추가시 복구)
              <Checkbox checked={checkState.checkAll} onChange={handleCheckAll}></Checkbox>
              */}
              <a href="#">{t('document.label.DocumentListSection.recent')}</a>
              <a href="#">{t('document.label.DocumentListSection.title')}</a>
            </List.Item>
            <CheckboxGroup value={checkState.value} onChange={handleCheck}>
              {props.documents &&
                props.documents.map((item, index) => (
                  <List.Item key={item['title'] + index} index={index} className="document-list">
                    {/* TODO : 체크박스 가리기(UI에 기능 추가시 복구)
                    <Checkbox
                      value={item['documentId']}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    ></Checkbox>
                    */}
                    {/*<Avatar circle />*/}
                    {item['folderId'] == DocumentFolderType.SHARED && (
                      <Button id="profileButton" className="btn-icon">
                        <Image
                          src={Avatars[item['avatarType']]}
                          style={{ backgroundColor: item['avatarBackgroundColor'] }}
                        />
                      </Button>
                    )}
                    <div
                      className="text"
                      onClick={() =>
                        item['folderId'] == DocumentFolderType.SHARED
                          ? showDocumentViewer(item['documentId'])
                          : showDocumentEditor(item['documentId'])
                      }
                    >
                      <p className="title">{item['title']}</p>
                      {item['folderId'] == DocumentFolderType.SHARED && (
                        <div>
                          <p className="comment">
                            <b>{item['createdMemberName']}</b>
                            {t('document.message.noteIsShared')}
                          </p>
                          <p className="comment">{item['sharedComment']}</p>
                        </div>
                      )}
                    </div>
                    <span
                      className="shared"
                      onClick={() =>
                        handleShowShareModal(item['documentId'].toString(), item['title'], item['content'])
                      }
                    ></span>
                    <span className="date">{setDateFormat(item['lastUpdateDate'])}</span>
                    {/*
                    <Dropdown
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                    
                      <Dropdown.Toggle id="sideMenuOption">
                        <i></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={e => {
                            e.stopPropagation();
                            props.handleShowMove(true);
                          }}
                        >
                          {t('document.label.DocumentListSection.dropdown.move')}
                        </Dropdown.Item>
                        
                        <Dropdown.Item
                          onClick={e => {
                            e.stopPropagation();
                            props.handleShowCopy(true);
                          }}
                        >
                          {t('document.label.DocumentListSection.dropdown.copy')}
                        </Dropdown.Item>
                        
                        <Dropdown.Item
                          onClick={e => {
                            e.stopPropagation();
                            props.handleShowDelete(true);
                          }}
                        >
                          {t('document.label.DocumentListSection.dropdown.delete')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    */}
                  </List.Item>
                ))}
            </CheckboxGroup>
          </List>
        ) : (
          <div id="emptyDocumentDiv" className="noDataContent">
            <ImgNodata />
            {t('document.message.noData')}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default DocumentListSection;
