import React, { useState, ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginForm.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import LoginResponse from '../../../../service/member/model/LoginResponse';
import LoginRequest from '../../../../service/member/model/LoginRequest';

import ArrowIcon from '../../../../icons/ArrowRight';

import LoginService from '../../../../service/member/LoginService';
import CookieService from '../../../../service/CookieService';
import TermsService from '../../../../service/terms/TermsService';
import TermsPage from '../../../terms/TermsPage';
import { TermsShowType, TermsTypeCode, TermsAgreeYnCondition } from '../../../../service/terms/model/Terms';

interface LoginFixedEmailProps {
  handleSubmit: Function;
  setFieldValue: Function;
}

const LoginFixedEmail: React.FC<LoginFixedEmailProps> = (props: LoginFixedEmailProps): ReactElement => {
  const fixedEmailList: string[] = [
    'user01@lgcns.com',
    'user02@lgcns.com',
    'user03@lgcns.com',
    'user04@lgcns.com',
    'user05@lgcns.com',
    'user06@lgcns.com',
    'user07@lgcns.com',
    'user08@lgcns.com',
    'user09@lgcns.com',
  ];
  return (
    <>
      {fixedEmailList.map((value, index) => {
        return (
          <Form.Row className="user-list" key={index}>
            <Button
              id="userLoginButton"
              name="userLoginButton"
              data-testid="userLoginButton"
              className="login-button"
              onClick={async () => {
                props.setFieldValue('emailId', value, false);
                await Promise.resolve();
                props.handleSubmit();
              }}
            >
              {value}
            </Button>
          </Form.Row>
        );
      })}
    </>
  );
};
export default LoginFixedEmail;
