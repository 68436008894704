import animal01 from '../icons/ic-avatar-animal01.png';
import animal02 from '../icons/ic-avatar-animal02.png';
import animal03 from '../icons/ic-avatar-animal03.png';
import animal04 from '../icons/ic-avatar-animal04.png';
import female01 from '../icons/ic-avatar-female01.png';
import female02 from '../icons/ic-avatar-female02.png';
import female03 from '../icons/ic-avatar-female03.png';
import male01 from '../icons/ic-avatar-male01.png';
import male02 from '../icons/ic-avatar-male02.png';
import male03 from '../icons/ic-avatar-male03.png';

const getAvatars = () => {
  return {
    animal01: animal01,
    animal02: animal02,
    animal03: animal03,
    animal04: animal04,
    female01: female01,
    female02: female02,
    female03: female03,
    male01: male01,
    male02: male02,
    male03: male03,
  };
};
export default getAvatars();
