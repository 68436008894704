import { YesNoFlag } from '../../model/enum/Common.enum';

export interface DocumentResponse {
  documentId: number;
  title: string;
  content: string;
  folderId: string;
  tags?: string;
  memberId: number;
  sharedYn: YesNoFlag;
  creationDate: string;
  lastUpdateDate: string;
  sharedComment: string;
  createdMemberId: string;
  createdMemberName: string;
  avatarType: string;
  avatarBackgroundColor: string;
}

export interface DocumentRequest {
  documentId?: number;
  title?: string;
  content?: string;
  folderId?: string;
  tags?: string;
  memberId?: number;
}

export interface DocumentFolderResponse {
  folderId: string;
  folderName: string;
}

export class EditorTrimAndTagRemoveConst {
  public static readonly REGEXP_TRIM: RegExp = /(^\s*)|(\s*$)/gi;
  public static readonly REGEXP_CONTENTS_TAG_REMOVE: RegExp = /&nbsp;|<br>/g;
}
