import React, { useState, useContext, ReactElement, ChangeEvent } from 'react';
import { Form, Button, FormControl } from 'react-bootstrap';
import SampleService from '../../../../service/SampleService';

export interface MetaSearchPocrops {} // eslint-disable-line

const MetaSearchPoc: React.FC<MetaSearchPocrops> = (props: MetaSearchPocrops) => {
  const getOGs = async (url: string) => {}; // eslint-disable-line
  const getOgsBtn = (event: React.MouseEvent) => {
    if (urlStr) {
      getOGs(urlStr);
    }
  };
  const setUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setUrlStr(event.target.value);
    }
  };
  const [ogsResult, setOgsResult] = useState<string>();
  const [urlStr, setUrlStr] = useState<string>('https://www.greencarcongress.com/2020/06/20200615-h2x.html');

  const [testAPIUrl, setTestAPIUrl] = useState<string>('http://localhost:1000/test');
  const [testRes, setTestRes] = useState<string>('');
  const handleAPIUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setTestAPIUrl(event.target.value);
    }
  };
  const callTestAPI = async () => {
    const response = await SampleService.testBackend(testAPIUrl);
    setTestRes(JSON.stringify(response));
  };

  return (
    <div>
      Meta Search
      <Form>
        <Form.Group controlId="openGraph">
          <Form.Label>URL</Form.Label>
          <Form.Control type="url" placeholder="Enter URL" onChange={setUrlHandler} defaultValue={urlStr} />
          <Button onClick={getOgsBtn}>Get Meta!!!</Button>
        </Form.Group>
      </Form>
      <div>{ogsResult}</div>
      <Form>
        <Form.Group controlId="testAPI">
          <Form.Label>Test API</Form.Label>
          <Form.Control type="url" placeholder="Enter API URL" onChange={handleAPIUrl} defaultValue={testAPIUrl} />
          <Button onClick={callTestAPI}>Test API</Button>
          <Form.Control as="textarea" rows="5" defaultValue={testRes} />
        </Form.Group>
      </Form>
    </div>
  );
};

export default MetaSearchPoc;
