import React, { ReactElement, useContext } from 'react';
import { AuthContext } from '../../../App';
import { SearchActionType } from '../../../reducer/actions';
import AggregateService from '../../../service/aggregate/AggregateService';
import { getCustomDate } from '../../../service/common/utils/CommonUtils';
import { DateFormat } from '../../../service/model/enum/Common.enum';
import { DetailOpenType, SearchData } from '../../../service/model/Search';
import { ActionFlag } from '../../../service/search/model/enum/Search.enum';
import './SearchItem.scss';

export interface SearchItemProps {
  searchData: SearchData;
  detailOpenType: string;
  detailOpenUrl?: string;
  isChecked: boolean;
  isCheckHover: boolean;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const SearchItem: React.FC<SearchItemProps> = (props: SearchItemProps): ReactElement => {
  const { state, dispatch } = useContext(AuthContext);
  const aggregateService = new AggregateService();

  const setDetailOpen = () => {
    if (props.searchData && props.searchData.crawlData)
      aggregateService.addAggregateData(props.searchData, ActionFlag.CLICK).then(response => {}); // eslint-disable-line

    if (props.detailOpenType === DetailOpenType.DETAIL_VIEW) {
      dispatch({
        type: SearchActionType.SHOW_SEARCH_DETAIL,
        searchDetail: { searchData: props.searchData, isDetailOpen: true },
      });
    } else {
      openNewTab();
    }
  };
  const openNewTab = () => {
    if (props.detailOpenUrl) {
      window.open(props.detailOpenUrl, '_blank');
    }
  };
  const onChange = () => ({ target: { checked: check } }) => {
    props.setChecked(check);
    if (check && state.searchDetail.isDetailOpen) {
      dispatch({
        type: SearchActionType.SHOW_SEARCH_DETAIL,
        searchDetail: { isDetailOpen: false },
      });
    }
  };
  const onMouseEnter = () => {
    props.setCheckHover(true);
  };
  const onMouseLeave = () => {
    const { isChecked } = props;
    if (!isChecked) props.setCheckHover(false);
  };

  return (
    <div className={'list flex'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {props.isCheckHover && (
        <input type="checkbox" className={'cb flex'} checked={props.isChecked} onChange={onChange()} />
      )}
      <div className={`flex list-box vbox ${state.searchDetail.isDetailOpen ? 'active' : ''}`}>
        <div className={'flex flex-one subject cursor_pointer'} onClick={setDetailOpen}>
          {props.searchData.title}
        </div>
        <div className={'flex hbox flex-one info'}>
          {/* TODO:작성자가 None일때 임시처리, 추후 수정 필요 */}
          {props.searchData.source !== 'None' && <div className={'flex spacing2'}>{props.searchData.source}</div>}
          <div className={'flex day'}>
            {props.searchData.date && getCustomDate(DateFormat.DATE_DISP_FORMAT, props.searchData.date)}
          </div>
        </div>
        <div className={'content-wrap flex'} dangerouslySetInnerHTML={{ __html: props.searchData.content }}></div>
        {/* TODO: 추후 props.social && 이런식으로 사용해야함 */}
        <div className={'flex social'}>
          <div className={'flex bookmark'}>0</div>
          <div className={'flex comment'}>0</div>
          <div className={'flex share'}></div>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
