import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import './ListTypeContentsCard.scss';
import { ListTypeContents } from '../../../../service/model/Contents';

export interface ListTypeContentsCardProps {
  contentsTitle: string;
  contentDataList: ListTypeContents[];
}

const ListTypeContentsCard: React.FC<ListTypeContentsCardProps> = (props: ListTypeContentsCardProps): ReactElement => {
  const [title, setTitle] = useState<string>();
  const [contentDataList, setContentDataList] = useState<ListTypeContents[]>();

  useEffect(() => {
    if (props) {
      setTitle(props.contentsTitle);
      setContentDataList(props.contentDataList);
    }
  }, [props, props.contentDataList]);

  return (
    <Card id="ListTypeContentsCard" className="ContentsCard type4">
      <Card.Body>
        <Card.Title className="cardTitle">{title}</Card.Title>
        <ul className="list">
          {props.contentDataList.map((item, index) => {
            return (
              <li key={index}>
                <span className="cardDesc">{item.contentDesc}</span>
                <span className="publisher">{item.publisher}</span>
              </li>
            );
          })}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default ListTypeContentsCard;
