import React, { useContext, useState } from 'react';

import CompetitionLeftMenu from './components/CompetitionLeftMenu';
import CompetitionContainer from './components/CompetitionContainer';

import './CompetitionPage.scss';

/* TODO: Pros 추가 후 lint 제외 삭제 */
// eslint-disable-next-line
export interface ContainerAProps {}

const CompetitionPage: React.FC<ContainerAProps> = (props: ContainerAProps) => {
  const [currentTableau, setCurrentTableau] = useState<string>('');
  const [menuName, setMenuName] = useState<string>('');

  return (
    <div id="competitionPage" className={'competitionPage'}>
      <section>
        <CompetitionLeftMenu setCurrentTableau={setCurrentTableau} setMenuName={setMenuName} />
        <CompetitionContainer currentTableau={currentTableau} menuName={menuName} />
      </section>
    </div>
  );
};

export default CompetitionPage;
