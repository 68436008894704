import React from 'react';
import { useTranslation } from 'react-i18next';

import './SearchEmpty.scss';

export interface SearchEmptyProps { } // eslint-disable-line

const SearchEmpty: React.FC<SearchEmptyProps> = (props: SearchEmptyProps) => {
  const { t } = useTranslation();
  return (
    <div className={'SearchEmpty flex flex-one'}>
      <div className={'result-empty flex cross-end center'}>{t('search.message.noData')}</div>
    </div>
  );
};

export default SearchEmpty;
