import BaseService from '../BaseService';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { CommonResponse } from '../model/CommonResponse';
import { MegaTrendCategoryYearCondtion, MegaTrendData, MegaTrendListSearchCondtion } from './model/MegaTrend';
import { SearchData } from '../model/Search';

export default class MegaTrendService extends BaseService {
  async searchSourceList(): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/sourcelist';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;
    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getCategoryYearList(condition: MegaTrendCategoryYearCondtion): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/categoryyearlist';
    const params = condition;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }

  async getCategoryList(): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/categorylist';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;
    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getYearList(): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/yearlist';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;
    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async searchMegaTrendList(condition: MegaTrendListSearchCondtion): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/es/megatrend';
    const params = condition;
    const body = null;
    const serviceName = Service.MIBE;
    let response: CommonResponse;
    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  getMegaTrendData(item: MegaTrendData): SearchData {
    return {
      title: item.keyword,
      date: item.crawlDttm,
      source: item.source,
      content: item.content,
      crawlData: {
        crawlDataId: item.source + ':' + item.crawlDttm, // TODO: 임시 crawlDataId 처리
        crawlDate: item.crawlDttm,
        url: item.url,
        publishDate: item.loadYmd,
        title: item.keyword,
        mediaType: '',
        content: item.content,
        mediaDetailId: '',
        imageUrl: '',
        fileUrl: item.fileDirectoryPath,
        author: item.source,
        addDate: item.loadYmd,
        htmlContent: item.content,
        morphemeWords: '',
      },
    };
  }
}
