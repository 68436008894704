import {
  BookmarkAddFolderCondtion,
  BookmarkAddContentsCondtion,
  FolderResponse,
  CrawlData,
  BookmarkContentsTypeCode,
  UrlInformation,
} from './model/Bookmark';
import { Service } from '../model/Service';
import { Method } from '../model/Method';
import { CommonResponse } from '../model/CommonResponse';
import BaseService from '../BaseService';

export default class BookmarkService extends BaseService {
  async addBookmarkFolder(inputFolderName: string): Promise<CommonResponse> {
    const condition: BookmarkAddFolderCondtion = {
      bookmarkFolderName: inputFolderName,
    };
    const method = Method.POST;
    const url = '/v1/bookmarks';
    const params = null;
    const body = condition;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }

  async retrieveFolderList(): Promise<FolderResponse[]> {
    const method = Method.GET;
    const url = '/v1/bookmarks';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async addBookmarkContents(condition: BookmarkAddContentsCondtion): Promise<CommonResponse> {
    const method = Method.POST;
    const url = '/v1/bookmarks/contents';
    const params = null;
    const body = condition;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  getBookmarkContentsByCrawlData(crawlData: CrawlData, bookmarkFolderId: number): BookmarkAddContentsCondtion {
    return {
      bookmarkFolderId: bookmarkFolderId,
      contentsId: crawlData.crawlDataId,
      publishedDate: crawlData.publishDate,
      title: crawlData.title,
      detailContents: crawlData.content,
      bookmarkContentsTypeCode: BookmarkContentsTypeCode.CT_ARTICLE,
      sharedYn: 'Y', //TODO: 추후에 수정 필요함
      crawlData: crawlData,
      source: 'tester', // TODO: 추후 수정 필요
    };
  }

  async retrieveContentList(folderId): Promise<BookmarkAddContentsCondtion[]> {
    const method = Method.GET;
    const url = '/v1/bookmarks/contents';
    const params = { folderId: folderId };
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async retrieveUrlInfo(targetUrl: string): Promise<UrlInformation> {
    const method = Method.GET;
    const requestUrl = '/v1/commons/url-info';
    const params = { url: targetUrl };
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, requestUrl, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }
}
