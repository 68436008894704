import LoginRequest from './model/LoginRequest';
import LoginResponse from './model/LoginResponse';
import { Service } from '../model/Service';
import { Method } from '../model/Method';
import BaseService from '../BaseService';
import { SessionInfo } from '../model/SessionInfo';

export default class LoginService extends BaseService {
  async requestLogin(loginRequest: LoginRequest): Promise<LoginResponse> {
    const method = Method.POST;
    const url = '/v1/member/tempLogin';
    const serviceName = Service.MIBE;
    const params = null;
    const body = loginRequest;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  public setSession = (param: LoginResponse) => {
    this.cookieService.setSessionToCookie(param as SessionInfo);
  };
}
