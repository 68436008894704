import React from 'react';
import './NavBar.scss';
import { Navbar, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MenuListI, MenuI } from '../service/model/Menu';
export interface NavBarProps {
  menuList: MenuListI[];
  currentSideMenu: MenuI;
  setCurrentSideMenu: Function;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const { t } = useTranslation();
  const menuDefaultClassName = '';
  return (
    <div id="navBar" className="navBar">
      <Navbar fixed="top" bg="dark" variant="dark">
        <Navbar.Brand href="/mi">
          <span className="bold">{t('common.NavBar.title')}</span>
          <span>{t('common.NavBar.subTitle')}</span>
        </Navbar.Brand>
        <div className="mainMenu navbar-expand ml-auto mr-auto">
          {props.menuList.map((menuList: MenuListI, inx) => {
            return (
              <span
                key={inx}
                onClick={() => props.setCurrentSideMenu(menuList.menuLv1)}
                className={
                  menuDefaultClassName + (props.currentSideMenu.menuCode === menuList.menuLv1.menuCode ? 'bold' : '')
                }
              >
                {menuList.menuLv1.menuTitle}
              </span>
            );
          })}
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
