import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import CookieService from './CookieService';
import { Error } from './model/Error';
import { HttpStatus, StatusCode } from './model/enum/Status.enum';
import uuidv4 from 'uuid';

export default class BaseService {
  private reactAppApiURL: any;
  private languageCode = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko';
  public cookieService = new CookieService();

  protected defaultPagingSize = 10;

  constructor() {
    this.reactAppApiURL = process.env.REACT_APP_API_URL ? JSON.parse(process.env.REACT_APP_API_URL) : {};
  }

  public getAPILocation = (key: string): string => {
    return this.reactAppApiURL[key];
  };

  protected correlationId = {
    correlationId:
      window.location.pathname === '/'
        ? 'root'.concat('_').concat(uuidv4())
        : window.location.pathname.concat('_').concat(uuidv4()), // 화면 경로 + uuid
  };

  protected headers = {
    Accept: 'application/json', //Accept-Charset은 고려 필요
    Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia', //임의값 적용, 인증관련 로직적용시 변경예정
    'Content-Type': 'application/json',
    'X-Correlation-Id': JSON.stringify(this.correlationId), // header 규칙 확인
    'Correlation-Object': JSON.stringify(this.correlationId), //Back-end 서버 업데이트 후 삭제
    'X-MI-APIKEY': 'API KEY BY TENANT',
    'X-Language-Code': this.languageCode,
    'X-Session-Id': this.cookieService.getSessionIdInCookie(),
  };

  protected config: AxiosRequestConfig = {
    method: 'GET',
    url: '',
    headers: this.headers,
    data: {},
  };

  /* eslint-disable */
  public async fnRest(
    method: string,
    url: string,
    params?: any,
    requestBody?: any,
    service?: string,
    timeout?: any
  ): Promise<any> {
    this.config.method = this.fnCheckHTTPMethod(method);
    this.config.url = this.fnCheckServiceUrl(url, service ? service : 'etc');
    this.config.params = params ? params : {}; // TODO
    this.config.data = requestBody ? requestBody : {}; // TODO
    if (timeout) {
      this.config.timeout = timeout;
    }
    this.config.withCredentials = true;

    try {
      const response: AxiosResponse<any> = await axios.request(this.config);
      if (response && response.data) {
        if (response.data.successOrNot) {
          return response.data;
        } else if (response.data.body && response.data.body.successOrNot) {
          return response.data.body;
        } else {
          const noProperError: Error = {
            successOrNot: 'N',
            statusCode: 'NO.PROPER',
            data: {},
          };
          throw noProperError;
        }
      } else {
        const noResponseError: Error = {
          successOrNot: 'N',
          statusCode: 'NO.RESPONSE',
          data: {},
        };
        throw noResponseError;
      }
    } catch (error) {
      if (error.response && error.response.status === HttpStatus.RESPONSE_STATUS_401) {
        window.location.assign('/unauthorized');
        return false;
      }
      if (error && (error as Error).successOrNot) {
        throw error;
      } else {
        const unknownError: Error = {
          successOrNot: 'N',
          statusCode: 'UNKNOWN.SERVER',
          data: {},
        };
        throw unknownError;
      }
    }
  }
  /* eslint-disable */

  public fnCheckServiceUrl = (url: string, service: string) => {
    let serviceUrl = this.reactAppApiURL[service] ? this.reactAppApiURL[service] + url : url;
    return serviceUrl;
  };

  public fnCheckHTTPMethod = (method: string) => {
    let httpMethod: Method;
    switch (method) {
      case 'GET':
      case 'get':
        httpMethod = 'GET';
        break;
      case 'POST':
      case 'post':
        httpMethod = 'POST';
        break;
      case 'PUT':
      case 'put':
        httpMethod = 'PUT';
        break;
      case 'PATCH':
      case 'patch':
        httpMethod = 'PATCH';
        break;
      case 'DELETE':
      case 'delete':
        httpMethod = 'DELETE';
        break;
      default:
        httpMethod = 'GET';
    }
    return httpMethod;
  };

  public getQueryStringFormat = (queryParam: any) => {
    const keys = Object.keys(queryParam);
    let queryString = '?';
    for (const key of keys) {
      if (queryString.length > 1) {
        queryString = queryString.concat('&');
      }
      queryString = queryString
        .concat(key)
        .concat('=')
        .concat(encodeURIComponent(queryParam[key]));
    }
    return queryString;
  };
}
