import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleSearch } from '../../service/search/model/enum/Search.enum';

import './GoogleSearchExceed.scss';

export interface GoogleSearchExceedProps {
  keyword: string;
}

const GoogleSearchExceed: React.FC<GoogleSearchExceedProps> = (props: GoogleSearchExceedProps) => {
  const { t } = useTranslation();
  const openGoogleWindow = () => {
    if (GoogleSearch.GOOGLE_SEARCH_URL) {
      window.open(GoogleSearch.GOOGLE_SEARCH_URL + props.keyword, '_blank');
    }
  };

  return (
    <div className={'GoogleSearchExceed flex'}>
      <div className={'list flex vbox google-exceed-alert'}>
        <div className={'flex grey-box'}>
          <div className={'flex exceed-msg-area'}>
            <b className={'flex label-area'}>{t('search.label.GoogleSearchExceed.googleConnect')}</b>
            <div className={'flex message-area'}>{t('search.message.googleSearchExceed')}</div>
          </div>
          <div className={'flex restore-msg-area'}>{t('search.message.restoreSoon')}</div>
          <div className={'flex new-window add'} onClick={openGoogleWindow}>
            {t('search.label.GoogleSearchExceed.googleLink')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleSearchExceed;
