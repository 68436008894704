import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export default class SampleService {
  public static testBackend = async (url: string) => {
    let testResult: AxiosResponse<any>;

    const headers = {
      Accept: 'application/json', //Accept-Charset은 고려 필요
      'Content-Type': 'application/json',
    };
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      headers: headers,
      data: {},
    };

    try {
      testResult = await axios.request(config);
    } catch (error) {
      testResult = error;
    }

    return testResult;
  };
}
