import React, { ReactElement, useContext, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';

import './YoutubeContainer.scss';

export interface YoutubeContainerProps {
  showYoutubeDrawer: Function;
  toggleDrawerSize: Function;
  setDrawerSize: Function;
}

const YoutubeContainer: React.FC<YoutubeContainerProps> = (props: YoutubeContainerProps): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const [footer, setFooter] = useState(false);
  return (
    <div id="YoutubeContainer" className={['YoutubeContainer', footer ? 'checked' : ''].join(' ')}>
      <div className="youtube-body">
        <section>
          <ButtonGroup>
            <Button
              className="btn-icon"
              onClick={() => {
                props.toggleDrawerSize();
              }}
            >
              <i className="ic-maximize"></i>
            </Button>
            <Button className="btn-icon">
              <i className="ic-share"></i>
            </Button>
            <Button className="btn-icon">
              <i className="ic-bookmark"></i>
            </Button>
            <Button
              className="btn-icon"
              onClick={() => {
                props.showYoutubeDrawer(false);
                props.setDrawerSize(false);
              }}
            >
              <i className="ic-close"></i>
            </Button>
          </ButtonGroup>
        </section>
      </div>
    </div>
  );
};

export default YoutubeContainer;
