import React, { ReactElement, useContext, useState } from 'react';
import { Drawer } from 'rsuite';
import { AuthContext } from '../../App';
import YoutubeContainer from './YoutubeContainer';

interface Props {
  showYoutubeDrawer: Function;
}

const YoutubeDrawerPage: React.FC<Props> = (props: Props): ReactElement => {
  const { state } = useContext(AuthContext);

  const [drawerSize, setDrawerSize] = useState(false);

  const toggleDrawerSize = () => {
    setDrawerSize(!drawerSize);
  };

  return (
    <Drawer
      backdrop={false}
      size="md"
      show={state.showYoutubeDrawer || false}
      onHide={() => props.showYoutubeDrawer(false)}
      className={drawerSize ? 'maximize' : ''}
    >
      <Drawer.Body>
        <YoutubeContainer
          showYoutubeDrawer={props.showYoutubeDrawer}
          toggleDrawerSize={toggleDrawerSize}
          setDrawerSize={setDrawerSize}
        />
      </Drawer.Body>
    </Drawer>
  );
};

export default YoutubeDrawerPage;
