import { CSSProperties } from 'react';
import CookieService from './CookieService';
import { ProfileConst } from './model/Profile';
import { SessionInfo } from './model/SessionInfo';

export default class ProfileService {
  private cookieService = new CookieService();
  private sessionInfo: SessionInfo = this.cookieService.getSessionInfoInCookie();

  public getProfileImageFileName = (): string => {
    const avatarType: string = this.sessionInfo.avatarType
      ? this.sessionInfo.avatarType
      : ProfileConst.AVATAR_DEFAULT_TYPE;

    return ProfileConst.AVATAR_IMAGE_PREFIX + avatarType + ProfileConst.AVATAR_IMAGE_EXTENSION;
  };

  public getImageStyle = (): CSSProperties => {
    const avatarBackgroundColor: string = this.sessionInfo.avatarBackgroundColor
      ? this.sessionInfo.avatarBackgroundColor
      : ProfileConst.AVATAR_DEFAULT_BACKGROUND_COLOR;

    return { backgroundColor: avatarBackgroundColor };
  };

  public getMemberKorNameInCookie = (): string => {
    return this.sessionInfo.memberKorName;
  };

  public getJobTitleInCookie = (): string => {
    return this.sessionInfo.jobTitle;
  };

  public getDeptKorNameInCookie = (): string => {
    return this.sessionInfo.deptKorName;
  };
}
