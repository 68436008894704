export enum menuCode {
  GOOGLE = 'GOOGLE',
  LINKMETA = 'LINKMETA',
}

export interface MenuListI {
  menuLv1: MenuI;
  menuLv2?: MenuI[];
}

export interface MenuI {
  menuCode: string;
  menuTitle: string;
  isScreen: string;
  order: number;
}

export interface TargetMenu {
  menuLv1: string;
  menuLv2: string;
}

export enum Menu {
  BOOKMARK = 'MENU001009',
}
