import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Move.scss';

import { ReactComponent as IconCheck } from '../../../../icons/ic-check.svg';

export interface MoveProps {
  handleCloseMove: Function;
}

export const Move: React.FC<MoveProps> = (props: MoveProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('document.modal.move.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>{t('document.modal.move.label')}</Form.Label>
          <Form.Control as="select">
            <option>거시환경</option>
            <option>2</option>
            <option>3</option>
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">
          <IconCheck />
          {t('document.modal.move.save')}
        </Button>
      </Modal.Footer>
    </>
  );
};
export default Move;
