export interface TermsRetrieveCondition {
  termsTypeCode: string;
  termsVersion: string;
}

export interface TermsAgreementCondition {
  termsId: number;
  memberId: number;
  termsAgreeYn: string;
}

export interface TermsAgreeYnCondition {
  termsTypeCode: string;
  memberId: number;
}
export interface TermsVersionRetrieveCondition {
  termsTypeCode: string;
}

export interface TermsResponse {
  termsId: number;
  termsTitle: string;
  termsDetailContents: string;
  languageCode?: string;
  termsTypeCode?: string;
  termsVersion?: string;
  termsAgreeYn?: string;
  memberId?: string;
}

export interface VersionResponse {
  termsVersion: string;
}

export enum TermsTypeCode {
  SERVICE = 'SERVICE',
  PRIVACY = 'PRIVACY',
}

export enum TermsShowType {
  AGREE = 'AGREEMENT',
  DISPLAY = 'DISPLAY',
}

export enum AgreeTerms {
  AGREE = 'Y',
  DONT_AGREE = 'N',
}
