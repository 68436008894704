import React from 'react';
import {} from 'react-bootstrap';
import './Title.scss';

/* TODO: Pros 추가 후 lint 제외 삭제 */
// eslint-disable-next-line
export interface TitleProps {}

const Title: React.FC<TitleProps> = (props: TitleProps) => {
  return (
    <div className="module-title">
      <a href="#"></a>
      <h3>Title</h3>
    </div>
  );
};

export default Title;
