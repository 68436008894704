import React from 'react';

import { TargetMenu } from '../../../service/model/Menu';

import BlankPage from './home/BlankPage';
import RecentPage from './home/RecentPage';
import BookmarkPage from './Bookmark';
import YoutubePage from './Youtube';

import GoogleSearchPoc from './home/GoogleSearchPoc';
import MetaSearchPoc from './home/MetaSearchPoc';
import SamplePage from './sample/SamplePage';
import TableauSamplePageOneItem from './home/TableauSamplePageOneItem';
import CompetitionPage from '../../competition/CompetitionPage';

import Container from '../../module/Container';

const Components = {
  MENU001: {
    MENU001001: RecentPage,
    MENU001002: Container,
    MENU001003: TableauSamplePageOneItem,
    MENU001004: CompetitionPage,
    MENU001005: BlankPage,
    MENU001006: BlankPage,
    MENU001007: BlankPage,
    MENU001008: BlankPage,
    MENU001009: BookmarkPage,
    MENU001010: YoutubePage,
  },
  MENU005: {
    MENU005001: GoogleSearchPoc,
    MENU005002: MetaSearchPoc,
    MENU005003: SamplePage,
  },
};

// eslint-disable-next-line
export default (menuInfo: TargetMenu): JSX.Element => {
  if (
    typeof Components[menuInfo.menuLv1] !== 'undefined' &&
    typeof Components[menuInfo.menuLv1][menuInfo.menuLv2] !== 'undefined'
  ) {
    return React.createElement(Components[menuInfo.menuLv1][menuInfo.menuLv2], { key: menuInfo.menuLv2 });
  }

  return React.createElement(() => <div></div>, { key: menuInfo.menuLv2 });
};
