export interface SearchDetailCopyInfo {
  title: string;
  url: string;
  tags?: string[];
}

export class SearchConstants {
  public static readonly BODY_STR = 'body';
  public static readonly DIV_STR = 'div';
  public static readonly ABSOLUTE_POSITION = 'absolute';
  public static readonly LEFT_HIDE = '-99999px';
  public static readonly PRE_STR = 'PRE';
  public static readonly PRE_TAG_STR = '<pre>{0}</pre>';
  public static readonly BR_TAG_STR = '<br />';
  public static readonly A_TAG_STR = '<a href="{0}">{1}</a>';
}
