import React, { useState, useContext, ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ContentsResponse } from '../../../../service/model/Contents';
import ContentsService from '../../../../service/ContentsService';
import ContentsCardDeck from './ContentsCardDeck';

export interface RecentPageProps {} // eslint-disable-line

const contentsResList = [
  {
    categoryName: '메가트렌드',
    contentsList: [
      {
        contentTitle: '지구온난화라는 이름의 기후 ‘대격변’…더는 안전지대가 없다',
        contentDesc:
          '열 받은 지구, 눈물을 흘리다\n 지구온난화로 해수면 온도 상승…아프리카·호주에 동시다발 영향\n 한반도 연평균 기온 계속 올라…동물 번식 시기 앞당겨져',
        contentDataList: [],
        publisher: '그린포스트코리아',
        publishedDate: '2020.7.10',
        imageUrl: 'http://www.greenpostkorea.co.kr/news/photo/202007/119243_120274_467.jpg',
        contentUrl: 'http://www.greenpostkorea.co.kr/news/articleView.html?idxno=119243',
        cardType: 'type2',
      },
      {
        contentTitle: 'Military helicopter shot at over northern Virginia, crew member injured',
        contentDesc:
          'The FBI is investigating the shooting of a U.S. Air Force helicopter as it flew over northern Virginia on Monday.One crew member had a minor injury, according to the FBI and the Air Force. The UH-1N Huey helicopter was forced to make an emergency landing at a nearby airport and a bullet was discovered in the airframe.',
        contentDataList: [],
        publisher: 'ABC News',
        publishedDate: '2020.8.12',
        imageUrl:
          'https://s.abcnews.com/images/Politics/uh1-helicopter-1st-squadron-file-ht-jef-200812_1597252479589_hpMain_16x9_992.jpg',
        contentUrl:
          'https://abcnews.go.com/Politics/military-helicopter-shot-northern-virginia-crew-member-injured/story?id=72329039',
        cardType: 'type1',
      },
      {
        contentTitle: 'Title3',
        contentDesc: 'Desc3',
        contentDataList: [],
        publisher: 'publisher3',
        publishedDate: '2020.8.12',
        imageUrl: '',
        contentUrl: '',
        cardType: 'type3',
      },
      {
        contentTitle: 'HOT 10 MEGA TREND',
        contentDesc: '',
        contentDataList: [
          {
            contentDesc: 'The Skinny On Lcd Monitors',
            publisher: 'BuzzFeed.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Party Jokes Startling But Unnecessary',
            publisher: 'CBS News',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Cleaning And Organizing Your Computer',
            publisher: 'Huffington Post.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'The Skinny On Lcd Monitors',
            publisher: 'BuzzFeed.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Party Jokes Startling But Unnecessary',
            publisher: 'CBS News',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Cleaning And Organizing Your Computer',
            publisher: 'Huffington Post.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'The Skinny On Lcd Monitors',
            publisher: 'BuzzFeed.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Party Jokes Startling But Unnecessary',
            publisher: 'CBS News',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'Cleaning And Organizing Your Computer',
            publisher: 'Huffington Post.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
          {
            contentDesc: 'The Skinny On Lcd Monitors',
            publisher: 'BuzzFeed.',
            publishedDate: '2020.8.22',
            contentUrl: '',
          },
        ],
        publisher: 'publisher4',
        publishedDate: '2020.8.12',
        imageUrl: '',
        contentUrl: '',
        cardType: 'type4',
      },
    ],
  },
];

const RecentPage: React.FC<RecentPageProps> = (props: RecentPageProps) => {
  const [contentsResponseList, setContentsResponseList] = useState<ContentsResponse[]>(contentsResList);

  return (
    <div id="RecentPageSection" className="RecentPageSection">
      <ContentsCardDeck contentsResponseList={contentsResponseList}></ContentsCardDeck>
    </div>
  );
};

export default RecentPage;
