import React, { useState, CSSProperties } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Interests } from './modal/Interests';
import AvatarImg from '../../../images/cpp-avatar_0010s_0000_WOMAN-1.png';

import './Profile.scss';

export interface ProfileProps {
  memberKorName: string;
  jobTitle: string;
  deptKorName: string;
  avatarImage: string;
  imageStyle: CSSProperties;
  setProfileRootClose: Function;
}

export const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {
  const { t } = useTranslation();

  const [showInterests, setShowInterests] = useState(false);

  const handleShowInterests = () => {
    setShowInterests(true);
    props.setProfileRootClose(false);
  };

  return (
    <div id="Profile">
      <a href="#" className="avatar">
        <Image src={require(`../../../icons/${props.avatarImage}`)} style={props.imageStyle} />
      </a>
      <p className="name">
        <b>{props.memberKorName}</b>
        {props.jobTitle}
      </p>
      <p className="department">{props.deptKorName}</p>
      <ul className="menu">
        <li>
          <a href="#">{t('profile.menu.profileInfo')}</a>
        </li>
        <li>
          <a href="#" id="bntInterest" onClick={() => handleShowInterests()}>
            {t('profile.menu.interest')}
          </a>
        </li>
        <li>
          <a href="#">{t('profile.menu.setting')}</a>
        </li>
      </ul>
      {showInterests && (
        <Interests
          isFirst={false}
          onHide={() => {
            setShowInterests(false);
          }}
        ></Interests>
      )}
    </div>
  );
};
export default Profile;
