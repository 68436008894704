import React from 'react';
import { SearchMenuType } from '../../service/search/model/enum/Search.enum';
import GoogleSearchPage from './GoogleSearchPage';
import MiMaterialPage from './MiMaterialPage';
import NewsAndReportPage from './NewsAndReportPage';
import './SearchContents.scss';
import UnifiedSearchPage from './UnifiedSearchPage';

export interface SearchContentsProps {
  isChecked: boolean;
  isCheckHover: boolean;
  leftMenu: number;
  keyword: string;
  setLeftMenu: React.Dispatch<number>;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const SearchContents: React.FC<SearchContentsProps> = (props: SearchContentsProps) => {
  const { leftMenu } = props;
  return (
    <div className={'SearchContents flex vbox relative'}>
      {leftMenu === SearchMenuType.UNIFIED && <UnifiedSearchPage {...props} />}
      {leftMenu === SearchMenuType.MI_MATERIAL && <MiMaterialPage {...props} />}
      {leftMenu === SearchMenuType.NEWS_REPORT && <NewsAndReportPage {...props} />}
      {leftMenu === SearchMenuType.GOOGLE && <GoogleSearchPage {...props} />}
    </div>
  );
};

export default SearchContents;
