import PropTypes from 'prop-types';
import React, { createRef, CSSProperties, ReactElement, useContext, useEffect, useState } from 'react';
import { Badge, Button, Image, Overlay, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import Footer from '../../layouts/Footer';
import CommonLoadingOverlay from '../../layouts/LoadingOverlay';
import { ActionType, BookmarkActionType, NoticeActionType } from '../../reducer/actions';
import { Menu, MenuI } from '../../service/model/Menu';
import { TermsShowType, TermsTypeCode } from '../../service/terms/model/Terms';
import DocumentDrawerPage from '../document/DocumentDrawerPage';
import SearchDetail from '../search/SearchDetail';
import SearchPage from '../search/SearchPage';
import TermsPage from '../terms/TermsPage';
import Bookmark from './component/Bookmark';
import LocalNav from './component/LocalNav';
import Component from './component/MiComponents';
import Profile from './component/Profile';
import Search from './component/Search';
import './MiContainer.scss';
import { SessionInfo } from '../../service/model/SessionInfo';
import { Interests } from './component/modal/Interests';
import InterestService from '../../service/interests/InterestService';
import ProfileService from '../../service/ProfileService';
import ShareModal from '../share/ShareModal';
import { ShareType } from '../../service/share/model/enum/Share.enum';
import ModuleDetail from '../module/component/ModuleDetail';
import NoticeDrawer from '../notice/NoticeDrawer';

export interface MiContainerI {
  menuLv1: MenuI;
  menuLv2List: MenuI[];
}

const MiContainer: React.FC<MiContainerI> = (props: MiContainerI) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;
  const menuLv1Code: string = props.menuLv1.menuCode;

  const [showTermModal, setShowTermModal] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>(0);
  const [showType, setShowType] = useState<string>(TermsShowType.AGREE);
  const [termsCode, setTermsCode] = useState<string>(TermsTypeCode.SERVICE);

  const [showInterestModal, setShowInterestModal] = useState<boolean>(false);

  const [isHome, setIsHome] = useState(true);
  const divRef = createRef<HTMLDivElement>();
  divRef.current?.setAttribute('scroll-behavior', 'smooth');

  const [search, setSearchChange] = useState<string>('');
  const profileService = new ProfileService();

  const [profileRootClose, setProfileRootClose] = useState<boolean>(true);

  const interestService = new InterestService();
  const avatarImage: string = profileService.getProfileImageFileName();
  const imageStyle: CSSProperties = profileService.getImageStyle();

  const hideTermsModal = () => {
    setShowTermModal(false);
  };

  const checkInitialInterestSetup = () => {
    interestService.getInitialInterestSetupYn().then(response => {
      if (response.successOrNot === 'Y') {
        if (response.data.interestSetupYn === 'N') {
          setShowInterestModal(true);
        } else {
          setShowInterestModal(false);
        }
      }
    });
  };

  /* eslint-disable */
  useEffect(() => {
    checkInitialInterestSetup();
  }, []);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    dispatch({
      type: ActionType.CHANGE_MENU,
      menuLv2: props.menuLv2List && props.menuLv2List.length > 0 ? props.menuLv2List[0].menuCode : undefined,
    });
  }, [props.menuLv1.menuCode]);
  /* eslint-enable */

  const onSearchChange = ({ target: { value } }): void => {
    if (value) {
      dispatch({
        type: ActionType.SET_SEARCH_RESULT,
        searchResult: true,
      });
      setIsHome(false);
      setSearchChange(value);
    }
  };

  const scrollHandle = (): void => {
    const scrollTop = divRef.current?.scrollTop as number;
    if (isHome && scrollTop > 30) {
      setIsHome(false);
    }
  };

  const menuClick = (e): void => {
    dispatch({
      type: ActionType.SET_SEARCH_RESULT,
      searchResult: false,
    });
    if (e) {
      divRef.current?.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  };

  const handleCloseBookmark = () => {
    dispatch({
      type: BookmarkActionType.SHOW_BOOKMARK,
      showBookmark: false,
    });
    dispatch({
      type: ActionType.CHANGE_MENU,
      menuLv2: state.menuLv2,
    });
  };
  const handleShowBookmark = () => {
    dispatch({
      type: BookmarkActionType.SHOW_BOOKMARK,
      showBookmark: true,
    });
    dispatch({
      type: BookmarkActionType.SET_IS_INITIAL,
      isInitial: true,
    });
    dispatch({
      type: ActionType.CHANGE_MENU,
      menuLv2: state.menuLv2,
    });
  };

  const showNoticesDrawer = (isShow: boolean): void => {
    dispatch({
      type: NoticeActionType.SHOW_NOTICE_DRAWER,
      showNoticeDrawer: isShow,
    });
  };

  const showDocumentsDrawer = (isShow: boolean): void => {
    dispatch({
      type: ActionType.SHOW_DOCUMENT_DRAWER,
      showDocumentDrawer: isShow,
    });
  };

  const hideInterestModal = () => {
    setShowInterestModal(false);
  };

  const getMiContainer = (): ReactElement => {
    return (
      <div
        id="MiContainer"
        onWheel={scrollHandle}
        onScroll={scrollHandle}
        ref={divRef}
        className={['MiContainer', isHome ? 'home' : '', state.menuLv2 === Menu.BOOKMARK ? 'bookmarkTab' : ''].join(
          ' '
        )}
      >
        {showTermModal ? (
          <TermsPage onHide={hideTermsModal} memberId={memberId} showType={showType} termsCode={termsCode}></TermsPage>
        ) : (
          showInterestModal && <Interests isFirst onHide={hideInterestModal}></Interests>
        )}
        <header>
          <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
            <a href="#" id="systemMap">
              {t('home.header.systemMap')}
            </a>
            <Button
              id="noticeButton"
              className="btn-icon"
              title={t('home.button.notice')}
              onClick={() => showNoticesDrawer(true)}
            >
              <i className="ic-bell"></i>
              <Badge variant="primary"></Badge>
            </Button>
            <Button
              id="bookmarkButton"
              className="btn-icon"
              title={t('home.button.bookmark')}
              onClick={handleShowBookmark}
            >
              <i className="ic-bookmark"></i>
            </Button>
            <Button
              id="documentButton"
              className="btn-icon"
              title={t('home.button.document')}
              onClick={() => showDocumentsDrawer(true)}
            >
              <i className="ic-pencil"></i>
            </Button>
            <OverlayTrigger
              trigger="click"
              placement="bottom-end"
              rootClose={profileRootClose}
              overlay={
                <Popover id="popoverProfile">
                  <Profile
                    memberKorName={profileService.getMemberKorNameInCookie()}
                    jobTitle={profileService.getJobTitleInCookie()}
                    deptKorName={profileService.getDeptKorNameInCookie()}
                    avatarImage={avatarImage}
                    imageStyle={imageStyle}
                    setProfileRootClose={setProfileRootClose}
                  />
                </Popover>
              }
            >
              <Button id="profileButton" className="btn-icon">
                <Image src={require(`../../icons/${avatarImage}`)} style={imageStyle} />
              </Button>
            </OverlayTrigger>
          </div>
          <Search id="miContainer" keyword={search} onSearchChange={onSearchChange} />
        </header>
        {state.menuLv2 && (
          <>
            <LocalNav
              menuLv2List={props.menuLv2List}
              menuLv2={state.menuLv2}
              setmenuLv2={menu => {
                dispatch({
                  type: ActionType.CHANGE_MENU,
                  menuLv2: menu,
                });

                if (menu === Menu.BOOKMARK) {
                  dispatch({
                    type: BookmarkActionType.SET_IS_INITIAL,
                    isInitial: true,
                  });
                }
              }}
              menuClick={menuClick}
              onSearchChange={onSearchChange}
              keyword={search}
            />
          </>
        )}

        {(state.menuLv2 || props.menuLv1.isScreen === 'Y') &&
          (state.searchResult ? (
            <SearchPage keyword={search} />
          ) : (
            <div className="tab-content">
              {Component({
                menuLv1: menuLv1Code,
                menuLv2: state.menuLv2 ? state.menuLv2 : menuLv1Code,
              })}
            </div>
          ))}

        {state.searchResult ? null : <Footer></Footer>}
        <Overlay show={state.bookmark.showBookmark} onHide={handleCloseBookmark}>
          <Popover id="Bookmark">
            <Bookmark handleCloseBookmark={handleCloseBookmark} menuClick={menuClick}></Bookmark>
          </Popover>
        </Overlay>
        <NoticeDrawer showNoticesDrawer={showNoticesDrawer} />
        <DocumentDrawerPage showDocumentsDrawer={showDocumentsDrawer} />
        <SearchDetail />
        <ModuleDetail />
        <ShareModal />
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getMiContainer}</CommonLoadingOverlay>;
};

MiContainer.propTypes = {
  menuLv1: PropTypes.any,
  menuLv2List: PropTypes.any,
};

export default MiContainer;
