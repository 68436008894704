import React from 'react';

import './SearchBottom.scss';

export interface SearchBottomProps {
  showUnlikeModal: any;
}

const SearchBottom: React.FC<SearchBottomProps> = (props: SearchBottomProps) => {
  return (
    <div className={'SearchBottom flex'}>
      <div className={'bottom-wrap flex flex-one vbox'}>
        <div className={'flex hbox title-area'}>
          <div className={'flex title'}>{'검색 결과는 만족하셨나요?'}</div>
          <div className={'flex like'}>{'만족해요!'}</div>
          <div className={'flex unlike'} onClick={props.showUnlikeModal}>
            {'별로에요'}
          </div>
        </div>
        <div className={'flex hbox input-area'}>
          <div className={'flex'}>
            <input className={'input'} />
          </div>
          <div className={'flex search-function'}>
            <div className={'search-cancel-btn'}></div>
            <div className={'search-btn'}></div>
            <div className={'rectangle'}></div>
            <div className={'google'}></div>
            <div className={'search-next-btn'}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBottom;
