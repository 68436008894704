import { MenuListI } from './model/Menu';
import { Service } from './model/Service';
import BaseService from './BaseService';

export default class MenuService extends BaseService {
  async getMenuList(): Promise<MenuListI[]> {
    const method = 'GET';
    const url = '/v1/menuList';
    const params = null;
    const body = null;
    const serviceName = Service.MIBE;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : [];
  }
}
