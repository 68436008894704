import React from 'react';

import './Bookmark.scss';

export interface BookmarkProps {
  Dummy?: string;
}

const Bookmark: React.FC<BookmarkProps> = (props: BookmarkProps) => {
  return (
    <div className={'Bookmark fixed bottom left right flex flex-one'}>
      <div className={'flex center between'}>
        <div className={'flex left-area'}>
          <button className={'flex center all-btn'}>{'전체선택'}</button>
          <button className={'flex center cancel-btn'}>{'취소'}</button>
        </div>
        <div className={'flex right-area'}>
          <button className={'flex center bookmark-btn'}>{'북마크'}</button>
          <button className={'flex center share-btn'}>{'공유'}</button>
          <button className={'flex center analysis-btn'}>{'분석하기'}</button>
        </div>
      </div>
    </div>
  );
};

export default Bookmark;
