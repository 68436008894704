import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import Pagination from '../../pages/common/Pagination';
import { ActionType, NewsActionType, SearchActionType } from '../../reducer/actions';
import { DetailOpenType } from '../../service/model/Search';
import { RecommendedSearch, SearchCondition } from '../../service/search/model/enum/Search.enum';
import { NewsListSearchCondtion } from '../../service/search/model/Search';
import SearchItem from './component/SearchItem';
import './NewsAndReportPage.scss';
import SearchEmpty from './SearchEmpty';

export interface NewsAndReportPageProps {
  keyword: string;
  setLeftMenu: React.Dispatch<number>;
  isChecked: boolean;
  isCheckHover: boolean;
  setChecked: React.Dispatch<boolean>;
  setCheckHover: React.Dispatch<boolean>;
}

const NewsAndReportPage: React.FC<NewsAndReportPageProps> = (props: NewsAndReportPageProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState<number>(SearchCondition.START_PAGE_NUMBER);

  useEffect(() => {
    if (state.isInitialPage) searchNewsList(SearchCondition.START_PAGE_NUMBER, SearchCondition.DEFAULT_PAGE_COUNT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialPage]);

  useEffect(() => {
    if (state.searchRight.recommendedSearch) {
      dispatch({
        type: SearchActionType.SET_IS_INITIAL_PAGE,
        isInitialPage: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchRight.recommendedSearch]);

  const searchNewsList = async (pageNum: number, counterPerPage: number): Promise<void> => {
    const condition: NewsListSearchCondtion = {
      size: counterPerPage,
      page: pageNum,
      keyword: props.keyword,
      recommendedSearch: state.cookieService.getRecommendedSearch() === RecommendedSearch.TOGGLE_ON,
    };
    const response = await state.searchService.searchNewsReportList(condition);
    let isShowRight = false;
    let keywordCategories = [];

    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: NewsActionType.SET_NEWS_VIEW_LIST,
        newsDataList: response.data,
      });
      if (response.data && response.data.total && response.data.total.value > 0) {
        isShowRight = true;
        keywordCategories = response.data.keywordCategories;
      }
    } else {
      dispatch({
        type: NewsActionType.SET_NEWS_VIEW_LIST,
        /* eslint-disable */
        newsDataList: {
          timed_out: false,
          total: {
            value: 0,
            relation: '',
          },
          max_score: 0,
          items: [],
        },
        /* eslint-enable */
      });
      dispatch({
        type: ActionType.SHOW_TOAST,
        toast: {
          showToast: true,
          toastMessage: t('search.message.UE'),
        },
      });
    }

    dispatch({
      type: SearchActionType.SHOW_SEARCH_RIGHT,
      searchRight: {
        isShowRight: isShowRight,
        keywordCategories: keywordCategories,
        recommendedSearch: state.cookieService.getRecommendedSearch(),
      },
    });

    if (state.isInitialPage) {
      dispatch({
        type: SearchActionType.SET_IS_INITIAL_PAGE,
        isInitialPage: false,
      });
    }

    setCurrentPage(pageNum);
  };

  return (
    <Fragment>
      {state.news.total.value > 0 ? (
        <>
          <div className={'NewsAndReportPage flex vbox'}>
            <div className={'title flex hbox between'}>
              <div className={'flex'}>{t('search.label.category.newsAndReport')}</div>
              <div className={'flex center paging_numbers'}>
                {state.searchService.getDefaultItemNumberStateDisplayStr(
                  currentPage,
                  state.news.total.value,
                  state.news.total.relation
                ) + t('search.label.category.count')}
              </div>
            </div>
          </div>
          <div className={'flex vbox'}>
            {state.news.items.map((item, index) => {
              return (
                <SearchItem
                  isCheckHover={props.isCheckHover}
                  isChecked={props.isChecked}
                  setCheckHover={props.setCheckHover}
                  setChecked={props.setChecked}
                  key={index}
                  searchData={state.searchService.getSearchDataByNewsData(item)}
                  detailOpenType={DetailOpenType.DETAIL_VIEW}
                />
              );
            })}
            <Pagination
              totalCount={state.news.total.value}
              countPerPage={SearchCondition.DEFAULT_PAGE_COUNT}
              onPageSelect={searchNewsList}
            />
          </div>
        </>
      ) : (
        <div id="newsNoData" className="noData">
          <SearchEmpty />
        </div>
      )}
    </Fragment>
  );
};

export default NewsAndReportPage;
