import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../App';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Search.scss';
import { ActionType } from '../../../reducer/actions';

import { ReactComponent as LogoHome } from '../../../icons/logo-singlex.svg';

export interface SearchProps {
  keyword: string;
  onSearchChange: Function;
  id: string;
}

export const Search: React.FC<SearchProps> = (props: SearchProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [inputFocused, setInputFocused] = useState(false);
  const inputFocus = (): void => {
    setInputFocused(true);
  };
  const inputBlur = (): void => {
    setInputFocused(false);
  };
  const inputKeyDown = (e): void => {
    if (e.key === 'Enter') {
      dispatch({
        type: ActionType.SEARCH,
        search: {
          isEnter: true,
        },
      });
      props.onSearchChange(e);
    }
  };

  return (
    <div className={['search-block', inputFocused ? 'searchMode' : ''].join(' ')}>
      <div key={props.keyword} className="form-group">
        <label htmlFor="mainSearch">
          <LogoHome />
          <span>상품기획 MI</span>
        </label>
        <div className="nav-icon">
          <div></div>
        </div>
        <input
          className="form-control form-control-lg mainSearch"
          id="mainSearch"
          placeholder={t('search.placeholder')}
          aria-describedby=""
          onFocus={inputFocus}
          onBlur={inputBlur}
          onKeyDown={inputKeyDown}
          defaultValue={props.keyword}
        />
        <Button className="advancedSearch">
          {t('search.advancedSearch')}
          <i></i>
        </Button>
      </div>
    </div>
  );
};
export default Search;
