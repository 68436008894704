import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import './ContentsCard.scss';
import { Contents, ListTypeContents } from '../../../../service/model/Contents';
import ListTypeContentsCard from './ListTypeContentsCard';

export interface ContentsCardProps {
  key: number;
  contentsList: Contents[];
}

const ContentsCard = (props: ContentsCardProps): ReactElement => {
  return (
    <>
      {props.contentsList.map((item, index) => {
        return (
          // ToDo : Check the render method of `ContentsCard`. See https://fb.me/react-warning-keys for more information.
          // need "key" ex) div key={index}
          <div key={index}>
            {item.cardType !== 'type4' && (
              <Card className={'ContentsCard ' + item.cardType}>
                {item.imageUrl && (
                  <a href={item.contentUrl} target="_blank">
                    <Card.Img className="cardImg" variant="top" src={item.imageUrl} />
                  </a>
                )}
                <Card.Body key={index}>
                  <Card.Title className="cardTitle">{item.contentTitle}</Card.Title>
                  <Card.Text className="cardText">{item.contentDesc}</Card.Text>
                  <div className="publishInfo">
                    <span className="publisher">{item.publisher}</span>
                    <span className="publishedDate">{item.publishedDate}</span>
                  </div>
                </Card.Body>
              </Card>
            )}
            {item.cardType === 'type4' && (
              <ListTypeContentsCard
                key={index}
                contentsTitle={item.contentTitle}
                contentDataList={item.contentDataList}
              ></ListTypeContentsCard>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ContentsCard;
