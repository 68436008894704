import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './UnauthorizedErrorPage.scss';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';

const UnauthorizedErrorPage: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const goToLoginPage = () => {
    window.location.assign('/login');
  };
  return (
    <div id="UnauthorizedErrorPage">
      <Container>
        <Row>
          <Col>
            <div className="container">
              <div id="UnauthorizedErrorPageTitle" className="UnauthorizedErrorPage-title">
                <span id="UnauthorizedErrorPageTitleTarget" className="UnauthorizedErrorPage-title-target">
                  {t('common.UnauthorizedErrorPage.title')}
                </span>
              </div>
              <div id="UnauthorizedErrorPageContent" className="UnauthorizedErrorPage-content">
                <div className="contents">{t('common.UnauthorizedErrorPage.contents')}</div>
                <div className="contents-sub">{t('common.UnauthorizedErrorPage.contentsSub')}</div>
              </div>
              <div className="UnauthorizedErrorPage-button">
                <Button variant="secondary" className="secondary btn-login" onClick={goToLoginPage} id="btnLoginPage">
                  {t('common.UnauthorizedErrorPage.button.goLogin')}
                </Button>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};
export default UnauthorizedErrorPage;
