import { DocumentRequest } from '../service/document/model/Document';
import { DocumentViewType } from '../service/document/model/enum/Document.enum';
import {
  MegaTrendDataList,
  MegaTrendSourceList,
  CategoryGroup,
  YearGroup,
  ModuleDetail,
} from '../service/megaTrend/model/MegaTrend';
import { GooglePagingInfo, GoogleSearchResult } from '../service/search/model/GoogleSearch';
import { MiMaterialResponse, NewsDataList, SearchDetail } from '../service/search/model/Search';
import { SearchRight } from '../service/search/model/SearchRight';
import { ShareType } from '../service/share/model/enum/Share.enum';
import { SharedDetail } from '../service/share/model/Share';
import { MemberResponse } from '../service/member/model/Member';
import { Loading, Search, Toast } from './states';

export enum ActionType {
  LOADING = 'LOADING',
  SHOW_TOAST = 'SHOW_TOAST',
  SHOW_DOCUMENT_DRAWER = 'SHOW_DOCUMENT_DRAWER',
  SHOW_YOUTUBE_DRAWER = 'SHOW_YOUTUBE_DRAWER',
  SEARCH = 'SEARCH',
  CHANGE_MENU = 'CHANGE_MENU',
  SET_SEARCH_RESULT = 'SET_SEARCH_RESULT',
}

export enum DocumentActionType {
  SET_DOCUMENT_VIEW_LIST = 'SET_DOCUMENT_VIEW_LIST',
  SET_DOCUMENT_VIEW_EDITOR = 'SET_DOCUMENT_VIEW_EDITOR',
  SET_DOCUMENT_EDITOR_CONTENT = 'SET_DOCUMENT_EDITOR_CONTENT',
  SET_DOCUMENT_EDITOR = 'SET_DOCUMENT_EDITOR',
  SET_DOCUMENT_VIEW_CONTENT = 'SET_DOCUMENT_VIEW_CONTENT',
}

export enum NewsActionType {
  SET_NEWS_VIEW_LIST = 'SET_NEWS_VIEW_LIST',
}

export enum GoogleSearchActionType {
  SET_GOOGLE_RESULT_LIST = 'SET_GOOGLE_RESULT_LIST',
}

export enum MiMaterialActionType {
  SET_MI_MATERIAL_RESULT = 'SET_MI_MATERIAL_RESULT',
}

export enum MegaTrendSourceActionType {
  SET_MEGA_TREND_SOURCE_LIST = 'SET_MEGA_TREND_SOURCE_LIST',
}

export enum MegaTrendCategoryYearActionType {
  SET_MEGA_TREND_CATEGORY_YEAR_LIST = 'SET_MEGA_TREND_CATEGORY_YEAR_LIST',
}

export enum MegaTrendActionType {
  SET_MEGA_TREND_RESULT_LIST = 'SET_MEGA_TREND_RESULT_LIST',
}

export enum MegaTrendDetailActionType {
  SHOW_MODULE_DETAIL = 'SHOW_MODULE_DETAIL',
}

export enum SearchActionType {
  SET_IS_INITIAL_PAGE = 'SET_IS_INITIAL_PAGE',
  SHOW_SEARCH_DETAIL = 'SHOW_SEARCH_DETAIL',
  SHOW_SEARCH_RIGHT = 'SHOW_SEARCH_RIGHT',
  HIDE_SEARCH_RIGHT = 'HIDE_SEARCH_RIGHT',
  TOGGLE_RECOMMENDED_SEARCH = 'TOGGLE_RECOMMENDED_SEARCH',
}

export enum BookmarkActionType {
  SHOW_BOOKMARK = 'SHOW_BOOKMARK',
  ADD_FOLDER = 'ADD_FOLDER',
  SET_IS_INITIAL = 'SET_IS_INITIAL',
  EXPAND_BOOKMARK = 'EXPAND_BOOKMARK',
  ADD_CONTENTS = 'ADD_CONTENTS',
}

export enum ShareActionType {
  SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL',
  SET_SHARED_DETAILS = 'SET_SHARED_DETAILS',
  ADD_MEMBERS = 'ADD_MEMBERS',
}

export enum NoticeActionType {
  SHOW_NOTICE_DRAWER = 'SHOW_NOTICE_DRAWER',
}

export type Action = {
  type: ActionType;
  loading?: Loading;
  toast?: Toast;
  showDocumentDrawer?: boolean;
  showYoutubeDrawer?: boolean;
  search: Search;
  menuLv2: string | undefined;
  searchResult: boolean;
};

export type DocumentAction = {
  type: DocumentActionType;
  viewType?: DocumentViewType;
  selectedFolderId?: string;
  documentEditor?: DocumentRequest;
  editorContent?: string;
};

export type NewsAction = {
  type: NewsActionType;
  newsDataList: NewsDataList;
};

export type GoogleSearchAction = {
  type: GoogleSearchActionType;
  searchResults: GoogleSearchResult[];
  pagingInfo: GooglePagingInfo;
};

export type SearchAction = {
  type: SearchActionType;
  isInitialPage: boolean;
  searchDetail: SearchDetail;
  searchRight: SearchRight;
};

export type MiMaterialAction = {
  type: MiMaterialActionType;
  miMaterial?: MiMaterialResponse;
};

export type MegaTrendSourceAction = {
  type: MegaTrendSourceActionType;
  megaTrendSourceList: MegaTrendSourceList;
};

export type MegaTrendCategoryYearAction = {
  type: MegaTrendCategoryYearActionType;
  categoryResult: CategoryGroup[];
  yearResult: YearGroup[];
};

export type MegaTrendAction = {
  type: MegaTrendActionType;
  megaTrendDataList: MegaTrendDataList;
};

export type MegaTrendDetailAction = {
  type: MegaTrendDetailActionType;
  moduleDetail: ModuleDetail;
};

export type BookmarkAction = {
  type: BookmarkActionType;
  showBookmark: boolean;
  folderId: number;
  isInitial: boolean;
  isAddFolder: boolean;
  isExpand: boolean;
  isAddContents: boolean;
};

export type ShareAction = {
  type: ShareActionType;
  show: boolean;
  sharedType: ShareType;
  sharedComment: string;
  sharedDetails: SharedDetail[];
  sharedMembers: MemberResponse[];
};

export type NoticeAction = {
  type: NoticeActionType;
  showNoticeDrawer?: boolean;
};
