import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ButtonGroup, Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { ActionType, DocumentActionType } from '../../reducer/actions';
import { Toast } from '../../reducer/states';
import { DocumentFolderResponse, DocumentRequest, DocumentResponse } from '../../service/document/model/Document';
import { DocumentFolderType, DocumentViewType } from '../../service/document/model/enum/Document.enum';
import DocumentEditorSection from './component/DocumentEditorSection';
import DocumentFolderSection from './component/DocumentFolderSection';
import DocumentListSection from './component/DocumentListSection';

import Delete from './component/modal/Delete';
import Move from './component/modal/Move';
import Copy from './component/modal/Copy';

import './DocumentContainer.scss';
import DocumentViewerSection from './component/DocumentViewerSection';

export interface DocumentContainerProps {
  showDocumentsDrawer: Function;
  toggleDrawerSize: Function;
  setDrawerSize: Function;
}

const DocumentContainer: React.FC<DocumentContainerProps> = (props: DocumentContainerProps): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const documentService = state.documentService;
  const [folders, setFolders] = useState<DocumentFolderResponse[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>();
  const [viewType, setViewType] = useState<string>(DocumentViewType.LIST);
  const initialDocumentEditor: DocumentRequest = { title: '', content: '' };

  const [footer, setFooter] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);
  const [showMove, setShowMove] = useState(false);
  const handleShowMove = () => setShowMove(true);
  const handleCloseMove = () => setShowMove(false);
  const [showCopy, setShowCopy] = useState(false);
  const handleShowCopy = () => setShowCopy(true);
  const handleCloseCopy = () => setShowCopy(false);
  const [showShare, setShowShare] = useState(false);
  const handleShowShare = () => setShowShare(true);
  const handleCloseShare = () => setShowShare(false);

  useEffect(() => {
    documentService.getFolders().then(response => {
      setFolders(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.document.viewType === DocumentViewType.LIST) {
      getDocuments(state.document.selectedFolderId ? state.document.selectedFolderId : DocumentFolderType.RECENT);
    } else {
      setViewType(state.document.viewType as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.document.viewType, state.document.selectedFolderId]);

  const getDocuments = async (folderId: string) => {
    await documentService.getDocuments(folderId).then(response => {
      if (response && response.successOrNot === 'Y') {
        setViewType(DocumentViewType.LIST);
        setDocuments(response.data);
        dispatch({
          type: DocumentActionType.SET_DOCUMENT_EDITOR,
          editorContent: '',
          documentEditor: {
            ...initialDocumentEditor,
            folderId: folderId,
          },
        });
      }
    });
  };

  const showList = () => {
    dispatch({
      type: DocumentActionType.SET_DOCUMENT_VIEW_LIST,
      selectedFolderId: state.document.selectedFolderId,
    });
  };

  const saveDocument = async () => {
    const toast: Toast = {
      toastMessage: '',
      showToast: true,
    };

    if (
      !state.document.documentEditor ||
      documentService.checkDocumentTitleAndContentsToSave(state.document.documentEditor, state.document.editorContent)
    ) {
      toast.toastMessage = t('document.message.saveFailForBlank');
      dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
      return;
    }

    const document = {
      documentId: state.document.documentEditor.documentId,
      folderId: state.document.documentEditor.folderId,
      title:
        state.document.documentEditor.title && state.document.documentEditor.title.trim()
          ? state.document.documentEditor.title
          : t('document.label.DocumentContainer.noTitle'),
      tags: state.document.documentEditor.tags,
      content: state.document.editorContent || '',
    };

    await documentService.saveDocument(document).then(response => {
      if (response && response.successOrNot === 'Y') {
        if (response.data.documentId) {
          dispatch({
            type: DocumentActionType.SET_DOCUMENT_EDITOR,
            editorContent: state.document.editorContent,
            documentEditor: {
              ...document,
              documentId: response.data.documentId,
            },
          });
        }

        toast.toastMessage = t('common.message.info.saveSuccess');
        dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
      } else {
        toast.toastMessage = t('document.message.saveFail');
        dispatch({ type: ActionType.SHOW_TOAST, toast: toast });
      }
    });
  };

  return (
    <div id="DocumentContainer" className={['DocumentContainer', footer ? 'checked' : ''].join(' ')}>
      <div className="document-body">
        <aside>
          <DocumentFolderSection folders={folders} setFooter={setFooter} />
        </aside>
        <section>
          <ButtonGroup>
            <Button
              className="btn-icon"
              onClick={() => {
                props.toggleDrawerSize();
              }}
            >
              <i className="ic-maximize"></i>
            </Button>
            <Button
              className="btn-icon"
              onClick={() => {
                props.showDocumentsDrawer(false);
                props.setDrawerSize(false);
              }}
            >
              <i className="ic-close"></i>
            </Button>
          </ButtonGroup>
          {viewType === DocumentViewType.LIST ? (
            <DocumentListSection
              documents={documents}
              setFooter={setFooter}
              handleShowMove={handleShowMove}
              handleShowCopy={handleShowCopy}
              handleShowDelete={handleShowDelete}
            />
          ) : viewType === DocumentViewType.VIEWER ? (
            <DocumentViewerSection setFooter={setFooter} />
          ) : (
            <DocumentEditorSection setFooter={setFooter} />
          )}
        </section>
      </div>
      <footer>
        {viewType === DocumentViewType.LIST ? (
          <div className="document-type-list document-button-group">
            {/* TODO : 차후 기능 추가시 주석 제거(이동/공유/삭제 버튼 ) 
            <Button variant="outline-dark" onClick={handleShowMove}>
              {t('document.label.DocumentContainer.move')}
            </Button>
            <Button variant="outline-dark" className="share" onClick={handleShowShare}>
              {t('document.label.DocumentContainer.share')}
            </Button>
            <Button variant="outline-dark" className="delete" onClick={handleShowDelete}>
              {t('document.label.DocumentContainer.delete')}
            </Button>
            */}
          </div>
        ) : (
          <div className="document-button-group">
            <Form.Group controlId="formBasicCheckbox">
              {/*<Form.Check type="checkbox" label={t('document.label.DocumentContainer.ALLOW_OPEN')}/> */}
            </Form.Group>
            <Button id="documentListButton" variant="outline-dark" className="list" onClick={showList}>
              {t('document.label.DocumentContainer.list')}
            </Button>
            <Button id="saveDocumentButton" variant="dark" onClick={saveDocument} className="save">
              {t('document.label.DocumentContainer.save')}
            </Button>
          </div>
        )}
      </footer>
      <Modal show={showDelete} onHide={handleCloseDelete} backdrop="static" centered dialogClassName="Delete">
        <Delete handleCloseDelete={handleCloseDelete}></Delete>
      </Modal>
      <Modal show={showMove} onHide={handleCloseMove} backdrop="static" centered dialogClassName="Move">
        <Move handleCloseMove={handleCloseMove}></Move>
      </Modal>
      <Modal show={showCopy} onHide={handleCloseCopy} backdrop="static" centered dialogClassName="Copy">
        <Copy handleCloseCopy={handleCloseCopy}></Copy>
      </Modal>
    </div>
  );
};

export default DocumentContainer;
