export enum PaginationOption {
  MAX_BUTTON = 10,
  START_PAGE = 1,
}

export enum PaginationSize {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  EXTRA_SMALL = 'xs',
}
