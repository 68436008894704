import React, { useState, useEffect, ReactElement, CSSProperties, useContext } from 'react';
import { AuthContext } from '../App';
import { TableauTicket } from '../service/tableau/model/TableauTicket';

export interface TableauIframeProps {
  url: string;
  width?: string;
  height?: string;
}

const TableauIframe: React.FC<TableauIframeProps> = (props: TableauIframeProps) => {
  const { state } = useContext(AuthContext);
  const [iframeSrc, setIframeSrc] = useState<string | undefined>();
  useEffect(() => {
    state.tableauService
      .getTableauTicket()
      .then((ticket: TableauTicket) => {
        if (ticket.ticket) {
          setIframeSrc(state.tableauService.getFullUrl(ticket.ticket, props.url));
        }
      })
      .catch(() => {
        setIframeSrc(undefined);
      });
  }, [props.url, state.tableauService]);

  const iframeStyle: CSSProperties = {
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : '100vh',
    border: 'none',
  };
  return <div className={'tableau-iframe'}>{iframeSrc && <iframe src={iframeSrc} style={iframeStyle}></iframe>}</div>;
};

export default TableauIframe;
