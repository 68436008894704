import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { FolderResponse, BookmarkCode } from '../../service/bookmark/model/Bookmark';
import { useTranslation } from 'react-i18next';
import './BookmarkFolder.scss';

export interface BookmarkFolderProps {
  folders: FolderResponse[];
  selectedFolderId: number;
  onSelectFolderId: (folderId: number) => void;
}

export const BookmarkFolder: React.FC<BookmarkFolderProps> = (props: BookmarkFolderProps) => {
  const { t } = useTranslation();
  const [folderId, setFolderId] = useState(props.selectedFolderId);

  useEffect(() => {
    setFolderId(props.selectedFolderId);
  }, [props.selectedFolderId]);

  return (
    <div id="BookmarkFolder">
      <ListGroup>
        <ListGroup.Item
          active={folderId === BookmarkCode.ALL_CONTENTS_FOLDER_ID}
          onClick={() => {
            setFolderId(BookmarkCode.ALL_CONTENTS_FOLDER_ID);
            props.onSelectFolderId(BookmarkCode.ALL_CONTENTS_FOLDER_ID);
          }}
        >
          <p>{t('bookmark.ui.allContents')}</p>
        </ListGroup.Item>
        {props.folders.map((folder, inx) => {
          return (
            <ListGroup.Item
              active={folderId === folder.bookmarkFolderId}
              key={inx}
              onClick={() => {
                setFolderId(folder.bookmarkFolderId);
                props.onSelectFolderId(folder.bookmarkFolderId);
              }}
            >
              <p>{folder.bookmarkFolderName}</p>

              {/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요 */}
              {/* <Badge>21</Badge>
              <Dropdown>
                <Dropdown.Toggle id="sideMenuOption">
                  <i></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleShowEdit}>{t('bookmark.aside.dropdown.edit')}</Dropdown.Item>
                  <Dropdown.Item>{t('bookmark.aside.dropdown.empty')}</Dropdown.Item>
                  <Dropdown.Item onClick={handleShowMove}>{t('bookmark.aside.dropdown.move')}</Dropdown.Item>
                  <Dropdown.Item onClick={handleShowDeleteFolder} className="delete">
                    {t('bookmark.aside.dropdown.delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};
export default BookmarkFolder;
