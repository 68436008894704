import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { GoogleSearchCondition } from './model/GoogleSearch';

export default class GoogleSearchService extends BaseService {
  async getResults(condition: GoogleSearchCondition): Promise<CommonResponse> {
    const method = Method.GET;
    const url = '/v1/google/search';
    const serviceName = Service.MIBE;
    const params = condition;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
}
