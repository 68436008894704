export enum YesNoFlag {
  YES_FLAG = 'Y',
  NO_FLAG = 'N',
}

export enum DateFormat {
  DATE_DISP_FORMAT = 'YYYY.MM.DD',
  DATE_IF_FORMAT = 'YYYYMMDD',
}

export enum EventType {
  CLICK = 'click',
  CHANGE = 'change',
}
