import React, { useContext, useEffect, useState } from 'react';
import { Pagination as RsuitPagination } from 'rsuite';
import { AuthContext } from '../../App';
import { SearchActionType } from '../../reducer/actions';
import { PaginationOption, PaginationSize } from '../../service/model/enum/Pagination.enum';
import './Pagination.scss';

export interface PaginationProps {
  totalCount: number;
  startPage?: number;
  countPerPage: number;
  onPageSelect: (selectedPageNum: number, countPerPage: number) => void;
  maxButton?: number;
  size?: PaginationSize;
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { dispatch, state } = useContext(AuthContext);
  const pageProperty = {
    prev: true,
    next: true,
    first: false,
    last: false,
    ellipsis: false,
    boundaryLinks: false,
  };

  const totalPages = Math.ceil(props.totalCount / props.countPerPage);
  const [selectedPage, setSelectedPage] = useState<number>(
    props.startPage ? props.startPage : PaginationOption.START_PAGE
  );

  const selectHandler = (selectNumber: number) => {
    if (state.isInitialPage) {
      dispatch({
        type: SearchActionType.SET_IS_INITIAL_PAGE,
        isInitialPage: false,
      });
    }
    setSelectedPage(selectNumber);
    props.onPageSelect(selectNumber, props.countPerPage);
  };

  /* eslint-disable */
  useEffect(() => {
    if(state.isInitialPage){
      dispatch({
        type: SearchActionType.SET_IS_INITIAL_PAGE,
        isInitialPage: false,
      });
      setSelectedPage(PaginationOption.START_PAGE);
    }
  }, [state.isInitialPage])
  /* eslint-enable */

  return (
    <div id="Pagination">
      <RsuitPagination
        {...pageProperty}
        pages={totalPages}
        maxButtons={props.maxButton ? props.maxButton : PaginationOption.MAX_BUTTON}
        activePage={selectedPage}
        onSelect={selectHandler}
        size={props.size ? props.size : PaginationSize.EXTRA_SMALL}
      />
    </div>
  );
};

export default Pagination;
