import React from 'react';
import { ListGroup, Image } from 'react-bootstrap';

import { TableauList } from '../model/Tableau';

import './CompetitionLeftMenu.scss';

import tableauIcon from '../../../icons/tableau.png';
export interface CompetitionLeftMenuProps {
  setCurrentTableau: Function;
  setMenuName: Function;
}

const CompetitionLeftMenu: React.FC<CompetitionLeftMenuProps> = (props: CompetitionLeftMenuProps) => {
  const tableauLinkHandler = (id: string, e) => {
    props.setMenuName(e.currentTarget.innerText);
    props.setCurrentTableau(id);
  };

  return (
    <div className="competitionLeftMenu">
      <ListGroup variant="flush">
        <h4>제푹 스펙 분석</h4>
        <ListGroup.Item
          action
          onClick={e => {
            tableauLinkHandler(TableauList.TABLEAU008, e);
          }}
        >
          <Image src={tableauIcon} width={18} height={18} />
          경쟁사 PRM - Mobile Device
        </ListGroup.Item>
        <ListGroup.Item
          action
          onClick={e => {
            tableauLinkHandler(TableauList.TABLEAU001, e);
          }}
        >
          <Image src={tableauIcon} width={18} height={18} />
          Mobile Device Spec Analysis
        </ListGroup.Item>
        <ListGroup.Item action>(준비중) 자동차 Spec Analysis</ListGroup.Item>
      </ListGroup>
      <ListGroup variant="flush">
        <h4>특허 동향/트렌드/성숙도</h4>
        <ListGroup.Item action>(준비중) 관련 리포트 준비중</ListGroup.Item>
      </ListGroup>
      <ListGroup variant="flush">
        <h4>신기술/스타트업 M&A정보</h4>
        <ListGroup.Item action>(준비중) 관련 리포트 준비중</ListGroup.Item>
      </ListGroup>
      <ListGroup variant="flush">
        <h4>원자재 가격/예측</h4>
        <ListGroup.Item action>(준비중) 관련 리포트 준비중</ListGroup.Item>
      </ListGroup>
      <ListGroup variant="flush">
        <h4>기타</h4>
        <ListGroup.Item action>(준비중) Open Innovation 정보</ListGroup.Item>
        <ListGroup.Item action>(준비중) LG경제연구원 리포트</ListGroup.Item>
        <ListGroup.Item action>(준비중) 전시회/컨퍼런스</ListGroup.Item>
        <ListGroup.Item action>(준비중) SCOUT 바로가기</ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default CompetitionLeftMenu;
