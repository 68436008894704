import React, { useState, useContext, useEffect } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Bookmark.scss';

import AddUrl from './modal/AddUrl';
/*TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요
import NewFolder from './modal/NewFolder';
import Edit from './modal/Edit';
import Move from './modal/Move';
import Delete from './modal/Delete';
import DeleteFolder from './modal/DeleteFolder';*/

import { AuthContext } from '../../../App';
import { ActionType, BookmarkActionType } from '../../../reducer/actions';
import { FolderResponse, BookmarkAddContentsCondtion, BookmarkCode } from '../../../service/bookmark/model/Bookmark';
import BookmarkModal from '../../bookmark/BookmarkModal';
import { Menu } from '../../../service/model/Menu';

/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요
import { ReactComponent as IconCheck } from '../../../icons/ic-check.svg';*/
import { ReactComponent as ImgNodata } from '../../../icons/ic-empty.svg';
import BookmarkFolder from '../../bookmark/BookmarkFolder';
import BookmarkContents from '../../bookmark/BookmarkContents';
export interface BookmarkProps {
  handleCloseBookmark: Function;
  menuClick: Function;
}

export const Bookmark: React.FC<BookmarkProps> = (props: BookmarkProps) => {
  const { t } = useTranslation();

  const [showAddUrl, setShowAddUrl] = useState(false);
  const handleShowAddUrl = () => setShowAddUrl(true);
  const handleCloseAddUrl = () => setShowAddUrl(false);

  const [isShowModal, setIsShowModal] = useState(false);
  const handleShowNewFolder = () => setIsShowModal(true);
  const handleOnHideModal = () => setIsShowModal(false);

  /* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요
  const [showEdit, setShowEdit] = useState(false);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);

  const [showMove, setShowMove] = useState(false);
  const handleShowMove = () => setShowMove(true);
  const handleCloseMove = () => setShowMove(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);

  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const handleShowDeleteFolder = () => setShowDeleteFolder(true);
  const handleCloseDeleteFolder = () => setShowDeleteFolder(false);*/

  const { dispatch, state } = useContext(AuthContext);
  const [folders, setFolders] = useState<FolderResponse[]>([]);
  const [contentsList, setContentsList] = useState<BookmarkAddContentsCondtion[]>([]);
  const [folderId, setFolderId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* eslint-disable */
  useEffect(() => {
    (async () => {
      const response = await state.bookmarkService.retrieveFolderList();
      if (response) {
        let resultFolderList: FolderResponse[] = [];
        response.forEach(elem => {
          resultFolderList.push(elem);
        });
        setFolders(resultFolderList);

        if (state.bookmark.isAddFolder) {
          dispatch({
            type: BookmarkActionType.ADD_FOLDER,
            isAddFolder: false,
          });
        }
        if (state.bookmark.isInitial) {
          setFolderId(BookmarkCode.ALL_CONTENTS_FOLDER_ID);
          dispatch({
            type: BookmarkActionType.SET_IS_INITIAL,
            isInitial: false,
          });
        } else {

          setFolderId(state.bookmark.isExpand ? state.bookmark.folderId : folderId);
        }
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    })();
  }, [state.bookmark.isAddFolder]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    if (!state.bookmark.isExpand) {
      getContentsList(folderId);
    }
  }, [folderId]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    if (state.bookmark.isExpand) {
      if (folderId !== state.bookmark.folderId) {
        setFolderId(state.bookmark.folderId);
      } else {
        getContentsList(folderId);
      }
      dispatch({
        type: BookmarkActionType.EXPAND_BOOKMARK,
        isExpand: false,
        folderId: state.bookmark.folderId,
      });
    }
  }, [state.bookmark.isExpand]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    if (state.bookmark.isAddContents) {
      getContentsList(folderId);
      dispatch({
        type: BookmarkActionType.ADD_CONTENTS,
        isAddContents: false,
      });
    }
  }, [state.bookmark.isAddContents]);
  /* eslint-enable */

  const handleAddFolder = (): void => {
    dispatch({
      type: BookmarkActionType.ADD_FOLDER,
      isAddFolder: true,
    });
  };

  const getContentsList = async (folderId: number) => {
    await state.bookmarkService.retrieveContentList(folderId).then(response => {
      if (response) {
        const resultContentsList: BookmarkAddContentsCondtion[] = [];
        response.forEach(elem => {
          resultContentsList.push(elem);
        });
        setContentsList(resultContentsList);
        if (!isLoading) {
          setIsLoading(true);
        }
      } else {
        dispatch({
          type: ActionType.SHOW_TOAST,
          toast: {
            showToast: true,
            toastMessage: t('bookmark.message.UE'),
          },
        });
      }
    });
  };

  const handleSuccessAddUrl = (addedFolderId: number) => {
    dispatch({
      type: BookmarkActionType.EXPAND_BOOKMARK,
      folderId: addedFolderId,
      isExpand: true,
    });
  };

  const expandBookmark = () => {
    props.menuClick(false);
    dispatch({
      type: BookmarkActionType.SHOW_BOOKMARK,
      showBookmark: false,
    });
    dispatch({
      type: BookmarkActionType.EXPAND_BOOKMARK,
      folderId: folderId,
      isExpand: true,
    });
    dispatch({
      type: ActionType.CHANGE_MENU,
      menuLv2: Menu.BOOKMARK,
    });
  };

  return (
    <div id="bookmark">
      <header>
        <h3>{t('bookmark.header.title')}</h3>
        <ButtonGroup>
          <Button id="expandBookmark" className="btn-icon" onClick={expandBookmark}>
            <i className="ic-layout"></i>
          </Button>
          <Button
            className="btn-icon"
            onClick={() => {
              props.handleCloseBookmark(true);
            }}
          >
            <i className="ic-close"></i>
          </Button>
        </ButtonGroup>
      </header>
      <div className="body">
        <aside className="folderArea">
          <header>
            <h3>북마크</h3>
          </header>
          <ButtonGroup>
            <Button id="addUrl" variant="primary" onClick={handleShowAddUrl}>
              {t('bookmark.aside.addUrl')}
            </Button>
            <Button variant="secondary" onClick={handleShowNewFolder}>
              {t('bookmark.aside.newFolder')}
            </Button>
          </ButtonGroup>
          <BookmarkFolder
            folders={folders}
            selectedFolderId={folderId}
            onSelectFolderId={clickedFolderId => {
              if (folderId !== clickedFolderId) {
                setFolderId(clickedFolderId);
              } else {
                getContentsList(clickedFolderId);
              }
            }}
          />
        </aside>
        {isLoading ? (
          contentsList.length > 0 ? (
            <section>
              <BookmarkContents contentsList={contentsList} />
              <article>{t('bookmark.section.article.instrucion')}</article>
            </section>
          ) : (
            <section>
              <div className="noDataContent">
                <ImgNodata />
                {t('bookmark.noData')}
              </div>
            </section>
          )
        ) : (
          ''
        )}
      </div>
      <footer>
        <div>
          {/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요 */}
          {/* <ButtonGroup>
            <Button variant="secondary" className="ic-check">
              <IconCheck />
              {t('bookmark.footer.checkAll')}
            </Button>
            <Button variant="secondary">{t('bookmark.footer.uncheck')}</Button>
          </ButtonGroup>
          <ButtonGroup className="right">
            <Button variant="secondary" onClick={handleShowMove}>
              {t('bookmark.footer.move')}
            </Button>
            <Button variant="secondary" className="ic-share">
              <IconShare />
              {t('bookmark.footer.share')}
            </Button>
            <Button variant="secondary" className="ic-delete" onClick={handleShowDelete}>
              {t('bookmark.footer.delete')}
            </Button>
          </ButtonGroup> */}
        </div>
      </footer>
      <Modal show={showAddUrl} onHide={handleCloseAddUrl} backdrop="static" centered dialogClassName="AddUrl">
        <AddUrl handleCloseAddUrl={handleCloseAddUrl} folderId={folderId} onSuccess={handleSuccessAddUrl}></AddUrl>
      </Modal>
      <BookmarkModal
        folders={folders}
        onAdd={handleAddFolder}
        onHide={handleOnHideModal}
        show={isShowModal}
      ></BookmarkModal>
      {/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요 */}
      {/* <Modal show={showNewFolder} onHide={handleCloseNewFolder} backdrop="static" centered dialogClassName="NewFolder">
        <NewFolder handleCloseNewFolder={handleCloseNewFolder}></NewFolder>
      </Modal> 
      <Modal show={showEdit} onHide={handleCloseEdit} backdrop="static" centered dialogClassName="Edit">
        <Edit handleCloseEdit={handleCloseEdit}></Edit>
      </Modal>
      <Modal show={showMove} onHide={handleCloseMove} backdrop="static" centered dialogClassName="Move">
        <Move handleCloseMove={handleCloseMove}></Move>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} backdrop="static" centered dialogClassName="Delete">
        <Delete handleCloseDelete={handleCloseDelete}></Delete>
      </Modal>
      <Modal
        show={showDeleteFolder}
        onHide={handleCloseDeleteFolder}
        backdrop="static"
        centered
        dialogClassName="Delete"
      >
        <DeleteFolder handleCloseDeleteFolder={handleCloseDeleteFolder}></DeleteFolder>
      </Modal>*/}
    </div>
  );
};
export default Bookmark;
