import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Delete.scss';

export interface DeleteProps {
  handleCloseDelete: Function;
}

export const Delete: React.FC<DeleteProps> = (props: DeleteProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('document.modal.delete.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{t('document.modal.delete.text')}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button>{t('document.modal.delete.cancel')}</Button>
        <Button>{t('document.modal.delete.confirm')}</Button>
      </Modal.Footer>
    </>
  );
};
export default Delete;
