import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import React, { createRef, ReactElement, useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import { DocumentActionType } from '../../../reducer/actions';
import './DocumentViewerSection.scss';

interface Props {
  setFooter: Function;
}

const editorRef = createRef<Editor>();

const DocumentViewerSection: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (state.document.documentEditor && !state.document.editorContent) {
      editorRef.current?.getInstance().setHtml('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.document.editorContent]);

  const handleChangeInput = e => {
    dispatch({
      type: DocumentActionType.SET_DOCUMENT_EDITOR,
      editorContent: state.document.editorContent,
      documentEditor: {
        ...state.document.documentEditor,
        [e.target.name]: e.target.value,
      },
    });
    if (e.target.value.length == 0) {
      props.setFooter(false);
    } else {
      props.setFooter(true);
    }
  };

  const handleChangeEditor = () => {
    dispatch({
      type: DocumentActionType.SET_DOCUMENT_EDITOR_CONTENT,
      editorContent: getEditorContent(),
    });
  };

  const getEditorContent = (): string => {
    let content = '';
    if (editorRef.current && editorRef.current.getInstance().isWysiwygMode()) {
      const editor = editorRef.current?.getInstance().getCurrentModeEditor() as toastui.WysiwygEditor;
      const editorHtml = editor.getValue();
      if (editorHtml !== '<br>') {
        content = editorHtml;
      }
    }
    if (content.length == 0) {
      props.setFooter(false);
    } else {
      props.setFooter(true);
    }
    return content;
  };

  return (
    <div id="DocumentViewerSection" className="DocumentViewerSection">
      <Form>
        <Form.Control
          id="editorTitle"
          name="title"
          type="text"
          placeholder={t('document.message.inputTitle')}
          value={state.document.documentEditor?.title}
          onChange={handleChangeInput}
        />
        <div className="document-content-area">
          {state.document.documentEditor && state.document.documentEditor.content && (
            <div className="content" dangerouslySetInnerHTML={{ __html: state.document.documentEditor.content }} />
          )}
        </div>
      </Form>
    </div>
  );
};

export default DocumentViewerSection;
