import React, { useContext, useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { AuthContext } from '../../../App';
import { MegaTrendActionType, MegaTrendSourceActionType } from '../../../reducer/actions';
import { DomesticOverseasCode, SourceCode } from '../../../service/megaTrend/model/enum/MegaTrend.enum';

import './LeftMenu.scss';

export interface LeftMenuProps {
  setSource: Function;
  setDomesticOverseas: Function;
  setIsMenuClicked: Function;
  isMenuClicked: boolean;
  source: string;
}

const LeftMenu: React.FC<LeftMenuProps> = (props: LeftMenuProps) => {
  const { dispatch, state } = useContext(AuthContext);

  useEffect(() => {
    searchSourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchSourceList = async (): Promise<void> => {
    const response = await state.megaTrendService.searchSourceList();

    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: MegaTrendSourceActionType.SET_MEGA_TREND_SOURCE_LIST,
        megaTrendSourceList: response.data,
      });
      if (response.data && response.data.total && response.data.total.value > 0) {
        props.setSource(SourceCode.OVERSEAS);
        props.setDomesticOverseas(DomesticOverseasCode.OVERSEAS);
        props.setIsMenuClicked(true);
      }
    } else {
      dispatch({
        type: MegaTrendSourceActionType.SET_MEGA_TREND_SOURCE_LIST,
        /* eslint-disable */
        megaTrendSourceList: {
          timed_out: false,
          total: {
            value: 0,
            relation: '',
          },
          max_score: 0,
          items: [],
        },
        /* eslint-enable */
      });
    }
  };

  return (
    <div id="leftMenu" className="leftMenu">
      {state.megaTrendSource.total.value > 0 && (
        <>
          {state.megaTrendSource.items.map(item => {
            return (
              <ListGroup key={item.domesticOverseas}>
                <h4>{item.domesticOverseas}</h4>
                {item.items.map(unit => {
                  return (
                    <ListGroup.Item
                      key={unit.source}
                      className={`flex cursor_pointer ${props.source === unit.source ? 'active' : ''}`}
                      action
                      onClick={() => {
                        props.setSource(unit.source);
                        props.setDomesticOverseas(item.domesticOverseas);
                        props.setIsMenuClicked(true);
                      }}
                    >
                      {unit.source}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            );
          })}
        </>
      )}
    </div>
  );
};

export default LeftMenu;
