import moment from 'moment';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import { String } from 'typescript-string-operations';
import { AuthContext } from '../../App';
import useWindowDimensions from '../../layouts/WindowDimensions';
import { SearchActionType, ShareActionType } from '../../reducer/actions';
import { DateFormat } from '../../service/model/enum/Common.enum';
import { SearchDetailDefaultProps } from '../../service/search/model/enum/Search.enum';
import { SearchConstants, SearchDetailCopyInfo } from '../../service/search/model/SearchDetail';
import { ShareType } from '../../service/share/model/enum/Share.enum';
import BookmarkContainer from '../bookmark/BookmarkContainer';
import './SearchDetail.scss';

const SearchDetail: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { state, dispatch } = useContext(AuthContext);
  const [overlayRootClose, setOverlayRootClose] = useState<boolean>(true);
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const rndRef = createRef<Rnd>();
  const divRef = createRef<HTMLDivElement>();

  const calWidth = (): number => {
    let result: number = SearchDetailDefaultProps.width;
    const autoSize: number = Math.round(width / 2);
    if (autoSize > result) {
      result = autoSize;
    }
    return result;
  };

  const calInitXaxios = (): number => {
    return width - calWidth();
  };

  const defaultModalProps = {
    x: calInitXaxios(),
    y: SearchDetailDefaultProps.top,
    width: calWidth(),
    height: window.innerHeight - SearchDetailDefaultProps.top,
  };

  const contentsInfo: SearchDetailCopyInfo | undefined = state.searchDetail.searchData
    ? state.searchService.getDetailContentCopyInfo(state.searchDetail.searchData.crawlData)
    : undefined;

  const copyHandler = (contentsInfo: SearchDetailCopyInfo) => {
    const selection: Selection | null = window.getSelection();

    const bodyElement = document.getElementsByTagName(SearchConstants.BODY_STR)[0];

    //if the selection is short let's not annoy our users
    if (!contentsInfo) return;
    if (('' + selection).length < 30) return;
    if (selection === null) return;

    const decodedUrl: string = decodeURI(contentsInfo.url);

    //create a div outside of the visible area
    const newdiv = document.createElement(SearchConstants.DIV_STR);
    newdiv.style.position = SearchConstants.ABSOLUTE_POSITION;
    newdiv.style.left = SearchConstants.LEFT_HIDE;
    bodyElement.appendChild(newdiv);
    newdiv.appendChild(selection.getRangeAt(0).cloneContents());

    //we need a <pre> tag workaround
    //otherwise the text inside "pre" loses all the line breaks!
    if (selection.getRangeAt(0).commonAncestorContainer.nodeName == SearchConstants.PRE_STR) {
      newdiv.innerHTML = String.Format(SearchConstants.PRE_TAG_STR, newdiv.innerHTML);
    }

    newdiv.innerHTML +=
      SearchConstants.BR_TAG_STR +
      SearchConstants.BR_TAG_STR +
      String.Format(SearchConstants.A_TAG_STR, decodedUrl, contentsInfo.title);
    selection.selectAllChildren(newdiv);

    window.setTimeout(function() {
      bodyElement.removeChild(newdiv);
    }, 200);
  };

  useEffect(() => {
    divRef.current?.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchDetail.searchData]);

  useEffect(() => {
    if (!state.searchDetail.isDetailOpen) {
      setIsMaximized(false);
      if (rndRef.current) {
        rndRef.current.updateSize({ width: defaultModalProps.width, height: defaultModalProps.height });
        rndRef.current.updatePosition({ x: defaultModalProps.x, y: defaultModalProps.y });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchDetail.isDetailOpen]);

  useEffect(() => {
    if (rndRef.current) {
      rndRef.current.updateSize({ width: defaultModalProps.width, height: defaultModalProps.height });
      rndRef.current.updatePosition({ x: defaultModalProps.x, y: defaultModalProps.y });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultModalProps.height,
    defaultModalProps.width,
    defaultModalProps.x,
    defaultModalProps.y,
    rndRef.current,
    width,
  ]);

  const handleOverlayShow = (rootClose: boolean) => {
    setOverlayRootClose(rootClose);
  };

  const toggleMaximized = () => {
    setIsMaximized(!isMaximized);
  };

  const hideDetail = () => {
    dispatch({
      type: SearchActionType.SHOW_SEARCH_DETAIL,
      searchDetail: { isDetailOpen: false },
    });
  };
  const getDetailContent = (): string => {
    let content = '';
    if (state.searchDetail.searchData) {
      content = state.searchDetail.searchData.content;

      /* TODO: htmlContent가 None일 때 임시 처리, 추후 수정 필요 */
      if (state.searchDetail.searchData.crawlData && state.searchDetail.searchData.crawlData.htmlContent !== 'None') {
        content = state.searchDetail.searchData.crawlData.htmlContent;
      }
    }
    return content;
  };

  const getDisplayDate = (date: string | undefined): string => {
    return date ? moment(date).format(DateFormat.DATE_DISP_FORMAT) : '';
  };

  const openOriginalUrl = (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      return false;
    }
  };

  const handleShowShareModal = () => {
    dispatch({
      type: ShareActionType.SHOW_SHARE_MODAL,
      show: true,
      sharedType: ShareType.NEWS,
      sharedDetails: [
        {
          contentId: state.searchDetail.searchData?.crawlData?.crawlDataId,
          title: state.searchDetail.searchData?.title,
          source: state.searchDetail.searchData?.source,
        },
      ],
    });
  };

  return (
    <Rnd
      ref={rndRef}
      className={`SearchDetail ${isMaximized ? 'maximize' : ''} ${
        state.searchDetail.isDetailOpen ? 'detail-show' : 'detail-hide'
      }`}
      dragAxis="x"
      default={defaultModalProps}
      minWidth={SearchDetailDefaultProps.width}
      maxWidth="100%"
      bounds="window"
      dragHandleClassName={'detail-header'}
      disableDragging={isMaximized}
      enableResizing={isMaximized ? false : { left: true, right: true }}
      resizeHandleClasses={{ left: 'resize-left-handler', right: 'resize-right-handler' }}
    >
      {state.searchDetail.searchData && (
        <div className={'vscroll flex flex-one vbox bottom'} ref={divRef}>
          <div className={'detail-header flex'}>
            <div className={'detail-header-left flex hbox'}>
              <div className={'original-btn-area'}>
                <button
                  className={'original-btn'}
                  onClick={() => {
                    openOriginalUrl(state.searchDetail.searchData?.crawlData?.url);
                  }}
                >
                  {t('search.label.SearchDetail.linkToOriginal')}
                </button>
              </div>
              <div className={'full-screen-btn-area'}>
                <Button className="btn-icon" onClick={toggleMaximized}>
                  <i className={isMaximized ? 'ic-minimize' : 'ic-maximize'}></i>
                </Button>
              </div>
              <OverlayTrigger
                trigger="click"
                rootClose={overlayRootClose}
                placement="bottom"
                overlay={
                  <Popover id="popoverBookmarkContainer">
                    {/* TODO: 임시로 crawlData가 있는 뉴스/리포트 영역만 북마크 보이도록 처리함 @Sprint4 */}
                    {state.searchDetail.searchData.crawlData && (
                      <BookmarkContainer
                        searchData={state.searchDetail.searchData}
                        crawlData={state.searchDetail.searchData.crawlData}
                        onOverlayRootClose={handleOverlayShow}
                      />
                    )}
                  </Popover>
                }
              >
                <div className={'bookmark-area'}></div>
              </OverlayTrigger>
              {/* TODO: 임시로 crawlData가 있는 뉴스/리포트 영역만 공유하기 보이도록 처리함 @Sprint6 */}
              {state.searchDetail.searchData.crawlData && (
                <div className={'share-area cursor_pointer'} onClick={handleShowShareModal}></div>
              )}
            </div>
            <div className={'detail-header-right flex hbox'}>
              <div className={'comment-area'}>{'7'}</div>
              <div className={'close-area cursor_pointer'} onClick={hideDetail}></div>
            </div>
          </div>
          <div className={'detail-body flex flex-one'}>
            <div className={'detail-body-top flex flex-one vbox'}>
              <div className={'detail-body-title flex'}>{state.searchDetail.searchData.title}</div>
              <div className={'detail-body-info flex hbox'}>
                <div className={'date'}>{getDisplayDate(state.searchDetail.searchData.date)}</div>
                <div className={'author'}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* TODO: 작성자가 None일 때 임시 처리, 추후 수정 필요 */}
                  {state.searchDetail.searchData.source !== 'None' && state.searchDetail.searchData.source}
                </div>
              </div>
              {/* TODO: tag 관련 정의 후 적용 필요 */}
              {/* <div className={'detail-body-tag-list flex hbox'}>
                <div className={'tag'}>{'#전기차'}</div>
                <div className={'tag'}>{'#2020'}</div>
                <div className={'tag'}>{'#트랜드'}</div>
                <div className={'tag'}>{'#고객조사'}</div>
                <div className={'tag'}>{'#Gartner'}</div>
              </div> */}
              <div className={'detail-body-content flex vbox'}>
                {/* TODO: 첨부파일 관련 정의 후 적용 필요 */}
                {/* <div className={'file-area flex'}>
                  <div className={'file-info flex vbox'}>
                    <div className={'name'}>{'전기자동차 2020 상품 트랜드.pptx'}</div>
                    <div className={'capacity'}>{'12MB PowerPoint File'}</div>
                  </div>
                </div> */}
                <div
                  id={'search-content'}
                  className={'content-area flex'}
                  onCopy={e => {
                    contentsInfo && copyHandler(contentsInfo);
                  }}
                  dangerouslySetInnerHTML={{ __html: getDetailContent() }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Rnd>
  );
};

export default SearchDetail;
