import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { SearchActionType } from '../../reducer/actions';
import { SearchMenuType } from '../../service/search/model/enum/Search.enum';
import './SearchLeft.scss';

export interface SearchLeftProps {
  leftMenu: number;
  setLeftMenu: React.Dispatch<number>;
  keyword: string;
}

const SearchLeft: React.FC<SearchLeftProps> = (props: SearchLeftProps) => {
  const { dispatch, state } = useContext(AuthContext);
  const { t } = useTranslation();
  const { leftMenu, setLeftMenu } = props;

  useEffect(() => {
    if (leftMenu !== SearchMenuType.UNIFIED) {
      setLeftMenu(SearchMenuType.UNIFIED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyword, state.search.isEnter]);

  const onClick = menu => () => {
    dispatch({
      type: SearchActionType.SET_IS_INITIAL_PAGE,
      isInitialPage: true,
    });

    setLeftMenu(menu);
  };

  return (
    <div className={'SearchLeft flex'}>
      <div className={'list flex vbox search-left-list'}>
        <div
          id="unifiedBtn"
          className={`flex cursor_pointer search-left-text ${leftMenu === SearchMenuType.UNIFIED ? 'active' : ''}`}
          onClick={onClick(SearchMenuType.UNIFIED)}
        >
          {t('search.label.category.unifiedSearch')}
        </div>
        <div
          id="miMaterialBtn"
          className={`flex cursor_pointer search-left-text ${leftMenu === SearchMenuType.MI_MATERIAL ? 'active' : ''}`}
          onClick={onClick(SearchMenuType.MI_MATERIAL)}
        >
          {t('search.label.category.miMaterial')}
        </div>
        <div
          id="newsAndReportBtn"
          className={`flex cursor_pointer search-left-text ${leftMenu === SearchMenuType.NEWS_REPORT ? 'active' : ''}`}
          onClick={onClick(SearchMenuType.NEWS_REPORT)}
        >
          {t('search.label.category.newsAndReport')}
        </div>
        {/* <div className={`flex cursor_pointer search-left-text ${leftMenu === 3 ? 'active' : ''}`} onClick={onClick(3)}>{t('search.label.category.image')}</div>
        <div className={`flex cursor_pointer search-left-text ${leftMenu === 4 ? 'active' : ''}`} onClick={onClick(4)}>{t('search.label.category.processMgmt')}</div>
        <div className={`flex cursor_pointer search-left-text ${leftMenu === 5 ? 'active' : ''}`} onClick={onClick(5)}>{t('search.label.category.sharedDoc')}</div>
        <div className={`flex cursor_pointer search-left-text scout ${leftMenu === 6 ? 'active' : ''}`} onClick={onClick(6)}>
          <div className={'circle'}></div>
          <div className={'scout-word'}>{t('search.label.category.scoutSearch')}</div>
        </div> */}
        <div
          id="googleSearchBtn"
          className={`flex cursor_pointer search-left-text google-search ${
            leftMenu === SearchMenuType.GOOGLE ? 'active' : ''
          }`}
          onClick={onClick(SearchMenuType.GOOGLE)}
        >
          {t('search.label.category.search')}
        </div>
      </div>
    </div>
  );
};

export default SearchLeft;
