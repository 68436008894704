import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import './BookmarkModal.scss';
import { ActionType } from '../../reducer/actions';
import { BookmarkStatusCode, FolderResponse } from '../../service/bookmark/model/Bookmark';
import { ReactComponent as IconCheck } from '../../icons/ic-check.svg';
export interface BookmarkModalProps {
  show: boolean;
  folders: FolderResponse[];
  onHide: () => void;
  onAdd: () => void;
}

const BookmarkModal: React.FC<BookmarkModalProps> = (props: BookmarkModalProps) => {
  const { dispatch, state } = useContext(AuthContext);
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(props.show);
  const schema = yup.object().shape({
    bookmarkFolderName: yup.string().required(t('bookmark.message.required.folderName')),
  });

  /* eslint-disable */
  useEffect(() => {
    setShow(props.show);
  }, [props]);
  /* eslint-enable */

  const handleClose = (): void => {
    props.onHide();
    setShow(false);
  };

  return (
    <Modal id="BookmarkModal" centered backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header className="BookmarkModal-header" closeButton>
        <Modal.Title>{t('bookmark.ui.modalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="BookmarkModal-body">
        <Formik
          initialValues={{
            bookmarkFolderName: '',
          }}
          onSubmit={(inputData, { setSubmitting }): void => {
            (async (): Promise<void> => {
              const response = await state.bookmarkService.addBookmarkFolder(inputData.bookmarkFolderName.trim());
              if (response) {
                switch (response.statusCode) {
                  case BookmarkStatusCode.SUCCESS:
                    dispatch({
                      type: ActionType.SHOW_TOAST,
                      toastMessage: t('common.message.info.saveSuccess'),
                    });
                    props.onAdd();
                    break;
                  case BookmarkStatusCode.DUPLICATE_FOLDER_NAME:
                    dispatch({
                      type: ActionType.SHOW_TOAST,
                      toast: {
                        showToast: true,
                        toastMessage: t('bookmark.message.DUPLICATE_FOLDERNAME'),
                      },
                    });
                    break;
                  default:
                    break;
                }
              } else {
                dispatch({
                  type: ActionType.SHOW_TOAST,
                  toast: {
                    showToast: true,
                    toastMessage: t('bookmark.message.UE'),
                  },
                });
              }
            })();
            props.onHide();
          }}
          validationSchema={schema}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  id="bookmarkFolderName"
                  name="bookmarkFolderName"
                  type="text"
                  placeholder={t('bookmark.ui.modalPlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={values.bookmarkFolderName}
                  isInvalid={!!errors.bookmarkFolderName}
                />
                <Form.Control.Feedback id="folderTitleFeedback" data-testid="folderTitleFeedback" type="invalid">
                  {!!errors.bookmarkFolderName && touched.bookmarkFolderName ? errors.bookmarkFolderName : ''}
                </Form.Control.Feedback>
              </Form.Group>

              <Button className="BookmarkModal-body-btn" type="submit" variant="primary" disabled={isSubmitting}>
                <IconCheck />
                {t('bookmark.ui.modalSaveBtn')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="BookmarkModal-footer"></Modal.Footer>
    </Modal>
  );
};

export default BookmarkModal;
