import React, { useState, useContext, ReactElement } from 'react';

export interface SamplePageProps {} // eslint-disable-line

const SamplePage: React.FC<SamplePageProps> = (props: SamplePageProps) => {
  return (
    <div>
      Sample Page
      <iframe
        src="//www.gapminder.org/tools/?embedded=true#$chart-type=bubbles"
        allowFullScreen
        style={{ width: '100%', height: '500px', margin: '0 0 0 0', border: '1px solid grey' }}
      ></iframe>
      <iframe
        src="//v-portal-ui.vportaldev.koreabuild.com/login"
        allowFullScreen
        style={{ width: '100%', height: '500px', margin: '0 0 0 0', border: '1px solid grey' }}
      ></iframe>
    </div>
  );
};

export default SamplePage;
