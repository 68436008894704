import React, { useContext } from 'react';
import { Button, ButtonGroup, ListGroup, Figure, Container } from 'react-bootstrap';
import { BookmarkAddContentsCondtion, BookmarkContentsTypeCode } from '../../service/bookmark/model/Bookmark';
import { ReactComponent as IconBookmark } from '../../icons/ic-bookmark-line.svg';
import { ReactComponent as IconComment } from '../../icons/ic-comment.svg';
import { ReactComponent as IconShare } from '../../icons/ic-share.svg';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'rsuite';
import './BookmarkContents.scss';
import { ShareActionType } from '../../reducer/actions';
import { ShareType } from '../../service/share/model/enum/Share.enum';
import { AuthContext } from '../../App';

export interface BookmarkContentsProps {
  contentsList: BookmarkAddContentsCondtion[];
}

export const BookmarkContents: React.FC<BookmarkContentsProps> = (props: BookmarkContentsProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const handleShowShareModal = (
    contentId: string | undefined,
    title: string | undefined,
    source: string | undefined
  ): void => {
    dispatch({
      type: ShareActionType.SHOW_SHARE_MODAL,
      show: true,
      sharedType: ShareType.BOOKMARK,
      sharedDetails: [
        {
          contentId: contentId,
          title: title,
          source: source,
        },
      ],
    });
  };

  const retrieveBookmarkImage = (item: BookmarkAddContentsCondtion) => {
    if (item.bookmarkContentsTypeCode === BookmarkContentsTypeCode.CT_ARTICLE) {
      return (
        <Figure>
          <Figure.Image alt="bookmark sample image" src={require('../../icons/sample-bookmark.png')} />
          <Figure.Caption>bookmark sample image</Figure.Caption>
        </Figure>
      );
    } else if (item.bookmarkContentsTypeCode === BookmarkContentsTypeCode.CT_URL) {
      if (item.thumbnail) {
        return (
          <Figure>
            <Figure.Image alt="bookmark image" src={item.thumbnail} />
            <Figure.Caption>{item.title}</Figure.Caption>
          </Figure>
        );
      } else {
        return (
          <Figure>
            <Figure.Image alt="bookmark sample image" src={require('../../icons/sample-bookmark.png')} />
            <Figure.Caption>bookmark sample image</Figure.Caption>
          </Figure>
        );
      }
    }
  };

  return (
    <div id="BookmarkContents">
      <div>
        <ListGroup>
          {props.contentsList.map((contents, inx) => {
            if (contents.bookmarkContentsTypeCode === BookmarkContentsTypeCode.CT_ARTICLE) {
              return (
                <ListGroup.Item key={inx}>
                  <Checkbox></Checkbox>
                  {retrieveBookmarkImage(contents)}
                  <div className="content">
                    <h4>{contents.title}</h4>
                    <p>{contents.detailContents}</p>
                    <address>{contents.source ? contents.source : ''}</address>
                  </div>
                  {/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요 */}
                  <ButtonGroup>
                    {/* <Button variant="secondary" className="btn-icon ic-bookmark">
                    <IconBookmark />
                    21
                  </Button>
                  <Button variant="secondary" className="btn-icon ic-comment">
                    <IconComment />3 
                  </Button>  */}
                    <Button
                      variant="secondary"
                      className="btn-icon ic-share"
                      onClick={() => handleShowShareModal(contents.contentsId, contents.title, contents.source)}
                    >
                      <IconShare />
                    </Button>
                    {/* <Button variant="secondary" className="btn-icon ic-delete"></Button>  */}
                  </ButtonGroup>
                </ListGroup.Item>
              );
            } else if (contents.bookmarkContentsTypeCode === BookmarkContentsTypeCode.CT_URL) {
              return (
                <ListGroup.Item key={inx} className="ct-url">
                  <Checkbox></Checkbox>
                  {retrieveBookmarkImage(contents)}
                  <div
                    className="content"
                    onClick={() => {
                      window.open(contents.detailContents, '_blank');
                    }}
                  >
                    <h4>{contents.title ? contents.title : contents.detailContents}</h4>
                    <p>{contents.detailContents}</p>
                    <i>{t('bookmark.ui.externalUrl')}</i>
                  </div>
                  {/* TODO: 기능 미구현으로 주석처리 추후 기능 구현 시 원복 필요 */}
                  <ButtonGroup>
                    {/* <Button variant="secondary" className="btn-icon ic-bookmark">
                    <IconBookmark />
                    21
                  </Button>
                  <Button variant="secondary" className="btn-icon ic-comment">
                    <IconComment />3 
                  </Button>  */}
                    <Button
                      variant="secondary"
                      className="btn-icon ic-share"
                      onClick={() => handleShowShareModal(contents.contentsId, contents.title, contents.source)}
                    >
                      <IconShare />
                    </Button>
                    {/* <Button variant="secondary" className="btn-icon ic-delete"></Button>  */}
                  </ButtonGroup>
                </ListGroup.Item>
              );
            }
          })}
        </ListGroup>
      </div>
    </div>
  );
};
export default BookmarkContents;
