import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { Notice } from './model/Notice';

export default class NoticeService extends BaseService {
  async retrieveSharedHistoryList(): Promise<Notice[]> {
    const url = '/v1/share/notice/history';
    const serviceName = Service.MIBE;
    const method = Method.GET;
    const params = null;
    const body = null;
    let response;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }
}
