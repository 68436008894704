import React, { useContext, useEffect, useState } from 'react';
import { Image, ButtonGroup, Button, Form, ListGroup, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import './CardA.scss';
import RedBullLogo from '../../../icons/logo-redbull.svg';
import {
  ImageArray,
  MegaTrendCategoryYearCondtion,
  MegaTrendData,
  MegaTrendListSearchCondtion,
} from '../../../service/megaTrend/model/MegaTrend';
import {
  MegaTrendActionType,
  MegaTrendCategoryYearActionType,
  MegaTrendDetailActionType,
} from '../../../reducer/actions';
import { CategoryCode, SearchCondition } from '../../../service/megaTrend/model/enum/MegaTrend.enum';

export interface CardAProps {
  showModuleDetail: Function;
  domesticOverseas: string;
  source?: string;
  setIsMenuClicked: Function;
  isMenuClicked: boolean;
  setCardContents: Function;
}

const CardA: React.FC<CardAProps> = (props: CardAProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);
  const [category, setCategory] = useState<string>('');
  const [year, setYear] = useState<string>('');

  useEffect(() => {
    if (props.isMenuClicked) {
      getCategoryYearList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMenuClicked]);

  const clickCategory = (category: string) => {
    setCategory(category);
    searchMegaTrendList(SearchCondition.START_PAGE_NUMBER, SearchCondition.DEFAULT_PAGE_COUNT, category, year);
  };

  const setYearHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setYear(event.target.value);
    }
    searchMegaTrendList(
      SearchCondition.START_PAGE_NUMBER,
      SearchCondition.DEFAULT_PAGE_COUNT,
      category,
      event.target.value
    );
  };

  const getImgSrc = (category: string, inx: number) => {
    let result: any;

    switch (category) {
      case CategoryCode.ECONOMIC:
        result = ImageArray.Economic[inx];
        break;
      case CategoryCode.ENVIRONMENT:
        result = ImageArray.Environment[inx];
        break;
      case CategoryCode.POLITICAL:
        result = ImageArray.Political[inx];
        break;
      case CategoryCode.SOCIAL:
        result = ImageArray.Social[inx];
        break;
      case CategoryCode.TECHNOLOGICAL:
        result = ImageArray.Technological[inx];
        break;
      default:
        result = ImageArray.SampleImage[0];
        break;
    }
    return result;
  };

  const searchMegaTrendList = async (pageNum: number, counterPerPage: number, category: string, year: string) => {
    props.setIsMenuClicked(false);

    const condition: MegaTrendListSearchCondtion = {
      size: counterPerPage,
      page: pageNum,
      domesticOverseas: props.domesticOverseas,
      source: props.source,
      category: category,
      year: year,
    };

    const response = await state.megaTrendService.searchMegaTrendList(condition);

    if (response && response.successOrNot === 'Y') {
      dispatch({
        type: MegaTrendActionType.SET_MEGA_TREND_RESULT_LIST,
        megaTrendDataList: response.data,
      });
    } else {
      dispatch({
        type: MegaTrendActionType.SET_MEGA_TREND_RESULT_LIST,
        /* eslint-disable */
        megaTrendDataList: {
          timed_out: false,
          total: {
            value: 0,
            relation: '',
          },
          max_score: 0,
          items: [],
        },
        /* eslint-enable */
      });
    }
  };

  const getCategoryYearList = async (): Promise<void> => {
    const condition: MegaTrendCategoryYearCondtion = {
      domesticOverseas: props.domesticOverseas,
      source: props.source,
    };

    const response = await state.megaTrendService.getCategoryYearList(condition);
    if (response) {
      if (response && response.successOrNot === 'Y') {
        dispatch({
          type: MegaTrendCategoryYearActionType.SET_MEGA_TREND_CATEGORY_YEAR_LIST,
          categoryResult: response.data.categoryResult,
          yearResult: response.data.yearResult,
        });
        if (response.data) {
          setCategory('');
          setYear(response.data.yearResult[0].source);
          searchMegaTrendList(
            SearchCondition.START_PAGE_NUMBER,
            SearchCondition.DEFAULT_PAGE_COUNT,
            '',
            response.data.yearResult[0].source
          );
        }
      } else {
        dispatch({
          type: MegaTrendCategoryYearActionType.SET_MEGA_TREND_CATEGORY_YEAR_LIST,
          categoryResult: [],
          yearResult: [],
        });
      }
    }
  };

  const setDetailOpen = (detailData: MegaTrendData) => {
    dispatch({
      type: MegaTrendDetailActionType.SHOW_MODULE_DETAIL,
      moduleDetail: { detailData: detailData, isDetailOpen: true },
    });
  };

  return (
    <article className="cardA">
      <div className="module-header">
        <h5>{props.source ? props.source : props.domesticOverseas}</h5>
        <Image src={RedBullLogo} className="logo" alt="" />
        {state.megaTrendCategoryYear.categoryResult && (
          <ButtonGroup>
            <Button
              active={category === ''}
              onClick={() => {
                clickCategory('');
              }}
            >
              {t('module.cardA.All')}
            </Button>
            {state.megaTrendCategoryYear.categoryResult.map((item, index) => {
              return (
                <Button
                  active={category === item.source}
                  key={item.source}
                  onClick={() => {
                    clickCategory(item.source);
                  }}
                >
                  {item.source}
                </Button>
              );
            })}
          </ButtonGroup>
        )}
        <div className="selectYear">
          {state.megaTrendCategoryYear.yearResult && (
            <Form.Control as="select" onChange={setYearHandler} value={year}>
              {state.megaTrendCategoryYear.yearResult.map(item => {
                return <option key={item.source}>{item.source}</option>;
              })}
            </Form.Control>
          )}
        </div>
      </div>
      <div id="moduleBody" className="module-body">
        <div className="topic">
          <i>{t('module.cardA.originalView')}</i>
          <p>{t('module.message.showOriginal')}</p>
          <span className="chevron-right"></span>
        </div>
        {state.megaTrendData.total.value > 0 && (
          <ListGroup>
            {state.megaTrendData.items.map((item, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  onClick={() => {
                    setDetailOpen(item);
                  }}
                >
                  <div className="card">
                    <div className="tags">
                      <Badge>{item.category}</Badge>
                    </div>
                    <Image src={getImgSrc(item.category, item.keyword.length % 4)} />
                    <div className="content">
                      <h6>
                        {index + 1}. {item.keyword}
                      </h6>
                      <pre>{item.content}</pre>
                      <address>
                        {item.source} <span>{item.year}</span>
                      </address>
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </div>
    </article>
  );
};

export default CardA;
