import React from 'react';

import './SearchOption.scss';

export interface SearchOptionProps {
  Dummy?: any;
}

const SearchOption: React.FC<SearchOptionProps> = (props: SearchOptionProps) => {
  return (
    <div className={'SearchOption flex'}>
      <div className={'list flex hbox center'}>
        <div className={'cursor_pointer ordering-triangle'}>정렬</div>
        <div className={'cursor_pointer date-triangle'}>기간</div>
        <div className={'cursor_pointer field-triangle'}>영역</div>
        <div className={'cursor_pointer option-btn'}>옵션유지</div>
        <div className={'cursor_pointer high-grade-triangle'}>고급검색</div>
      </div>
    </div>
  );
};

export default SearchOption;
